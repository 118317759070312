html.index {
	body {
		//padding-top: 51px;
		background: #fff;
	}

	.navbar-static-top {
		margin-bottom: 0;
	}
}

.index-inner-1 {
	margin: 0;
	padding: 0;
	position: relative;
	width: 100%;
}

@media (min-width: @screen-sm-min) {
	.index-inner-1 {
		background-image: none;
		padding-left: 0;
	}
}

@media (min-width: @screen-md-min) {
	.index-inner-1 {
		background: #0087c9 url('../img/index-lines.png') left top no-repeat;
		background-size: auto 100%;
		padding-left: 18%;
	}
}

.index-inner-2 {
	background: #0087c9 url('../img/index-man.jpg') right bottom no-repeat;
	min-height: 470px;
	padding-right: 30%;
	padding-left: 4em;
}

@media (max-width: @screen-xs-max) {
	.index-inner-2 {
		background-image: url('../img/index-lines.png');
		background-position: left top;
		padding: 1em;
		min-height: 0;
	}
}

.index-inner-bottom {
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 70px;
	background: fade(#0073ab, 40%);
}

.index-text {
	display: table;
	padding-bottom: 80px;

	h1 {
		font-size: 60px;
		color: #fafeff;
		margin-top: 1em;
		margin-bottom: 0.5em;
	}
	p {
		font-size: 24px;
		color: #fafeff;
		margin-bottom: 1em;
	}
}

@media (max-width: @screen-xs-max) {
	.index-text {
		text-align: center;

		h1 {
			font-size: 31px;
		}
		p {
			font-size: 16px;
			text-align: justify;
			margin: 2em 1em;
		}
	}
}

.index-list {
	min-height: 300px;

	background: #fff url('../img/index-notes2.jpg') top center no-repeat;

	.pack-card-small {
		margin-bottom: .9em;
	}
}

.index-list-title {
	color: darken(#a3a3a3, 10%);
	margin-top: 220px;
	font-size: 50px;
	margin-bottom: 1em;
}

@media (max-width: @screen-xs-max) {
	.index-list-title {
		font-size: 24px;
		margin-top: 120px;
	}
}

html.nav-append {
	body {
		padding-top: 50px;
	}

	.navbar-default {
		border-bottom: none;
	}

	body > .nav-append {
		background-color: #f8f8f8;
		border-bottom: solid 1px #e7e7e7;
		margin-bottom: 10px;

		ol.breadcrumb {
			padding-left: 0;
			padding-top: 0;
			margin-bottom: 0;
		}
	}
}

.index-welcome {
	border-top: solid 10px #000;
	background: #fff;
	text-align: center;
	padding-bottom: 50px;

	h2 {
		font-size: 36px;
		color: #8a8a8a;
		margin: 1.5em 0;
	}
}

@media (max-width: @screen-xs-max) {
	.index-welcome {
		h2 {
			font-size: 24px;
		}
	}
}

.index-welcome-wid {
	text-align: center;
	color: #4b4b4b;

	img {
		width: 45%;
		max-width: 128px;
	}
	h3 {
		font-size: 20px;
		color: #3e3e3e;
		margin: 1em 0 .3em;
	}
	p {
		padding: 0 5%;
	}
}

@media (max-width: @screen-xs-max) {
	.index-welcome-wid {
		img {
			float: left;
			width: 40px;
		}

		h3 {
			display: inline-block;
			margin-top: 8px;
			line-height: 24px;
			margin-bottom: 8px;
		}

		p {
			margin-top: 1em;
			margin-bottom: 3em;
			text-align: justify;
		}
	}
}

.index-welcome-footer {
	background: #28a05c url(../img/index-footer.svg) right bottom no-repeat;
	padding: 60px 0;

	h2 {
		color: #fff;
		font-size: 30px;
		line-height: 1;
	}

	.btn {
		margin-top: 4px;
	}
}

@media (max-width: @screen-xs-max) {
	.index-welcome-footer {
		h2 {
			text-align: center;
			font-size: 24px;
			margin-bottom: 2em;
		}
		.btn {
			text-align: center;
		}
	}
}
