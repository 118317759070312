@media (min-width: @screen-md-min) {
  .pack-test-prepare {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .pack-test-prepare__first,
  .pack-test-prepare__second {
    flex: 50%
  }

  .pack-test-prepare__second {
    border-left: solid 1px #ddd;
  }

  .pack-test-prepare__buttons {
    flex: 100%;
  }

  .pack-test-set__checkButton {
    .btn {
      width: 60%;
    }
  }
}