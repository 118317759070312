@media (min-width: @screen-md-min) {
  .fl-memo-cards__container {
    display: block;
  }

  .fl-memo-cards__question-width {
    width: 42rem !important;
    margin-left: auto;
    margin-right: auto;
  }

  .fl-memo-cards__question {
    height: 24rem !important;
    .fl-memo-cards__question-width();
  }

  .fl-memo-controls {
    width: 42rem !important;
    margin-left: auto;
    margin-right: auto;
  }
}