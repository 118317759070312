.search-head {
	padding-top: 1em;
	padding-bottom: 1em;
}

.search-table-packs {
	li {
		background: #fff;
		margin-bottom: 1em;
	}

	.card-card-qa {
		position: relative;
		font-size: 90%;
		padding: .5em;
	}

	.pack-and-some-cards {
		border-bottom: none;
	}
}