@media (max-width: @screen-sm-max) and (orientation: landscape) {
  .fl-memo-card--both {
    .fl-memo-card__side--question {
      top: 0;
      right: auto;
      bottom: 0;
      left: 0;

      height: auto;
      width: 50%;
    }

    .fl-memo-card__side--answer {
      top: 0;
      right: 0;
      bottom: 0;
      left: auto;

      height: auto;
      width: 50%;

      &:before {
        position: absolute;
        content: " ";
        height: 80%;
        width: 1px;
        left: 0;
        top: 10%;
        background: #ddd;
      }
    }
  }
}