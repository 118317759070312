
.fl-pack-embed {
  box-shadow: 0 0 7px #555;
  position: relative;
}

.fl-pack-embed__field {
  cursor: pointer;
  background: #fff;
  padding: 0 3rem;
}

.fl-pack-embed__notice {
  background: #2f4247;
  padding: .5rem;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  font-size: 80%;
}

.fl-pack-embed__next,
.fl-pack-embed__prev {
  position: absolute;
  top: 0;
  height: 100%;
  font-size: 200%;
  opacity: .4;

  &:focus {
    box-shadow: none;
  }
}

.fl-pack-embed__prev {
  left: 0;
  padding-right: 0;
}

.fl-pack-embed__next {
  right: 0;
  padding-left: 0;
}

.fl-pack-embed__current-index {
  padding: 0 1rem;
}

.fl-pack-embed__card {
  min-height: 16.7rem;
  position: relative;
}

.fl-pack-embed__card-face,
.fl-pack-embed__card-back {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fl-pack-embed__card-side--image-and-text {
  .fl-pack-embed__card-image,
  .fl-pack-embed__card-text {
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
  }

  .fl-pack-embed__card-image {
    flex-basis: 40%;
    padding-right: 0;
  }

  .fl-pack-embed__card-text {
    flex-basis: 60%;
  }

  .fl-pack-embed__card-text {
    text-align: left;
  }
}

.fl-pack-embed__card-image {
  text-align: center;
  height: 100%;
  padding: 1rem;
}

.fl-pack-embed__card-text {
  font-size: 3rem;
  text-align: center;
  padding: 0 1rem;
  max-height: 100%;
  max-width: 100%;

  > div {
    margin: 0;
    display: block;
    user-select: none;
    max-width: 100%;
  }
}

@import "fl-pack-embed--md";
@import "fl-pack-embed--portrait";