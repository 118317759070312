@media (min-width: @screen-md-min) {
  .fl-pack-header__actions-toggle {
    display: none;
  }

  .fl-pack-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem 1.5rem;
  }

  .fl-pack-header__title {
    font-size: 2rem;
  }

  .fl-pack-header__meta {
    width: 100%;
  }

  .fl-pack-header__text,
  .fl-pack-header__actions {
    flex: 1;
    display: initial;
  }

  .fl-pack-header__actions {
    margin-top: 0;
    text-align: end;
  }
}