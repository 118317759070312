.fl-memo-cards__container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fl-memo-cards__question {
  position: relative;
  flex: 1;
}

.fl-memo-cards__buttons {
  padding: .5rem;

  display: flex;
  flex-direction: row;
  place-content: center;

  > .btn {
    margin-right: .5rem;
    margin-bottom: .5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

@import "fl-memo-cards--landscape.less";
@import "fl-memo-cards--md.less";
