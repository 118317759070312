@media (min-width: @screen-md-min) {
  .fl-memo-card {
    margin-top: 0;
  }

  .fl-memo-card__actions {
    position: absolute;
    top: 0;
    left: -2.5rem;
    font-size: 0;

    margin-top: 0;

    display: flex;
    flex-direction: column;
  }

  .fl-memo-card__tools {
    top: 0;
    right: -3rem;

    display: flex;
    flex-direction: column;

    .btn {
      margin-bottom: .25rem;
    }
  }

  .fl-memo-card__container {
    max-height: 24rem !important;
    max-width: 42rem !important;
  }

  .fl-memo-controls {
    max-width: 42rem !important;
    margin-left: auto;
    margin-right: auto;
  }

  .fl-memo-card__side-text {
    font-size: 3rem;
  }

  .mixin-put-image-to-the-right(@imagePercent) {
    .fl-memo-card__side.fl-memo-card__side--display-all {
      .fl-memo-card__side-image {
        right: 100% - @imagePercent;
        bottom: 0;
        padding: 1rem;
      }

      .fl-memo-card__side-text {
        left: @imagePercent;
        top: 0;
      }
    }

  }

  .mixin-put-image-to-the-right(40%);

  .fl-memo-card.fl-memo-card--both {
    .mixin-put-image-to-the-right(30%);
  }
}