.fl-memo-controls {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .btn {
    border: solid 1px #ddd;
    background: #fff;
  }
}

@import "fl-memo-controls--xs";