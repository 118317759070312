/* needed for @screen-sm below */
@import "bootstrap/less/variables";
@import "bootstrap/less/mixins/clearfix";
@import "bootstrap/less/mixins/buttons";

//.h1, .h2, .h3, h1, h2, h3 {
//  margin-bottom: 10px;
//  margin-top: 10px;
//}

label {
  font-weight: normal;
}

.cursor-default {
  cursor: default !important;
}

.cursor-na {
  cursor: not-allowed !important;
}

.row .row {
  margin-left: 0;
  margin-right: 0;
}

.btn-lg {
  border-radius: 2px;
}

.input-colored(@back) {
  border-bottom-color: fade(@back, 30%);
  &:focus, &:hover {
    border-bottom-color: fade(@back, 80%);
  }
}

.input-blue() {
  .input-colored(#17a3ff);
}

.input-red() {
  .input-colored(red);
}

.input-gray() {
  .input-colored(#777);
}

input.input-text,
textarea.input-textarea,
select.input-select {
  background: #fff;
  border-radius: 0;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  padding: 4px 8px;

  .input-blue();

  &.input-error {
    .input-red();
    background-color: rgba(255, 220, 220, 0.3);
  }
}

textarea.input-textarea {
  width: 100%;
  resize: vertical;
  min-height: 40px;
}

.has-error {
  > .input-text,
  > .input-textarea {
    .input-red();
    background-color: rgba(255, 220, 220, 0.3);
  }

  > .control-label {
    display: block;
  }
}

.btn-glyphicon-left {
  > .glyphicon {
    margin-right: .5em;
  }
}

.btn-link {
  .pointer();
  text-decoration: dotted underline;
}

.panel-body {
  border-width: 1px;
  border-style: none solid solid solid;
}

.panel-footer {
  border-width: 1px;
  border-style: none solid solid solid;
}

.panel-border-color(@color) {
  border-color: @color;

  > .panel-footer {
    border-color: @color;
  }
  > .panel-body {
    border-color: @color;
  }
}

.panel-default {
  .panel-border-color(#ddd);
}

.panel-info {
  .panel-border-color(#bce8f1);
}

.panel-footer-info {
  background-color: #d9edf7;
  color: #31708f;
}

label.checkbox-outer,
label.radio-outer {
  > input {
    margin-right: .6em;
  }
}

.form-control-with-search-icon {
  position: relative;

  &:before {
    position: absolute;
    width: 50px;
    height: 18px;
    top: 17px;
    left: 4px;
    text-align: center;
    color: #d2d6e0;

    content: "\e003";
    display: block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-size: 18px;
  }

  > .form-control {
    padding-left: 50px;
  }

  &.focus {
    &:before {
      color: #777777;
    }
  }
}

.footer-brand {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.nav-stacked > li + li {
  margin-top: 0;
}

.nav-pills {

  > li {
    background: transparent;

    > span {
      border-radius: 1px;
      position: relative;
      display: block;
      padding: 10px 15px;
    }

    > a {
      border-radius: 1px;
      color: #333;
      background: #fff;
    }

    > a:focus,
    > a:hover {
      background: #fff;
      color: #a0c3ff;
    }
  }

  > li.active {
    > a,
    > a:focus,
    > a:hover {
      background: #fff;
      color: #a0c3ff;
    }
  }

}

.alert {
  border-radius: 1px;
}

.form-group {
  > label {
    display: block;
  }
}

.form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0;
}

.navbar-toggle {
  border: none;
  border-radius: 0;
}

.navbar-nav {
  li.image-button {
    padding: 0;

    img {
      height: 40px;
      max-width: 40px;
    }

    .navbar-toggle {
      margin: 0;
      padding: 0;
    }
  }
}

.pagination {
  > li {
    > a {
      margin-right: 1em;
      color: #333;
    }

    &:first-child > a,
    &:last-child > a {
      border-radius: 0;
    }
  }
}

.modal-header {
  background: #00a6e8;
  color: #ffffff;

  button.close {
    color: #ffffff;
    text-shadow: none;
    opacity: 1;
  }
}

.modal-content {
  background: #ffffff;
  border: none;
}

.modal-title {
  text-align: center;
}

.modal-footer {
  border-top: none;

  &.modal-footer-text-center {
    text-align: center;
  }

  &.modal-footer-modaller {
    padding-top: 0;
  }
}

@media (max-width: @screen-xs-max) {
  .btn-icon-on-xs {
    .btn-text {
      display: none;
    }

    .glyphicon {
      margin-right: 0;
    }
  }
}

.visible-xs-table-row {
  display: none !important;

  @media (max-width: @screen-xs-max) {
    display: table-row !important;
  }
}

.w-4em {
  width: 4em;
}

.popover--yellow {
  min-width: 25vw;

  @popover-arrow-color: #fbf7c5;

  &.bs-popover-top {
    .arrow::after {
      border-top-color: @popover-arrow-color;
    }
  }

  &.bs-popover-right {
    .arrow::after {
      border-right-color: @popover-arrow-color;
    }
  }

  &.bs-popover-bottom {
    .arrow::after {
      border-bottom-color: @popover-arrow-color;
    }
  }

  &.bs-popover-left {
    .arrow::after {
      border-left-color: @popover-arrow-color;
    }
  }

  .popover-body {
    background: @popover-arrow-color;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
  }
}