@media (orientation: landscape) {
  .fl-learn-card__write {
    display: flex;
  }

  .fl-learn-card__write-input {
    flex: 100;
    margin-right: 1rem;
  }

  .fl-learn-card__write-buttons {
    flex: 1;
    white-space: nowrap;
  }

  .fl-learn-card__answers {
    > li {
      max-width: 25%;
    }
  }

  .fl-learn-card__answers.fl-learn-card__answers--2lines {
    flex-wrap: wrap;

    > li {
      max-height: 50%;
      flex: 25%;
    }
  }
}