@media (min-width: @screen-md-min) {
  .fl-pack-embed {
    box-shadow: none;
  }

  .fl-pack-embed__field {
    min-height: 16.7rem;
  }

  .fl-pack-embed__card-image {
    height: 100%;
    max-width: 50%;
  }
}