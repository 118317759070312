@import "_edit-card-form.less";

.fl-memo-edit-card {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.fl-memo-edit-card__side {
  flex: 1;
  margin-bottom: 1rem;
  padding: 1.5rem;
  border: solid 1px #eee;
  box-shadow: 1px 1px 1px fade(#333, 30%);
  background: #ffffff;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.fl-memo-edit-card__side-input {
  flex: 1;

  .ProseMirror {
    .edit-card-textarea-theme();
  }
}

.fl-memo-edit-card__side-picture {
  margin-left: 1rem;

  img {
    max-height: 4rem;
    max-width: 4rem;
  }
}

.fl-memo-edit-card__side-buttons {
  display: flex;
  flex-direction: column;
  margin-left: .5rem;

  .btn {
    opacity: 0.5;
    font-size: 1.5em;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    line-height: 1;

    &:focus,
    &:active,
    &:hover {
      opacity: 1;
    }
  }
}

.fl-memo-edit-card {
  textarea:invalid {
    border-color: #dc3545;

    & ~ .invalid-feedback {
      display: block;
    }
  }
}