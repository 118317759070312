.fl-memo-write__container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fl-memo-write__question {
  width: 100%;
  position: relative;
  flex: 1;

  margin-bottom: .5rem;
}

.fl-memo-write__input {
  width: 100%;
}

@import "fl-memo-write--md";