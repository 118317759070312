.fl-form {
  .btn-link {
    text-decoration: none;
  }

  .bootstrap-select {
    .dropdown-toggle {
      background: #fff;
      border-color: #ced4da;
    }
  }

  .bootstrap-select.is-invalid .dropdown-toggle {
    border-color: #dc3545;
    background: rgba(220, 53, 69, 0.05);
  }

  .form-control.is-invalid {
    background: fade(#dc3545, 5%);
  }
}

.fl-form__label {
  font-weight: bold;
}

.fl-form__label-optional {
  opacity: .7;
  font-size: 70%;
  font-weight: normal;
}

@media (min-width: @screen-sm-min) {
  .fl-form__label-optional {
    display: block;
  }
}

.fl-form-main-fixed {
  position: fixed;
  background: #fff;
  padding: .5rem 1rem;
  top: 0;
  box-shadow: 0 4px 20px #777;
  z-index: 100;

  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.fl-form-main-fixed__title {
}

.fl-form-main-fixed__button {
}

.fl-form-main {
  margin-bottom: 1rem;

  > .form-group:last-child {
    margin-bottom: 0;
  }

  .custom-radio label {
    cursor: pointer;
  }
}

.fl-form-main__title {
  font-size: 1.2rem;
  line-height: 1.2;
  margin: 0 0 1em;
}

.fl-form-main__thumb {
  width: 7.5rem;
  height: 7.5rem;
  display: inline-block;
  position: relative;
  border: solid 1px fade(#8d8d8d, 40%);
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    border-color: fade(#8d8d8d, 60%);

    .fl-form-main__thumb-button {
      opacity: .9;
    }
  }

  span {
    color: #ffffff;
  }
}

.fl-form-main__thumb-preview {
  width: 100%;
  height: 100%;

  img {
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
  }
}

.fl-form-main__thumb--empty {
  .fl-form-main__thumb-preview {
    display: none;
  }
}

.fl-form-main__thumb-button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #8d8d8d;
  font-size: 80%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  opacity: .8;
  text-align: center;
}


.fl-form-secondary {
}

@media (min-width: @screen-sm-min) {
  .fl-form-secondary__languages {
    display: flex;
    flex-direction: row;

    select {
      display: inline-block;
      width: auto;
    }
  }
}

.custom-select--selected-placeholder {
  &:invalid {
    color: #aaa;
  }

  option {
    color: #212529;
  }
}

.pack-edit__final-options {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: center;

  label {
    margin: 0;
    display: inline;
  }

  .btn-link {
    padding-left: 0;
    padding-right: 0;
  }
}

.pack-edit__final-options-input {
  margin-bottom: .7rem;
}

.pack-edit__final-options-body {
  display: block;
}

.fl-form__add-card {
  text-align: center;
  margin-top: 1.5em;
  margin-left: 50%;
  padding-right: 3rem;
}

.fl-form__add-card-button {
  margin-left: -2.3rem;

  opacity: .5;
  padding: .5rem;
  font-size: 250%;

  > span {
    display: block;
  }

  &:hover,
  &:focus,
  &:active {
    opacity: .8;
  }
}

.fl-form__preview {
  background: #f6f6f6;

  strong {
    color: #aaaaaa;
  }

  table {
    margin: 0 auto;
    border-collapse: collapse;
  }

  td {
    border: solid 1px #aaa;
    width: 40%;
    background: #fff;
    margin-bottom: 1rem;
  }
}