.fl-learn-progress {
  color: #fff;
  white-space: nowrap;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.fl-learn-progress__item {
  text-align: center;
  font-size: 1rem;
}

.fl-learn-progress__label {
  font-size: .9rem;
}

.fl-learn-progress__label_ring {
  border-radius: 100%;
  display: inline-block;
  width: .5rem;
  height: .5rem;
  border: solid 1px #fff;
}

.fl-learn-progress__label_ring--green {
  background: #b7e5a1;
}

.fl-learn-progress__label {
  opacity: .8;
  line-height: 1.5;
}

.fl-learn-progress__delimiter {
  display: block;
  margin-left: 1rem;
  margin-right: 1rem;
  opacity: .8;
  line-height: 1.3;
}

.fl-learn-progress__value {
  flex: 1;
  text-align: center;
  font-size: 1.7rem;
  line-height: 2.0rem;
}
