.modal.modal-popover {
  padding: 0 !important;
  margin: 0 !important;

  .modal-dialog {
    margin: 0;
    padding: 0;
  }

  .modal-body {
    padding: 1rem 2rem;
    text-align: center;
  }
}
