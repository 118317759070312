@media (min-width: @screen-sm-min) {
  .fl-pack-header {
    margin-left: 0;
    margin-right: 0;
  }

  .fl-pack-header__thumb {
    float: left;
    margin-right: 1rem;
    margin-bottom: 0;
    margin-left: 0;
  }
}