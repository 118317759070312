.fl-memo-help {
  position: absolute;
  top: 5rem;
  padding-left: 1rem;
  width: auto !important;

  .btn {
    padding-left: 0;
    padding-right: 0;
    opacity: .6;
    font-size: 110%;

    &:hover,
    &:focus,
    &:active {
      opacity: 1;
    }
  }
}

.fl-memo-help__line {
  opacity: .6;
  line-height: 1.6;
}

.fl-memo-help__title {
  opacity: .5;
  font-size: 170%;
  line-height: 2;
}

@import "fl-memo-help--xs";