.fl-pack-plist {
}

.fl-pack-plist__controls {
  margin-bottom: .5rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .btn {
    .fl-pack-games-buttons-btn();
  }
}

.fl-pack-plist__list {
  > .card {
  }

  .face,
  .back,
  .card-action {
    padding: 1em;
  }

  .back {
    border-left: solid 1px #ccc;
  }
}

.fl-pack-plist__flags {
  text-align: end;
  font-size: 0;
  padding: 0 .2rem;

  > span,
  button {
    font-size: .9rem;
    opacity: .7;
    vertical-align: baseline;
    line-height: 1;
    padding: .7rem .4rem;
  }


  > span {
    padding-left: .3rem;
    padding-right: .3rem;
  }

  .fa.fa-star {
    color: #ffcd4d;
  }
}

.fl-pack-plist__flags-line {
  white-space: nowrap;
  display: inline-block;
}

.fl-pack-plist__item {
  margin-bottom: .5em;
}

.fl-pack-plist__empty-item {
  text-align: center;
  padding: 2em 1em;
  opacity: .8;
}

.fl-pack-plist__face,
.fl-pack-plist__back {
  flex: .5;
  padding: 0 1em 1em;
  word-break: break-word;
}

.fl-pack-plist__image {
  float: left;
  margin-right: 1rem;
  //margin-bottom: 1rem;

  > img {
    max-width: 5rem;
    max-height: 5rem;
  }
}

@import "fl-pack-plist--md";