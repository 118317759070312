.fl-dashboard-memo-grid--table {
  .fl-dashboard-memo-grid--grid-only {
    display: none !important;
  }
}

// cell layout in table
.fl-dashboard-memo-grid--table {
  .fl-dashboard-memo-grid__row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: .5rem .5rem;
    margin-bottom: .5rem;
  }

  .fl-dashboard-memo-grid__card-placeholder {
    height: 4.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  .fl-dashboard-memo-grid__card-header {
    width: 40%;
    padding-left: .5em;
  }

  .fl-dashboard-memo-grid__row-foot {
    flex: 100%;
  }

  .fl-dashboard-memo-grid__card-metrics {
    display: block;
  }

  .fl-dashboard-memo-grid__card-metric {
    display: inline-block;
  }

  .fl-dashboard-memo-grid__card-timings {
    justify-content: center;
  }

  .fl-dashboard-memo-grid__card-timing {
    margin-right: .5em;
    margin-left: .5em;
  }
}

@media (min-width: @screen-xs-min) {
  .fl-dashboard-memo-grid--table {
    .fl-dashboard-memo-grid__card-metric {
      display: inline-block;
    }

    .fl-dashboard-memo-grid__card-header {
      width: 50%;
    }
  }
}

@media (min-width: @screen-md-min) {
  .fl-dashboard-memo-grid--table {
    .fl-dashboard-memo-grid__card-metrics {
      display: table;
    }

    .fl-dashboard-memo-grid__card-metric {
      display: table-row;

      > span {
        display: table-cell;
        padding-right: 1em;
      }

      > em {
        display: table-cell;
        text-align: right;
      }
    }

    .fl-dashboard-memo-grid__card-header {
      width: 40%;
    }


    .fl-dashboard-memo-grid__card-timings {
      width: 15%;
    }
  }
}
