//  .fl-form-cards__textarea
.edit-card-textarea-theme() {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  border-radius: 0;
  border: none;
  border-bottom: solid 1px #ddd;

  padding-left: 0;
  padding-right: 0;
  padding-bottom: .1rem;
  line-height: 1.2;

  &:focus {
    box-shadow: none;
    border-color: #80bdff;
    outline: none !important;
  }

  &::placeholder {
    opacity: .6;
  }

  &.is-invalid {
    border-color: #dc3545;
  }
}
