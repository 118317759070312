@media (max-width: @screen-sm-max) and (orientation: landscape) {
  .fl-learn-card__question {
    min-height: auto;
  }

  .fl-learn-card__answers {
    flex-direction: row;
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text {
    @this-padding: .25rem;

    .fl-learn-card__answers-answer-image {
      left: 0;
      right: 0;
      bottom: 0;
      top: auto;

      height: 30%;
      width: auto;

      padding: @this-padding;

      img {
        vertical-align: top;
      }
    }

    .fl-learn-card__answers-answer-text {
      position: absolute;
      right: 0;
      left: 0;
      bottom: auto;
      top: 0;

      width: auto;
      height: 70%;

      padding: @this-padding @this-padding 0;
    }
  }

  //.fl-learn-card-wrong-display__value,
  //.fl-learn-card-wrong-display__continue {
  //  display: none;
  //}
}