.fl-keep-nl {
  white-space: pre-line;
}

.fl-tt-text {
  white-space: pre-line;
}

.fl-tt-html {
  > p {
    margin: 0;
  }
}

@import "fl-learn-card.less";
@import "fl-memo-choice.less";
@import "fl-memo-btn-grade.less";
@import "fl-memo-card.less";
@import "fl-memo-session-end.less";
@import "fl-memo-cards.less";
@import "fl-memo-write.less";

.fl-expandable-image {
  transition: 0.25s opacity;
  cursor: zoom-in;

  .close-button {
    position: fixed;
    top: 10px;
    right: 10px;
    display: none;
    cursor: pointer;
  }

  .expand-button {
    position: absolute;
    z-index: 999;
    right: 10px;
    top: 10px;
    align-items: center;
    justify-content: center;
    padding: 3px;
    opacity: 0;
    transition: 0.2s opacity;
  }

  svg {
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
  }

  svg path {
    fill: #FFF;
  }

  &:hover .expand-button {
    opacity: 1;
  }

  &.expanded {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    bottom: auto;
    right: auto;
    width: 100%;
    height: 100%;
    background: black;
    display: flex;
    align-items: center;
    opacity: 0;
    padding: 0 !important;
    cursor: zoom-out;

    > img {
      width: 100%;
      max-width: 1200px;
      max-height: 100%;
      object-fit: contain;
      margin: 0 auto;
    }

    > .close-button {
      display: block;
    }
  }

  .expand-button svg {
    width: 20px;
    height: 20px;
  }

  .expand-button path {
    fill: #FFF;
  }
}
