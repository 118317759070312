.fl-learn-card__container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.fl-learn-card__question {
  margin-left: .5rem;
  margin-right: .5rem;
  flex-basis: 40%;
  position: relative;
  min-height: 15rem;

  > .play-card-stack__card {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.fl-learn-card__answers {
  flex-basis: 60%;

  overflow: hidden;
  list-style: none;
  padding: .5rem .5rem 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  > li {
    flex: .25;
    position: relative;
  }
}

.fl-learn-card__answers-item {
  position: absolute;
  left: .25rem;
  right: .25rem;
  top: .25rem;
  bottom: .25rem;
}

.fl-learn-card__answers-answer {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  background: #fff;
  box-shadow: 0 0 2px fade(#333, 30%);
  border-width: 0;
  cursor: pointer;
}

.fl-learn-card__answers-answer.btn {
  padding-top: .175rem;
  padding-bottom: .175rem;
  font-size: 1.2rem;
}

.fl-learn-card__answers-answer-image {
  @this-padding: .25rem;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: @this-padding;

  img {
    vertical-align: top;
  }
}

.fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text {
  @this-padding: .25rem;

  .fl-learn-card__answers-answer-image {
    width: 30%;
  }

  .fl-learn-card__answers-answer-text {
    position: absolute;
    right: 0;
    width: 70%;
    top: 0;
    bottom: 0;
    height: auto;
    padding: @this-padding @this-padding @this-padding 0;
  }
}

.fl-learn-card__answers-answer-shortcut {
  position: absolute;
  padding: 0 .5em;
  font-size: 80%;
  left: 0;
  top: 0;
  background: #b1aadd;
  display: none;
  z-index: 1;
}

.fl-learn-card__answers-answer-text {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  max-height: 100%;
  max-width: 100%;
  height: 100%;

  > p {
    margin: 0;
  }
}

.fl-learn-card__answers-answer--gone {
  opacity: .2 !important;
}

.fl-learn-card__answers-answer--correct {
  background: #e4efdf !important;
  opacity: 1 !important;
}

.fl-learn-card__answers-answer--incorrect {
  background: #f8d0d0 !important;
  opacity: 1 !important;
}


.fl-learn-card-wrong-display {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: #ffffff;
  box-shadow: 0 0 2px fade(#333, 30%);
}

.fl-learn-card-wrong-display__title {
  color: #f89a90;
  padding: .5rem 1rem;
  font-size: 150%;
  border-bottom: solid 1px #ddd;

  > .far {
    font-size: 160%;
    vertical-align: bottom;
    margin-right: 1rem;
  }
}

.fl-learn-card-wrong-display__value {
  padding: .5rem 1rem 0;
  flex: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.fl-learn-card-wrong-display__value-label {
  display: block;
  margin-bottom: .5rem;
  width: 100%;
  opacity: .6;
}

.fl-learn-card-wrong-display__value-answer {
  overflow: hidden;
  flex: 1;
  width: 100%;

  > p {
    margin: 0;
  }
}

.fl-learn-card-wrong-display__continue {
  margin: .5rem 1rem;
  opacity: .6;
  text-align: center;
}

.mixin-fl-learn-card__write--set-padding(@padding: .5rem) {
  .fl-learn-card__write {
    padding: @padding @padding 0;
  }

  .fl-learn-card__write-input {
    margin-bottom: @padding;
  }

  .fl-learn-card__write-buttons {
    margin-bottom: @padding;
  }
}

.fl-learn-card__write {
  overflow: hidden;
  list-style: none;
  background: #fff;
}

.fl-learn-card__write-input {
}

.fl-learn-card__write-buttons {
  text-align: end;
}

.mixin-fl-learn-card__write--set-padding();

@import "fl-learn-card--sm-landscape";
@import "fl-learn-card--landscape";
@import "fl-learn-card--md";