@media (min-width: @screen-md-min) {
  .fl-memo-write__container {
    display: block;
  }

  .fl-memo-write__question {
    flex: 0;
    height: 24rem !important;
    width: 42rem !important;
    margin-left: auto;
    margin-right: auto;
  }

  .fl-memo-write__input {
    flex: 0;
    width: 42rem !important;
    margin-left: auto;
    margin-right: auto;
  }

  .fl-learn-card-wrong-display__title {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}