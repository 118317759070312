@media (min-width: @screen-md-min) {
  .fl-memo-choice__question {
    height: 24rem !important;
    width: 42rem !important;
    margin-left: auto;
    margin-right: auto;
  }

  .fl-memo-choice__buttons {
    height: 10rem !important;
    //width: 42rem !important;
    margin-left: auto;
    margin-right: auto;
  }
}