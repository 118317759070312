@media (min-width: @screen-md-min) {
  .fl-learn-card__question {
    height: 24rem;
    width: 42rem;
    margin: 0 auto;
    min-height: auto;
  }

  .fl-learn-card__answers {
    position: static;
    max-height: 15rem;
    flex: 1;
  }

  .fl-learn-card__answers {
    flex-direction: row;
    padding: 1rem 1rem 0;

    > li {
      max-width: 25%;
      max-height: 100%;
    }
  }

  .fl-learn-card__answers-answer-shortcut {
    display: block;
  }

  .fl-learn-card__answers-answer.btn {
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: 1rem;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text {
    @this-padding: 1rem;

    .fl-learn-card__answers-answer-image {
      left: 0;
      right: 0;
      bottom: 0;
      top: auto;

      height: 40%;
      width: auto;

      padding: @this-padding/2 @this-padding @this-padding;

      img {
        vertical-align: top;
      }
    }

    .fl-learn-card__answers-answer-text {
      position: absolute;
      right: 0;
      left: 0;
      bottom: auto;
      top: 0;

      width: auto;
      height: 60%;

      padding: 1.2rem @this-padding 0;
    }
  }

  .mixin-fl-learn-card__write--set-padding(1rem);

  .fl-learn-card__write {
    width: 42rem;
    margin-left: auto;
    margin-right: auto;
  }
}