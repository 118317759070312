.person-name-wid {
  .clearfix();
  background: #a1c3ff;
  position: relative;
  border-radius: 1px;

  > img {
    display: block;
    width: 90px;
    height: auto;
    max-height: 90px;
    left: 0;
    bottom: 0;
    position: absolute;
    border-bottom-left-radius: 1px;
  }

  > div {
    display: block;
    padding: 5px 10px;
    margin-left: 100px;
    text-align: right;
    height: 90px;
    overflow: hidden;
  }

  .person-cog {
    display: block;
    text-align: right;
    font-size: 150%;

    a {
      color: #d4e4ff;
    }
  }

  .ba-exp {
    display: inline-block;
    height: 50px;
    vertical-align: bottom;
    width: 0;
  }

  .display-name {
    color: #fff;
    font-size: 150%;
    font-weight: bold;
    text-shadow: 1px 0 0 #eee;
  }
}

@media (max-width: @screen-xs-max) {
  .person-name-wid {
    @height: 70px;

    > div {
      height: @height;
    }

    .ba-exp {
      height: 30px;
    }

    > img {
      width: @height;
      max-height: @height;
    }
  }
}

.my-page-list {
  background: #fff;
  overflow: auto;
  padding: 10px 30px;

  > h1 {
    margin-top: 0;
    margin-bottom: 1em;
    font-size: 24px;
  }

  > div,
  > ul {
    padding-left: 0;
  }
}

.settings-form-picture {
  display: inline-block;
  width: 150px;
  height: 150px;
  position: relative;

  > img {
    width: 100%;
    height: 100%;
    background: #000;
    object-fit: contain;
  }

  > button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    opacity: .8;
    border-radius: 0;

    &:hover {
      opacity: 1;
    }

    &.btn-in-progress {
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }
}

.memtrick-list-title {
  font-size: 1rem;
  margin: 0;
  padding: 1rem;
}