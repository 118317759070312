.pack-play-controls {
  margin-top: 1rem;
  opacity: .7;
  padding: .5rem;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.pack-play-controls__left {
  text-align: left;
  flex: 1;
  white-space: nowrap;
}

.pack-play-controls__center {
  text-align: center;
  flex: 1;
  white-space: nowrap;

  .btn {
    font-size: 0;
    padding: .3rem;

    > span {
      font-size: 1.8rem;
      display: inline-block;
    }
  }
}

.pack-play-controls__right {
  text-align: right;
  flex: 1;
  white-space: nowrap;

  .btn {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
}

.pack-play-controls__index-text {
}

.play-header {
  background: #fff;
  display: block;
  padding: .5rem;
  margin-bottom: 1rem;
}

.play-header__left {
  white-space: nowrap;
  text-align: center;

  .btn {
    margin-left: .5rem;
    margin-bottom: .5rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.play-card-stack--container {
  position: relative;
}

.play-card-stack {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: visible;

  .animated {
    z-index: 1000;
  }
}

.play-card-stack__card {
  animation-duration: 500ms !important;
  height: 100%;
  width: 100%;
}

.play-card-stack__card-side {
  height: 100%;
  width: 100%;
  position: relative;

  background: #fff;
  box-shadow: 1px 1px 5px fade(#333, 30%);
}

.play-card-stack__card-side.play-card-stack__card-side--pointer {
  cursor: pointer;
}

.play-card-stack__card-side--one-button {
  > button {
    display: block;
    height: 100%;
    width: 100%;
    font-size: 10em;
  }
}

.play-card-stack__card-text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    word-break: break-word;
    user-select: none;
    margin: 0;
    font-size: 2.5rem;
  }
}

.play-card-stack__card-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.play-card-stack__card-side.play-card-stack__card-side--image-and-text {
  .play-card-stack__card-image {
    bottom: 50%;
    padding-bottom: 0;
  }

  .play-card-stack__card-text {
    top: 50%;
  }
}


.play-card-stack.play-card-stack--speakable {
  button.play-card-stack__speak {
    position: absolute;
    right: 0;

    color: #6b6b6b;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    padding: 0.5rem;
    display: inline-block !important;
    line-height: 1;
  }
}

.play-card-stack.play-card-stack--both-faces {
  .play-card-stack__card {
    position: relative;
  }

  .play-card-stack__card-side {
    position: absolute;
    left: 0;
    right: 0;
  }

  .play-card-stack__card-face {
    top: 0;
    height: 50%;
  }

  .play-card-stack__card-back {
    bottom: 0;
    height: 50%;
  }
}


.mixin-play-card-stack__card-side--image-and-text--horizontal(@imagePercent) {
  .play-card-stack__card-side.play-card-stack__card-side--image-and-text {
    .play-card-stack__card-image {
      right: 100% - @imagePercent;
      bottom: 0;
      padding: 1rem 0 1rem 1rem;
    }

    .play-card-stack__card-text {
      left: @imagePercent;
      top: 0;
    }
  }
}

@import "pack-play-sm-landscape";
@import "pack-play-md";
