@test-correct-color: #399011;
@test-wrong-color: red;

.pack-test-prepare {
  background: #fff;
  padding: 1rem 0;
}

.pack-test-prepare__first,
.pack-test-prepare__second {
  padding: 0 1rem;
}

.pack-test-prepare__buttons {
  .btn-primary {
    padding-left: 4em;
    padding-right: 4em;
  }
}

.pack-test-prepare__option {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 1.5rem;
}

.pack-test-prepare__option-title {
  flex: 50%;
  padding-right: 1rem;
}

.pack-test-prepare__option-title-fade {
  opacity: .5;
  font-size: 80%;
  display: block;
}

.pack-test-prepare__option-value {
  flex: 50%;

  .custom-control-label {
    cursor: pointer;
  }

  .form-control--single-underline {
    padding-top: 0;
    height: auto;
  }
}

.pack-test-prepare__option-value-error {
  color: #f89a90;
}

.form-control.form-control--single-underline {
  border-radius: 0;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom-color: rgba(0, 123, 255, .25);
  padding-left: 0;
  padding-right: 0;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.pack-test-set {
  padding: 1em;
  background: #fff;
}

.pack-test-set__title {
  font-weight: bold;
  margin-bottom: 1em;
}

.pack-test-set__matching-questions {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.pack-test-set__matching-question {
  margin-bottom: 1em;
}

.pack-test-set__matching-question--correct {
  color: @test-correct-color;

  select {
    border-color: @test-correct-color;
    color: @test-correct-color;
  }
}

.pack-test-set__matching-question--wrong {
  color: @test-wrong-color;

  select {
    border-color: @test-wrong-color;
    color: @test-wrong-color;
  }
}

.pack-test-set__matching-question-expected {
  display: block;
  margin-top: .2rem;
}

.pack-test-set__matching-answers {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.pack-test-set__matching-question-image,
.pack-test-set__matching-answer-image {
  > img {
    max-width: 100%;
    max-height: 100%;
  }
}

.pack-test-set__matching-answer {
  margin-bottom: 1em;
}

.pack-test-set__matching-answer--occupied {
  opacity: .3;
}

.pack-test-set__written-questions {
  ul {
    list-style: decimal;
  }
}

.pack-test-set__written-question {
  margin-bottom: 1rem;
}

.pack-test-set__written-question--correct {
  color: @test-correct-color;

  > input.form-control {
    padding-left: 1rem;
  }
}

.pack-test-set__written-question--wrong {
  color: @test-wrong-color;

  > input.form-control {
    padding-left: 1rem;
  }
}

.pack-test-set__choice-questions {
  > ul {
    list-style: decimal;
  }
}

.pack-test-set__choice-question {
  margin-bottom: 1rem;
}

.pack-test-set__choice-answer-image,
.pack-test-set__choice-question-image {
  > img {
    max-width: 150px;
    max-height: 150px;
  }
}

.pack-test-set__choice-question--correct {
  color: @test-correct-color;
}

.pack-test-set__choice-question--wrong {
  color: @test-wrong-color;
}

.pack-test-set__choice-answers {
  list-style: none;
  margin: 0;
  padding: 0;

  .custom-control-label {
    cursor: pointer;
  }
}

.pack-test-set__yn-questions {
  list-style: decimal;
}

.pack-test-set__yn-question {
  margin-bottom: 1rem;
}

.pack-test-set__yn-image {
  > img {
    max-width: 150px;
    max-height: 150px;
  }
}

.pack-test-set__yn-question--correct {
  color: @test-correct-color;
}

.pack-test-set__yn-question--wrong {
  color: @test-wrong-color;
}

.pack-test-set__yn-selection {
  .custom-control-label,
  .custom-radio {
    cursor: pointer;
  }
}

.pack-test-set__checkButton {
  margin-top: 2rem;
  text-align: center;

  .btn {
    width: 100%;
  }
}

.pack-test-set {
  .custom-radio.custom-radio-correct {
    color: @test-correct-color;

    .custom-control-label {
      &:after {
        display: none;
      }

      &:before {
        color: @test-correct-color;
        content: "✓";
        border: none;
        top: .1rem;
        background-color: transparent;
      }
    }
  }

  .custom-radio.custom-radio-wrong {
    color: @test-wrong-color;

    .custom-control-label {
      &:after {
        display: none;
      }

      &:before {
        color: @test-wrong-color;
        content: "✗";
        border: none;
        top: .1rem;
        background-color: transparent;
      }
    }
  }
}

.pack-test-score {
  text-align: center;
  border-bottom: solid 1px #ddd;
  background: #fff;
  padding: 2rem;
}

.pack-test-score__title {
  display: block;
  font-size: 150%;
  opacity: .7;
}

.pack-test-score__percent {
  display: block;
  font-size: 200%;
  font-weight: bold;
  color: #4257b1;
}

@import "pack-test-md.less";