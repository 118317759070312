
body {
  //padding-top: 60px;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  //background-color: transparent;
}

frame {
  display: block;
}

.text-account-picture {
  border-radius: 0.2em;
  display: inline-block;
  height: 1.2em;
  //vertical-align: text-bottom;
  vertical-align: sub;
  width: 1.2em;
}

div.ul {
  padding-left: 1em;

  > .li {
    position: relative;
    margin-bottom: .5em;
  }
}

label.fade-label {
  color: #003d66;
  font-weight: normal;

  & + .input-lg {
    padding-top: 0;
  }
}

ul.list-vr {
  margin-left: 0;

  > li {
    padding-left: 0;
    padding-right: 0;

    &:after {
      content: "•";
      color: #ddd;
      padding-left: .6em;
      padding-right: .6em;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.page-option-panel {
  margin-bottom: 1em;
}

.fl-keep-nl {
  white-space: pre-line;
}

.fl-tt-text {
  white-space: pre-line;
}

.fl-tt-html {
  > p {
    margin: 0;
  }
}

@import "base.less";
@import "bootstrap-additions.less";
@import "modal-popover.less";
@import "index.less";
@import "sticky-footer.less";
@import "fullscreenplay.less";
@import "cards.less";
@import "course.less";
@import "pack-page.less";
@import "pack-play.less";
@import "fl-form-control";
@import "fl-learn-tune-form";
@import "fl-learn-progress";
@import "fl-learn-card";
@import "fl-write-card";
@import "fl-memo-controls";
@import "fl-memo-startup";
@import "fl-memo-help";
@import "fl-pack-games-buttons";
@import "fl-pack-header";
@import "fl-pack-main-cols";
@import "fl-pack-embed";
@import "fl-solw";
@import "fl-pack-plist";
@import "my-page.less";
@import "search-page.less";
@import "pack-games.less";
@import "pack-test.less";
@import "pack-edit.less";
@import "fl-form-cards.less";
@import "fl-form-select-image-gallery.less";
@import "sidebar.less";
@import "person_name.less";
@import "fl-explorer.less";
@import "fl-spell-game.less";
@import "fl-inpage-modal.less";
@import "fl-memo-progress.less";
@import "fl-memo-edit-card.less";
@import "fl-landing.less";
@import "fl-match-game.less";
@import "fl-memo-heatmap.less";
@import "fl-dashboard-memo-grid.less";
@import "fl-radial-progress.less";
@import "@iserv2/flashcards-memo-sr/lib/theme/theme";
@import "@iserv2/flashcards-memo-sr/lib/tiptap/tiptap";
@import "iserv2-flashcards-memo-sr";

@import "../app/vue/AddCardSideForm.less";

.row.row-gap-5px {
  margin-right: -5px;
  margin-left: -5px;

  > div[class^="col-"],
  > div[class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.btn-pl {
  display: inline-block;
  border-radius: .2em;
  background-color: #fff;
  border: solid 1px #ccc;
  text-decoration: none;
  line-height: 1.2;
  padding: 1em;
  color: #555;
  font-size: 90%;

  > .icon {
    height: 2em;
    display: block;
    padding: 0 1em 1em;
  }

  > .glyphicon {
    line-height: 1;
    font-size: 2em;
    display: block;
    padding: .3em 1em .5em;
    color: forestgreen;
  }

  > .text {
    font-weight: bold;
  }

  &:hover, &:focus {
    color: #555;
    border-bottom: solid 1px forestgreen;
    text-decoration: none;
  }
}

.form-options-widget-header {
  color: #31708f;
  margin-bottom: .5em;
}

.child-padding-05em {
  > * {
    padding: .5em;
  }
}

.radio-hidden {
  > input[type=radio] {
    display: none;
  }
}

.btn-fc {
  color: #7b7b7b;
  border-radius: 0;
  border: none;
  text-align: center;

  padding-top: 1.5em;
  padding-bottom: 0.5em;

  .btn-text {
    display: block;
    line-height: 2;
  }
}

@pbar-border-radius: 2px;

.pbar {
  position: relative;

  //width: @pbar-width;
  height: 1.7em;

  font-weight: bold;
  font-size: 90%;
  padding: 0;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ededed+0,f5f5f5+7,f3f3f3+100 */
  background: rgb(237, 237, 237); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(237, 237, 237, 1) 0%, rgba(245, 245, 245, 1) 7%, rgba(243, 243, 243, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(237, 237, 237, 1) 0%, rgba(245, 245, 245, 1) 7%, rgba(243, 243, 243, 1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(237, 237, 237, 1) 0%, rgba(245, 245, 245, 1) 7%, rgba(243, 243, 243, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#f3f3f3', GradientType=0); /* IE6-9 */

  border-top: solid 1px #e4e4e4;
  border-right: solid 1px #eeeeee;
  border-radius: @pbar-border-radius;

  .pbar-bar {
    width: 0;

    position: absolute;
    height: 100%;
    top: 0;
    left: 0;

    overflow: hidden;

    //background: #5bc0de;
    border-radius: @pbar-border-radius;
    //border-bottom: solid 1px #4da3bd;
  }

  .pbar-val {
    position: absolute;
    display: block;
    text-align: center;
    //color: #4da3bd;
    height: 100%;
  }

  //.pbar-bar > .pbar-val {
  //	color: #fff;
  //}

  .pbar-colors(@backColor, @textColor:#fff) {
    .pbar-bar {
      background: @backColor;
      border-bottom: solid 1px darken(@backColor, 10%);
    }
    .pbar-val {
      color: darken(@backColor, 10%);
    }
    .pbar-bar > .pbar-val {
      color: @textColor;
    }
  }

  .pbar-colors(#5bc0de);

  &.pbar-green {
    .pbar-colors(#16a765);
  }

  &.pbar-red {
    .pbar-colors(#db6e60);
  }
}

.pbar-200 {
  @pbar-width: 200px;
  width: @pbar-width;

  .pbar-val {
    width: @pbar-width;
  }
}

table.col2 {
  width: 100%;

  td {
    vertical-align: top;
  }

  td:first-child {
    width: 50%;
  }

  td:last-child {
    width: 50%;
  }
}

.list-custom-icons {
  > li {
    min-height: 2em;
    padding-left: 2.5em;
    position: relative;

    > .list-icon {
      display: inline-block;
      position: absolute;
      top: 0;
      left: -.5em;
      width: 2.5em;
      overflow: visible;
      text-align: right;
    }
  }
}

.input-radio-decorator {
  @input-color: #0088cc;

  cursor: pointer;

  > input {
    display: none;
  }

  > span:before {
    content: "⦾";
    font-family: 'flashcards';
    color: @input-color;
    margin-right: .4em;
    font-size: 130%;
    display: inline-block;
    vertical-align: sub;
  }

  > input:checked + span:before {
    content: "⦿";
  }

  &:hover {
    > span:before {
      text-shadow: 0 0 1px @input-color;
    }
  }
}

.input-check-decorator {
  @input-color: #0088cc;

  cursor: pointer;

  > input {
    display: none;
  }

  > span:before {
    content: "☐";
    font-family: 'flashcards';
    color: @input-color;
    margin-right: .4em;
    font-size: 130%;
    display: inline-block;
    vertical-align: sub;
  }

  > input:checked + span:before {
    content: "☑";
  }

  &:hover {
    > span:before {
      text-shadow: 0 0 1px @input-color;
    }
  }
}

.white-container {
  background: #fff;
  padding: 2%;
}

.white-container-title-lg {
  background: #8a9ebf;
  color: #fff;
  padding: 32px 22px;

  > h1 {
    font-size: 24px;
    margin: 0;
  }
}

@media (max-width: @screen-xs-max) {
  .white-container-title-lg {
    padding: 1em;

    > h1 {
      font-size: 18px;
    }
  }
}

.info-nav-list {
  .white-container();

  strong {
    font-size: 18px;
    color: #979797;
    padding: 0 15px 5px;
    display: block;
    font-weight: normal;
  }

  .nav-pills > li > a {
    .pointer();
    padding-top: 5px;
    padding-bottom: 5px;
  }

  ul {
    margin-bottom: 2em;
    margin-left: 0;
  }
}

.info-nav-header {
  background: #25b7d3;
  text-align: center;
  padding: 10px;

  img {
    width: 90px;
  }
}

@keyframes scrollBackgroundX {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 20px 0;
  }
}

@-webkit-keyframes scrollBackgroundX /* Safari and Chrome - necessary duplicate */ {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 20px 0;
  }
}

.btn-in-progress {
  background: url(../img/btn-back-anim.png) repeat-x;

  animation: scrollBackgroundX 1s linear infinite;
  -webkit-animation: scrollBackgroundX 1s linear infinite; /* Chrome and Safari */
}

.form-footer {
  > .btn {
    margin-left: .3em;
    margin-right: .3em;
  }

  margin-top: 1em;
}

.btn-icon {
  margin-right: .35em;
}

.alert-panel {
  padding-left: 130px;
  display: block;
  min-height: 100px;

  background-image: url(../img/alert-80.svg);
  background-position: 25px center;
  background-repeat: no-repeat;
}

.help-content {
  padding-left: 5%;
  padding-right: 5%;

  h2 {
    margin: 1em 0;
  }
}

.right-wing {
  .clearfix();

  > .wing {
    float: right;
  }

  > .content {
    overflow: auto;
  }
}

.btn-oauth-facebook,
.btn-oauth-google {
  .clearfix();

  border-radius: 0;
  border: none;
  width: 250px;
  padding: 1px;
  line-height: 1.6;
  text-align: center;
  color: #fff;

  font-size: 14px;
  font-family: Roboto, serif;
  font-weight: 500;

  .logo {
    float: left;
    width: 3em;
    height: 3em;
    background: white;
    background-size: 100% 100%;
  }

  .btn-text {
    line-height: 3;
  }

  &:hover,
  &:focus {
    color: #000;
  }
}

.btn-oauth-google {
  background-color: #4285f4;

  .logo {
    background-image: url(../img/socials/btn_google_light_normal.svg);
  }
}

.btn-oauth-facebook {
  background-color: #3b579d;

  .logo {
    background-image: url(../img/socials/FB-f-Logo__blue_144.png);
  }
}

.fl-va-block {
  white-space: nowrap;
  font-size: 0;

  > * {
    vertical-align: middle;
  }
}

.fl-va-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.bg-f1f1f1 {
  background-color: #f1f1f1;
}

.border-1-ddd {
  border: solid 1px #dddddd;
}

.fl-btn-green {
  .button-variant(#fff; #00b042; #00b042);
}

.fl-hover-green {
  border-bottom-style: solid !important;
  border-bottom-width: 2px !important;

  &:hover {
    border-bottom-color: #00b042 !important;
  }

  &:focus,
  &:hover:focus {
    border-bottom-color: darken(#00b042, 10%) !important;
  }
}

