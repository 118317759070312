@media (min-width: @screen-md-min) {
  .fl-pack-plist__item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .fl-pack-plist__flags {
    order: 100;
    flex: .2;
  }

  .fl-pack-plist__back {
    border-left: solid 1px #d4d4d4;
  }

  .fl-pack-plist__face,
  .fl-pack-plist__back {
    padding: 1em;
  }

  .fl-pack-plist__flags {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > span {
      display: block;
      order: 100;
      padding-top: .5em;
      padding-bottom: .5rem;
    }

    button {
      padding: .8em;
    }
  }

  .fl-pack-plist__flags-line {
    display: block;
  }
}