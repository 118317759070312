.no-underline {
  &:hover {
    text-decoration: none;
  }
}

.one-liner {
  white-space: nowrap;
}

.noresize {
  resize: none;
}

.w100p {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.mnemonic {
  text-decoration: underline;
}

.resize-v {
  resize: vertical;
}

.fs-0 {
  font-size: 0;
}

.text-lg {
  font-size: 20px;
}

@media (max-width: @screen-sm-max) {
  .text-lg {
    font-size: 15px;
  }
}

.text-normal {
  font-size: 14px;
}

.text-sm {
  font-size: 12px;
}

.vm-2em {
  margin-top: 2em;
  margin-bottom: 2em;
}

.blue-text {
  color: #0088cc;
}

.blue-a {
  color: #a0c3ff;
}

.no-padding {
  padding: 0;
}

.column-count(@val) {
  -moz-column-count: @val;
  -webkit-column-count: @val;
  column-count: @val;
}

.columns-3 {
  width: 100%;

  .column-count(3);

  > div {
    -webkit-column-break-inside: avoid; /* Chrome, Safari */
    page-break-inside: avoid; /* Theoretically FF 20+ */
    break-inside: avoid-column; /* IE 11 */
    display: inline-block; /* Actually FF 20+ */

    width: 100%;
  }
}

@media (max-width: @screen-sm-max) {
  .columns-sm-2 {
    .column-count(2);
  }
}

@media (max-width: @screen-xs-max) {
  .columns-xs-1 {
    .column-count(1);
  }
}

.mr-3em {
  margin-right: 3em;
}