.fl-memo-card__container {
}

.fl-memo-card {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0 0 2px fade(#333, 30%);
  margin-top: 2.5rem;
}

.fl-memo-card__side {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}


.fl-memo-card--both {
  .fl-memo-card__side--question {
    top: 0;
    bottom: auto;
    height: 47%;
  }

  .fl-memo-card__side--answer {
    top: auto;
    bottom: 0;
    height: 47%;
  }
}

.fl-memo-card__side--answer:before {
  position: absolute;
  content: " ";
  height: 1px;
  width: 80%;
  left: 10%;
  top: -7%;
  background: #ddd;
}

.fl-memo-card__tools {
  position: absolute;
  top: -2.5rem;
  right: 0;

  .btn {
    color: #6b6b6b;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    padding: .5rem;
    display: inline-block;
    line-height: 1;

    margin-left: .25rem;
  }
}

.fl-memo-card__actions {
  display: flex;
  flex-direction: row;
  margin-top: -2.5rem;
}

.fl-memo-card__action-btn {
  background: none;
  border: none;
  line-height: 1;
  opacity: .6;

  display: block;
  font-size: 1.2rem;
  padding: 0.5rem;

  &:hover, &:focus, &:active {
    opacity: initial;
  }

  .fa.fa-star {
    color: #ffcd4d;
  }
}

.fl-memo-card__side-text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;

  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fl-memo-card__side-text-content {
  max-height: 100%;
  margin-right: .1rem;
  margin-left: .1rem;

  overflow: auto;
  border-radius: .5rem;

  > div {
    word-break: break-word;
    user-select: none;
    margin: 0;
    font-size: 2.5rem;
  }
}

.fl-memo-card__side-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.fl-memo-card__side-image {
  img.enlargeable {
    cursor: pointer;
  }
}

.fl-memo-card__side.fl-memo-card__side--display-all {
  .fl-memo-card__side-image {
    bottom: 50%;
    padding-bottom: 0;
  }

  .fl-memo-card__side-text {
    top: 50%;
  }
}

.fl-memo-card__side-text {
  @side-text-back-color: #00000075;
  @side-text-scroll-thumb-color: #bfbfbf91;

  padding: 0;

  .fl-memo-card__side-text-content {
    &::-webkit-scrollbar {
      width: .5rem;
      height: .5rem;
    }

    &::-webkit-scrollbar-track {
      border-radius: .3rem;
      background: transparent;
      margin: 0.2rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: .3rem;
      background: @side-text-scroll-thumb-color;

      //&:hover,
      //&:focus {
      //  background: @side-text-scroll-thumb-color;
      //}
    }
  }

  &.fl-memo-card__side-text--align-left {
    text-align: left;
  }

  &.fl-memo-card__side-text--text-small,
  &.fl-memo-card__side-text--text-xsmall,
  &.fl-memo-card__side-text--text-xxsmall {
    .fl-memo-card__side-text-content {
      background: @side-text-back-color;
      padding: 1.5rem;
    }
  }

  &.fl-memo-card__side-text--text-small .fl-memo-card__side-text-content > div {
    font-size: 2rem;
  }

  &.fl-memo-card__side-text--text-xsmall .fl-memo-card__side-text-content > div {
    font-size: 1.5rem;
  }

  &.fl-memo-card__side-text--text-xxsmall .fl-memo-card__side-text-content > div {
    font-size: 1rem;
  }
}

@import "fl-memo-card--sm-landspace.less";
@import "fl-memo-card--md.less";