@fl-radial-progress-width: 2px;
@fl-radial-progress-space: 5px;
@fl-radial-progress-none-color: #efefef;
@fl-radial-progress-gray-color: #d7d7d7;
@fl-radial-progress-active-color: #00bccc;

.fl-radial-progress {
  position: relative;
  display: inline-block;
}

svg.fl-radial-progress__svg {
  position: absolute;
  overflow: visible;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  stroke-width: 2;
  fill: transparent;
}

.fl-radial-progress__svg-track,
.fl-radial-progress__svg-thumb {
  transform: translateX(50%) translateY(50%) rotate(-90deg);
}

.fl-radial-progress__svg-thumb {
  stroke: @fl-radial-progress-active-color;
}

.fl-radial-progress__svg-track {
  stroke: @fl-radial-progress-none-color;
}

.fl-radial-progress--blue {
  .fl-radial-progress__svg-track {
    stroke: @fl-radial-progress-none-color;
  }
}

.fl-radial-progress__text {
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
