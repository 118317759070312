@media (orientation: landscape) {
  .fl-memo-choice__question--1,
  .fl-memo-choice__question--2,
  .fl-memo-choice__question--3,
  .fl-memo-choice__question--4,
  .fl-memo-choice__question--5,
  .fl-memo-choice__question--6,
  .fl-memo-choice__question--7,
  .fl-memo-choice__question--8 {
    .fl-memo-choice__question--height-proportion(50%);
  }
}