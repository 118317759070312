.fl-learn-tune-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem 0;

  border-bottom: solid 1px #ddd;

  &:last-child {
    border-bottom: none;
  }
}

.fl-learn-tune-form__label {
  flex: 40%;
  max-width: 40%;
  margin-bottom: 0;
}

.fl-learn-tune-form__content {
  flex: 60%;

  .btn-sm {
    margin-top: -.2rem;
  }

  .custom-control-label {
    cursor: pointer;
  }
}

.fl-learn-tune-form__error {
  flex: 100%;
  text-align: center;
  color: #f89a90;
}

.fl-learn-tune-form__notice {
  flex: 100%;
  text-align: left;
  opacity: 0.7;
  font-size: 80%;
}