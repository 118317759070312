.fl-solw {
  position: relative;
  padding: 1rem;
}

.fl-solw__help {
  position: absolute;
  right: 0;
  top: 0;

  .btn {
    font-size: 1.2rem;
    color: #dddddd;
  }
}

.fl-solw__title {
  font-size: 120%;
  color: #555;
  margin-right: 1rem;
}

.fl-solw__title-icon {
  font-size: 150%;
  vertical-align: middle;
  margin-right: .25em;
}

.fl-solw__title-small {
  display: block;
  margin-left: 3em;
  line-height: 1;
  font-size: 70%;
  opacity: .6;
}

.fl-solw__play {
  font-size: 200%;
  padding-left: .5rem;
  padding-right: .5rem;
}

.fl-solw__numbers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 1rem;
  min-width: 1rem;
}

.fl-solw__numbers-line {
  white-space: nowrap;
  flex: 100%;
}

.fl-solw__numbers-item {
  white-space: nowrap;
  margin-right: 1rem;
}

.fl-solw__numbers-label {
  opacity: .5;
}

.fl-solw__numbers-value--green {
  color: #72cd4e;
}

.fl-solw__numbers-value--blue {
  color: #007bff;
}

@import "fl-solw--md";