@media (min-width: @screen-md-min) {
  .fl-pack-games-buttons {
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;

    .btn:last-child {
      margin-bottom: 0;
    }
  }

  .fl-pack-games-buttons__group {
    width: auto;
    flex: 0;
  }

  .fl-pack-games-buttons__filler {
    display: initial;
    flex: 1;
  }

  .fl-pack-games-buttons__icon {
    margin-top: .4em;
    display: inline-block;
  }

  .fl-pack-games-buttons__text {
    float: right;
  }
}