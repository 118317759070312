.fl-memo-progress {
  display: block;
  color: #ffffff;
  margin-left: 1rem;
  margin-right: 1rem;
}

.fl-memo-progress__text-line {
  display: flex;
  flex-direction: row;
}

.fl-memo-progress__text-main {
  flex: 1;
}

.fl-memo-progress__count {
  margin-left: 1rem;
}

.fl-memo-progress__text-fade {
  opacity: .7;
}

.fl-memo-progress__bar {
  width: 100%;
  height: .6rem;
  border-radius: .2rem;
  background: fade(#ffffff, 30%);
  overflow: hidden;
}

.fl-memo-progress__bar-thumb {
  height: 100%;
  background: #ffffff;
}

@import "fl-memo-progress--xs";
@import "fl-memo-progress--xs-portrait";
@import "fl-memo-progress--lg";