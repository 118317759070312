.course-header {
	> h1 {
		margin-top: 0;
	}
}

.course-title-panel {
	padding-left: 15px;
	padding-right: 15px;

	h1 {
		margin-top: 0;
	}
}