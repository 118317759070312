@media (min-width: @screen-md-min) {
  .play-card-stack--container {
    max-height: 24rem !important;
    max-width: 42rem !important;
  }

  .pack-play-controls {
    max-width: 42rem !important;
    margin-left: auto;
    margin-right: auto;
  }

  .play-card-stack__card-text {
    font-size: 3rem;
  }

  .mixin-play-card-stack__card-side--image-and-text--horizontal(40%);

  .play-card-stack.play-card-stack--both-faces {
    .mixin-play-card-stack__card-side--image-and-text--horizontal(30%);
  }
}