.my-sidebar {
  background: #fff;
}

.my-main {
  background: #fff;
}

.my-sidebar {
  .nav {
    margin-top: 1em;
  }

  .nav-link {
    padding: 5px 20px;
  }

  .nav-pills-header {
    color: #9b9b9b;
    position: relative;

    &:before {
      position: absolute;
      left: 0;
      top: 1.5em;
      right: 0;
      height: 1px;
      content: " ";
      background: #f1f1f1;
    }

    > span {
      padding-left: 5px;
      padding-right: 5px;
      display: inline-block;
      background: #fff;
      margin-left: 15px;
    }
  }
}

/*
.sidebar-size(@sidebarWidth, @sidebarGap : 15px) {
	width: @sidebarWidth;
	+ .main {
		margin-left: @sidebarWidth + @sidebarGap;
	}
}

.sidebar-left() {
	float: left;
	margin: 0;
}

.sidebar {
	display: block;

	+ .main {
		overflow: auto;
	}

	&:after {
		content: " ";
		display: block;
		width: 100%;
		height: 20px;
		background: #f8f8fa;
	}

	@media (min-width: @screen-sm-min) {
		.sidebar-size(250px, 10px);
		.sidebar-left();
		&:after {
			display: none;
		}
	}
	@media (min-width: @screen-md-min) {
		.sidebar-size(290px, 10px);
		.sidebar-left();
		&:after {
			display: none;
		}
	}
	@media (min-width: @screen-lg-min) {
		.sidebar-size(315px);
		.sidebar-left();
		&:after {
			display: none;
		}
	}
}

.sidebar {
	.nav-pills {
		margin-top: 1em;
		> li {
			> a {
				padding: 5px 20px;
			}
		}
	}

	li.nav-pills-header {
		color: #9b9b9b;
		position: relative;

		&:before {
			position: absolute;
			left: 0;
			top: 1.5em;
			right: 0;
			height: 1px;
			content: " ";
			background: #f1f1f1;
		}

		> span {
			padding-left: 5px;
			padding-right: 5px;
			display: inline-block;
			background: #fff;
			margin-left: 15px;
		}
	}
}

.sidebar-collapsible {
	display: none;

	@media (min-width: @screen-sm-min) {
		display: block;
	}
}

.sidebar-background {
	height: 0;
	overflow: visible;

	.sidebar {
		position: absolute;
		top: 71px;
		bottom: 0;
		left: 50%;
		z-index: -1000;

		background: #fff;
	}

	.main {
		position: absolute;
		top: 71px;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: -1000;
		margin-left: 15px;
		margin-right: 15px;

		background: #fff;
	}

	@media (min-width: @screen-sm-min) {
		display: block;

		.sidebar {
			width: 250px;
			margin-left: -360px;
		}

		.main {
			left: 50%;
			margin-right: 0;
			margin-left: -100px;
			width: 460px;
		}
	}
	@media (min-width: @screen-md-min) {
		.sidebar {
			width: 290px;
			margin-left: -470px;
		}

		.main {
			left: 50%;
			margin-right: 0;
			margin-left: -170px;
			width: 640px;
		}
	}
	@media (min-width: @screen-lg-min) {
		.sidebar {
			width: 315px;
			margin-left: -570px;
		}

		.main {
			left: 50%;
			margin-right: 0;
			margin-left: -240px;
			width: 810px;
		}
	}
}

.nav.mini-nav {
	float: right;
	margin: 5px 5px;

	> li {
		float: left;
		box-shadow: none;
	}

	> li > a {
		display: block;
	}

	> li > a > img {
		width: 40px;
	}
}
*/