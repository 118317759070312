@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?90zax2');
  src:  url('fonts/icomoon.eot?90zax2#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?90zax2') format('truetype'),
    url('fonts/icomoon.woff?90zax2') format('woff'),
    url('fonts/icomoon.svg?90zax2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="fc-"], [class*=" fc-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fc-match:before {
  content: "\e911";
}
.fc-repeat-alt:before {
  content: "\e910";
}
.fc-image:before {
  content: "\e90f";
}
.fc-plus-in-circle:before {
  content: "\e90e";
}
.fc-arrow-left:before {
  content: "\e90c";
}
.fc-arrow-right:before {
  content: "\e90d";
}
.fc-check-button:before {
  content: "\e900";
}
.fc-check-button-checked:before {
  content: "\e901";
}
.fc-pack-button-learn:before {
  content: "\e902";
}
.fc-pack-button-play:before {
  content: "\e903";
}
.fc-pack-button-test:before {
  content: "\e904";
}
.fc-pack-button-write:before {
  content: "\e905";
}
.fc-pack-icon-info:before {
  content: "\e906";
}
.fc-pack-icon-plus:before {
  content: "\e907";
}
.fc-pack-icon-share:before {
  content: "\e908";
}
.fc-pack-icon-trash:before {
  content: "\e909";
}
.fc-radio-button:before {
  content: "\e90a";
}
.fc-radio-button-checked:before {
  content: "\e90b";
}
