.fl-memo-choice {
}

.fl-memo-choice__question {
  position: relative;
}

.fl-memo-choice__question--height-proportion(@height) {
  height: @height;

  + .fl-memo-choice__buttons {
    height: 100% - @height;
  }
}

.fl-memo-choice__question--1 {
  .fl-memo-choice__question--height-proportion(70%);
}

.fl-memo-choice__question--2 {
  .fl-memo-choice__question--height-proportion(60%);
}

.fl-memo-choice__question--3 {
  .fl-memo-choice__question--height-proportion(50%);
}

.fl-memo-choice__question--4,
.fl-memo-choice__question--5 {
  .fl-memo-choice__question--height-proportion(50%);
}

.fl-memo-choice__question--6,
.fl-memo-choice__question--7,
.fl-memo-choice__question--8 {
  .fl-memo-choice__question--height-proportion(40%);
}

.fl-memo-choice__buttons {
  > .fl-learn-card__answers {
    height: 100%;
  }
}

@import "fl-memo-choice--landscape";
@import "fl-memo-choice--md";