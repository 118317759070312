.fl-match-game__timer {
  color: #fff;
}

.fl-match-game__timer-label {
  font-weight: bold;
  font-size: 80%;
  opacity: .8;
}

.fl-match-game__field {
  background: #f1f1f1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fl-match-game__side {
  position: absolute;
  background: #fff;
  border: solid 2px #eee;
  padding-top: .5rem;
  cursor: pointer;
  user-select: none;
  left: 50%;
  top: 50%;

  > div {
    display: block;
    margin: 0;
    max-height: 50vh;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 .5rem .5rem .5rem;
    //max-width: 150px;
  }
}

.fl-match-game__side-image {
  > img {
    max-width: 150px;
    max-height: 150px;
  }

  padding: 0 .5rem .5rem .5rem;
  text-align: center;
}

.fl-match-game__side--moving {
  border-color: #bbb;
}

.fl-match-game__side--over {
  border-color: #198fbb;
}