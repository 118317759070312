.user-collections-select-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;

  > li {
    display: block;
    margin: .2rem;
  }
}

.user-collections-select-list__item {
  .btn {
    border-color: #0788ff;
    color: #007cff;
    padding: .2rem .5rem;
  }

  .btn.btn-pressed {
    background-color: #007cff;
    color: #fff;
  }
}

.user-collections-select-list__new {
  .btn {
    border-color: #0788ff;
    color: #007cff;
    background: #ffefbf;
    padding: .2rem .5rem;
  }
}

@import "pack-page-md";
@import "pack-page-portrait";