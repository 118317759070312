html.fl-fullscreenplay {
  height: 100%;
  width: 100%;
}

body.fl-fullscreenplay {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  min-height: auto !important;

  > * {
    width: 100%;
  }

  > .cc-revoke {
    display: none !important;
  }
}

.fl-fullscreenplay--main {
  flex: 1;
  position: relative;

  /* >> quick fix for #60. memo в режиме write на телефоне не уменьшает поле, когда появляется экранная клавиатура
   * https://www.reddit.com/r/webdev/comments/alg8az/anyone_experiencing_issues_with_flexbox_and/effcldd?utm_source=share&utm_medium=web2x
   */
  min-height: 0;
  flex-grow: 1;
  /* << */
}

.fl-fp-head {
  .clearfix();

  background: #00a1ff;
  padding: .5rem;
  margin-bottom: .5rem;
  width: 100%;

  @btn-color: #00acff;

  .btn {
    color: #fff;
    background-color: @btn-color;
    border-color: @btn-color;
    opacity: .85;

    &:hover,
    &:focus,
    &:active {
      opacity: 1;
    }
  }
}

.fl-fp-head__title {
  font-size: 1rem;
  line-height: 2.4;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #fff;
  margin: 0;
  padding: 0 .5rem;
  white-space: nowrap;
}

.fl-progress-bar {
  width: 100%;
}

.fl-progress-bar__title {
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
  line-height: 1.5;
  color: #ffffff;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.fl-progress-bar__title-text {
  text-align: left;
}

.fl-progress-bar__title-text-label {
  opacity: .7;
  margin-right: .7rem;
}

.fl-progress-bar__title-text-val {
  opacity: 1;
  white-space: nowrap;
}

.fl-progress-bar__bar {
  position: relative;
  height: 1rem;
  width: 100%;
  background: #35b4ff;
  border-radius: .25rem;
}

.fl-progress-bar__thumb {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  background: #c8e4fc;
  border-radius: .25rem;
}