/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@font-face {
  font-family: icomoon;
  src: url("icomoon.9252cb1f.eot");
  src: url("icomoon.9252cb1f.eot#iefix") format("embedded-opentype"), url("icomoon.825edb42.ttf") format("truetype"), url("icomoon.426f83b6.woff") format("woff"), url("icomoon.cdf88e6f.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="fc-"], [class*=" fc-"] {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icomoon !important;
}

.fc-match:before {
  content: "";
}

.fc-repeat-alt:before {
  content: "";
}

.fc-image:before {
  content: "";
}

.fc-plus-in-circle:before {
  content: "";
}

.fc-arrow-left:before {
  content: "";
}

.fc-arrow-right:before {
  content: "";
}

.fc-check-button:before {
  content: "";
}

.fc-check-button-checked:before {
  content: "";
}

.fc-pack-button-learn:before {
  content: "";
}

.fc-pack-button-play:before {
  content: "";
}

.fc-pack-button-test:before {
  content: "";
}

.fc-pack-button-write:before {
  content: "";
}

.fc-pack-icon-info:before {
  content: "";
}

.fc-pack-icon-plus:before {
  content: "";
}

.fc-pack-icon-share:before {
  content: "";
}

.fc-pack-icon-trash:before {
  content: "";
}

.fc-radio-button:before {
  content: "";
}

.fc-radio-button-checked:before {
  content: "";
}

@font-face {
  font-family: Glyphicons Halflings;
  src: url("glyphicons-halflings-regular.cca02454.eot");
  src: url("glyphicons-halflings-regular.cca02454.eot#iefix") format("embedded-opentype"), url("glyphicons-halflings-regular.a3cea8f3.woff2") format("woff2"), url("glyphicons-halflings-regular.3a458e4a.woff") format("woff"), url("glyphicons-halflings-regular.1a33c1eb.ttf") format("truetype"), url("glyphicons-halflings-regular.5497fc8b.svg#glyphicons_halflingsregular") format("svg");
}

.glyphicon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Glyphicons Halflings;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
  position: relative;
  top: 1px;
}

.glyphicon-asterisk:before {
  content: "*";
}

.glyphicon-plus:before {
  content: "+";
}

.glyphicon-euro:before, .glyphicon-eur:before {
  content: "€";
}

.glyphicon-minus:before {
  content: "−";
}

.glyphicon-cloud:before {
  content: "☁";
}

.glyphicon-envelope:before {
  content: "✉";
}

.glyphicon-pencil:before {
  content: "✏";
}

.glyphicon-glass:before {
  content: "";
}

.glyphicon-music:before {
  content: "";
}

.glyphicon-search:before {
  content: "";
}

.glyphicon-heart:before {
  content: "";
}

.glyphicon-star:before {
  content: "";
}

.glyphicon-star-empty:before {
  content: "";
}

.glyphicon-user:before {
  content: "";
}

.glyphicon-film:before {
  content: "";
}

.glyphicon-th-large:before {
  content: "";
}

.glyphicon-th:before {
  content: "";
}

.glyphicon-th-list:before {
  content: "";
}

.glyphicon-ok:before {
  content: "";
}

.glyphicon-remove:before {
  content: "";
}

.glyphicon-zoom-in:before {
  content: "";
}

.glyphicon-zoom-out:before {
  content: "";
}

.glyphicon-off:before {
  content: "";
}

.glyphicon-signal:before {
  content: "";
}

.glyphicon-cog:before {
  content: "";
}

.glyphicon-trash:before {
  content: "";
}

.glyphicon-home:before {
  content: "";
}

.glyphicon-file:before {
  content: "";
}

.glyphicon-time:before {
  content: "";
}

.glyphicon-road:before {
  content: "";
}

.glyphicon-download-alt:before {
  content: "";
}

.glyphicon-download:before {
  content: "";
}

.glyphicon-upload:before {
  content: "";
}

.glyphicon-inbox:before {
  content: "";
}

.glyphicon-play-circle:before {
  content: "";
}

.glyphicon-repeat:before {
  content: "";
}

.glyphicon-refresh:before {
  content: "";
}

.glyphicon-list-alt:before {
  content: "";
}

.glyphicon-lock:before {
  content: "";
}

.glyphicon-flag:before {
  content: "";
}

.glyphicon-headphones:before {
  content: "";
}

.glyphicon-volume-off:before {
  content: "";
}

.glyphicon-volume-down:before {
  content: "";
}

.glyphicon-volume-up:before {
  content: "";
}

.glyphicon-qrcode:before {
  content: "";
}

.glyphicon-barcode:before {
  content: "";
}

.glyphicon-tag:before {
  content: "";
}

.glyphicon-tags:before {
  content: "";
}

.glyphicon-book:before {
  content: "";
}

.glyphicon-bookmark:before {
  content: "";
}

.glyphicon-print:before {
  content: "";
}

.glyphicon-camera:before {
  content: "";
}

.glyphicon-font:before {
  content: "";
}

.glyphicon-bold:before {
  content: "";
}

.glyphicon-italic:before {
  content: "";
}

.glyphicon-text-height:before {
  content: "";
}

.glyphicon-text-width:before {
  content: "";
}

.glyphicon-align-left:before {
  content: "";
}

.glyphicon-align-center:before {
  content: "";
}

.glyphicon-align-right:before {
  content: "";
}

.glyphicon-align-justify:before {
  content: "";
}

.glyphicon-list:before {
  content: "";
}

.glyphicon-indent-left:before {
  content: "";
}

.glyphicon-indent-right:before {
  content: "";
}

.glyphicon-facetime-video:before {
  content: "";
}

.glyphicon-picture:before {
  content: "";
}

.glyphicon-map-marker:before {
  content: "";
}

.glyphicon-adjust:before {
  content: "";
}

.glyphicon-tint:before {
  content: "";
}

.glyphicon-edit:before {
  content: "";
}

.glyphicon-share:before {
  content: "";
}

.glyphicon-check:before {
  content: "";
}

.glyphicon-move:before {
  content: "";
}

.glyphicon-step-backward:before {
  content: "";
}

.glyphicon-fast-backward:before {
  content: "";
}

.glyphicon-backward:before {
  content: "";
}

.glyphicon-play:before {
  content: "";
}

.glyphicon-pause:before {
  content: "";
}

.glyphicon-stop:before {
  content: "";
}

.glyphicon-forward:before {
  content: "";
}

.glyphicon-fast-forward:before {
  content: "";
}

.glyphicon-step-forward:before {
  content: "";
}

.glyphicon-eject:before {
  content: "";
}

.glyphicon-chevron-left:before {
  content: "";
}

.glyphicon-chevron-right:before {
  content: "";
}

.glyphicon-plus-sign:before {
  content: "";
}

.glyphicon-minus-sign:before {
  content: "";
}

.glyphicon-remove-sign:before {
  content: "";
}

.glyphicon-ok-sign:before {
  content: "";
}

.glyphicon-question-sign:before {
  content: "";
}

.glyphicon-info-sign:before {
  content: "";
}

.glyphicon-screenshot:before {
  content: "";
}

.glyphicon-remove-circle:before {
  content: "";
}

.glyphicon-ok-circle:before {
  content: "";
}

.glyphicon-ban-circle:before {
  content: "";
}

.glyphicon-arrow-left:before {
  content: "";
}

.glyphicon-arrow-right:before {
  content: "";
}

.glyphicon-arrow-up:before {
  content: "";
}

.glyphicon-arrow-down:before {
  content: "";
}

.glyphicon-share-alt:before {
  content: "";
}

.glyphicon-resize-full:before {
  content: "";
}

.glyphicon-resize-small:before {
  content: "";
}

.glyphicon-exclamation-sign:before {
  content: "";
}

.glyphicon-gift:before {
  content: "";
}

.glyphicon-leaf:before {
  content: "";
}

.glyphicon-fire:before {
  content: "";
}

.glyphicon-eye-open:before {
  content: "";
}

.glyphicon-eye-close:before {
  content: "";
}

.glyphicon-warning-sign:before {
  content: "";
}

.glyphicon-plane:before {
  content: "";
}

.glyphicon-calendar:before {
  content: "";
}

.glyphicon-random:before {
  content: "";
}

.glyphicon-comment:before {
  content: "";
}

.glyphicon-magnet:before {
  content: "";
}

.glyphicon-chevron-up:before {
  content: "";
}

.glyphicon-chevron-down:before {
  content: "";
}

.glyphicon-retweet:before {
  content: "";
}

.glyphicon-shopping-cart:before {
  content: "";
}

.glyphicon-folder-close:before {
  content: "";
}

.glyphicon-folder-open:before {
  content: "";
}

.glyphicon-resize-vertical:before {
  content: "";
}

.glyphicon-resize-horizontal:before {
  content: "";
}

.glyphicon-hdd:before {
  content: "";
}

.glyphicon-bullhorn:before {
  content: "";
}

.glyphicon-bell:before {
  content: "";
}

.glyphicon-certificate:before {
  content: "";
}

.glyphicon-thumbs-up:before {
  content: "";
}

.glyphicon-thumbs-down:before {
  content: "";
}

.glyphicon-hand-right:before {
  content: "";
}

.glyphicon-hand-left:before {
  content: "";
}

.glyphicon-hand-up:before {
  content: "";
}

.glyphicon-hand-down:before {
  content: "";
}

.glyphicon-circle-arrow-right:before {
  content: "";
}

.glyphicon-circle-arrow-left:before {
  content: "";
}

.glyphicon-circle-arrow-up:before {
  content: "";
}

.glyphicon-circle-arrow-down:before {
  content: "";
}

.glyphicon-globe:before {
  content: "";
}

.glyphicon-wrench:before {
  content: "";
}

.glyphicon-tasks:before {
  content: "";
}

.glyphicon-filter:before {
  content: "";
}

.glyphicon-briefcase:before {
  content: "";
}

.glyphicon-fullscreen:before {
  content: "";
}

.glyphicon-dashboard:before {
  content: "";
}

.glyphicon-paperclip:before {
  content: "";
}

.glyphicon-heart-empty:before {
  content: "";
}

.glyphicon-link:before {
  content: "";
}

.glyphicon-phone:before {
  content: "";
}

.glyphicon-pushpin:before {
  content: "";
}

.glyphicon-usd:before {
  content: "";
}

.glyphicon-gbp:before {
  content: "";
}

.glyphicon-sort:before {
  content: "";
}

.glyphicon-sort-by-alphabet:before {
  content: "";
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "";
}

.glyphicon-sort-by-order:before {
  content: "";
}

.glyphicon-sort-by-order-alt:before {
  content: "";
}

.glyphicon-sort-by-attributes:before {
  content: "";
}

.glyphicon-sort-by-attributes-alt:before {
  content: "";
}

.glyphicon-unchecked:before {
  content: "";
}

.glyphicon-expand:before {
  content: "";
}

.glyphicon-collapse-down:before {
  content: "";
}

.glyphicon-collapse-up:before {
  content: "";
}

.glyphicon-log-in:before {
  content: "";
}

.glyphicon-flash:before {
  content: "";
}

.glyphicon-log-out:before {
  content: "";
}

.glyphicon-new-window:before {
  content: "";
}

.glyphicon-record:before {
  content: "";
}

.glyphicon-save:before {
  content: "";
}

.glyphicon-open:before {
  content: "";
}

.glyphicon-saved:before {
  content: "";
}

.glyphicon-import:before {
  content: "";
}

.glyphicon-export:before {
  content: "";
}

.glyphicon-send:before {
  content: "";
}

.glyphicon-floppy-disk:before {
  content: "";
}

.glyphicon-floppy-saved:before {
  content: "";
}

.glyphicon-floppy-remove:before {
  content: "";
}

.glyphicon-floppy-save:before {
  content: "";
}

.glyphicon-floppy-open:before {
  content: "";
}

.glyphicon-credit-card:before {
  content: "";
}

.glyphicon-transfer:before {
  content: "";
}

.glyphicon-cutlery:before {
  content: "";
}

.glyphicon-header:before {
  content: "";
}

.glyphicon-compressed:before {
  content: "";
}

.glyphicon-earphone:before {
  content: "";
}

.glyphicon-phone-alt:before {
  content: "";
}

.glyphicon-tower:before {
  content: "";
}

.glyphicon-stats:before {
  content: "";
}

.glyphicon-sd-video:before {
  content: "";
}

.glyphicon-hd-video:before {
  content: "";
}

.glyphicon-subtitles:before {
  content: "";
}

.glyphicon-sound-stereo:before {
  content: "";
}

.glyphicon-sound-dolby:before {
  content: "";
}

.glyphicon-sound-5-1:before {
  content: "";
}

.glyphicon-sound-6-1:before {
  content: "";
}

.glyphicon-sound-7-1:before {
  content: "";
}

.glyphicon-copyright-mark:before {
  content: "";
}

.glyphicon-registration-mark:before {
  content: "";
}

.glyphicon-cloud-download:before {
  content: "";
}

.glyphicon-cloud-upload:before {
  content: "";
}

.glyphicon-tree-conifer:before {
  content: "";
}

.glyphicon-tree-deciduous:before {
  content: "";
}

.glyphicon-cd:before {
  content: "";
}

.glyphicon-save-file:before {
  content: "";
}

.glyphicon-open-file:before {
  content: "";
}

.glyphicon-level-up:before {
  content: "";
}

.glyphicon-copy:before {
  content: "";
}

.glyphicon-paste:before {
  content: "";
}

.glyphicon-alert:before {
  content: "";
}

.glyphicon-equalizer:before {
  content: "";
}

.glyphicon-king:before {
  content: "";
}

.glyphicon-queen:before {
  content: "";
}

.glyphicon-pawn:before {
  content: "";
}

.glyphicon-bishop:before {
  content: "";
}

.glyphicon-knight:before {
  content: "";
}

.glyphicon-baby-formula:before {
  content: "";
}

.glyphicon-tent:before {
  content: "⛺";
}

.glyphicon-blackboard:before {
  content: "";
}

.glyphicon-bed:before {
  content: "";
}

.glyphicon-apple:before {
  content: "";
}

.glyphicon-erase:before {
  content: "";
}

.glyphicon-hourglass:before {
  content: "⌛";
}

.glyphicon-lamp:before {
  content: "";
}

.glyphicon-duplicate:before {
  content: "";
}

.glyphicon-piggy-bank:before {
  content: "";
}

.glyphicon-scissors:before {
  content: "";
}

.glyphicon-bitcoin:before, .glyphicon-btc:before, .glyphicon-xbt:before {
  content: "";
}

.glyphicon-yen:before, .glyphicon-jpy:before {
  content: "¥";
}

.glyphicon-ruble:before, .glyphicon-rub:before {
  content: "₽";
}

.glyphicon-scale:before {
  content: "";
}

.glyphicon-ice-lolly:before {
  content: "";
}

.glyphicon-ice-lolly-tasted:before {
  content: "";
}

.glyphicon-education:before {
  content: "";
}

.glyphicon-option-horizontal:before {
  content: "";
}

.glyphicon-option-vertical:before {
  content: "";
}

.glyphicon-menu-hamburger:before {
  content: "";
}

.glyphicon-modal-window:before {
  content: "";
}

.glyphicon-oil:before {
  content: "";
}

.glyphicon-grain:before {
  content: "";
}

.glyphicon-sunglasses:before {
  content: "";
}

.glyphicon-text-size:before {
  content: "";
}

.glyphicon-text-color:before {
  content: "";
}

.glyphicon-text-background:before {
  content: "";
}

.glyphicon-object-align-top:before {
  content: "";
}

.glyphicon-object-align-bottom:before {
  content: "";
}

.glyphicon-object-align-horizontal:before {
  content: "";
}

.glyphicon-object-align-left:before {
  content: "";
}

.glyphicon-object-align-vertical:before {
  content: "";
}

.glyphicon-object-align-right:before {
  content: "";
}

.glyphicon-triangle-right:before {
  content: "";
}

.glyphicon-triangle-left:before {
  content: "";
}

.glyphicon-triangle-bottom:before {
  content: "";
}

.glyphicon-triangle-top:before {
  content: "";
}

.glyphicon-console:before {
  content: "";
}

.glyphicon-superscript:before {
  content: "";
}

.glyphicon-subscript:before {
  content: "";
}

.glyphicon-menu-left:before {
  content: "";
}

.glyphicon-menu-right:before {
  content: "";
}

.glyphicon-menu-down:before {
  content: "";
}

.glyphicon-menu-up:before {
  content: "";
}

frame {
  display: block;
}

.text-account-picture {
  vertical-align: sub;
  border-radius: .2em;
  width: 1.2em;
  height: 1.2em;
  display: inline-block;
}

div.ul {
  padding-left: 1em;
}

div.ul > .li {
  margin-bottom: .5em;
  position: relative;
}

label.fade-label {
  color: #003d66;
  font-weight: normal;
}

label.fade-label + .input-lg {
  padding-top: 0;
}

ul.list-vr {
  margin-left: 0;
}

ul.list-vr > li {
  padding-left: 0;
  padding-right: 0;
}

ul.list-vr > li:after {
  content: "•";
  color: #ddd;
  padding-left: .6em;
  padding-right: .6em;
}

ul.list-vr > li:last-child:after {
  display: none;
}

.page-option-panel {
  margin-bottom: 1em;
}

.no-underline:hover {
  text-decoration: none;
}

.one-liner {
  white-space: nowrap;
}

.noresize {
  resize: none;
}

.w100p {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.mnemonic {
  text-decoration: underline;
}

.resize-v {
  resize: vertical;
}

.fs-0 {
  font-size: 0;
}

.text-lg {
  font-size: 20px;
}

@media (width <= 991px) {
  .text-lg {
    font-size: 15px;
  }
}

.text-normal {
  font-size: 14px;
}

.text-sm {
  font-size: 12px;
}

.vm-2em {
  margin-top: 2em;
  margin-bottom: 2em;
}

.blue-text {
  color: #08c;
}

.blue-a {
  color: #a0c3ff;
}

.no-padding {
  padding: 0;
}

.columns-3 {
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  width: 100%;
}

.columns-3 > div {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
  width: 100%;
  display: inline-block;
}

@media (width <= 991px) {
  .columns-sm-2 {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (width <= 767px) {
  .columns-xs-1 {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}

.mr-3em {
  margin-right: 3em;
}

label {
  font-weight: normal;
}

.cursor-default {
  cursor: default !important;
}

.cursor-na {
  cursor: not-allowed !important;
}

.row .row {
  margin-left: 0;
  margin-right: 0;
}

.btn-lg {
  border-radius: 2px;
}

input.input-text, textarea.input-textarea, select.input-select {
  background: #fff;
  border: 2px solid #0000;
  border-bottom-color: #17a3ff4d;
  border-radius: 0;
  padding: 4px 8px;
}

input.input-text:focus, textarea.input-textarea:focus, select.input-select:focus, input.input-text:hover, textarea.input-textarea:hover, select.input-select:hover {
  border-bottom-color: #17a3ffcc;
}

input.input-text.input-error, textarea.input-textarea.input-error, select.input-select.input-error {
  background-color: #ffdcdc4d;
  border-bottom-color: #ff00004d;
}

input.input-text.input-error:focus, textarea.input-textarea.input-error:focus, select.input-select.input-error:focus, input.input-text.input-error:hover, textarea.input-textarea.input-error:hover, select.input-select.input-error:hover {
  border-bottom-color: #f00c;
}

textarea.input-textarea {
  resize: vertical;
  width: 100%;
  min-height: 40px;
}

.has-error > .input-text, .has-error > .input-textarea {
  background-color: #ffdcdc4d;
  border-bottom-color: #ff00004d;
}

.has-error > .input-text:focus, .has-error > .input-textarea:focus, .has-error > .input-text:hover, .has-error > .input-textarea:hover {
  border-bottom-color: #f00c;
}

.has-error > .control-label {
  display: block;
}

.btn-glyphicon-left > .glyphicon {
  margin-right: .5em;
}

.btn-link {
  cursor: pointer;
  text-decoration: underline dotted;
}

.panel-body, .panel-footer {
  border-style: none solid solid;
  border-width: 1px;
}

.panel-default, .panel-default > .panel-footer, .panel-default > .panel-body {
  border-color: #ddd;
}

.panel-info, .panel-info > .panel-footer, .panel-info > .panel-body {
  border-color: #bce8f1;
}

.panel-footer-info {
  color: #31708f;
  background-color: #d9edf7;
}

label.checkbox-outer > input, label.radio-outer > input {
  margin-right: .6em;
}

.form-control-with-search-icon {
  position: relative;
}

.form-control-with-search-icon:before {
  text-align: center;
  color: #d2d6e0;
  content: "";
  width: 50px;
  height: 18px;
  font-family: Glyphicons Halflings;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: block;
  position: absolute;
  top: 17px;
  left: 4px;
}

.form-control-with-search-icon > .form-control {
  padding-left: 50px;
}

.form-control-with-search-icon.focus:before {
  color: #777;
}

.footer-brand {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.nav-stacked > li + li {
  margin-top: 0;
}

.nav-pills > li {
  background: none;
}

.nav-pills > li > span {
  border-radius: 1px;
  padding: 10px 15px;
  display: block;
  position: relative;
}

.nav-pills > li > a {
  color: #333;
  background: #fff;
  border-radius: 1px;
}

.nav-pills > li > a:focus, .nav-pills > li > a:hover, .nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  color: #a0c3ff;
  background: #fff;
}

.alert {
  border-radius: 1px;
}

.form-group > label {
  display: block;
}

.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}

.navbar-toggle {
  border: none;
  border-radius: 0;
}

.navbar-nav li.image-button {
  padding: 0;
}

.navbar-nav li.image-button img {
  max-width: 40px;
  height: 40px;
}

.navbar-nav li.image-button .navbar-toggle {
  margin: 0;
  padding: 0;
}

.pagination > li > a {
  color: #333;
  margin-right: 1em;
}

.pagination > li:first-child > a, .pagination > li:last-child > a {
  border-radius: 0;
}

.modal-header {
  color: #fff;
  background: #00a6e8;
}

.modal-header button.close {
  color: #fff;
  text-shadow: none;
  opacity: 1;
}

.modal-content {
  background: #fff;
  border: none;
}

.modal-title {
  text-align: center;
}

.modal-footer {
  border-top: none;
}

.modal-footer.modal-footer-text-center {
  text-align: center;
}

.modal-footer.modal-footer-modaller {
  padding-top: 0;
}

@media (width <= 767px) {
  .btn-icon-on-xs .btn-text {
    display: none;
  }

  .btn-icon-on-xs .glyphicon {
    margin-right: 0;
  }
}

.visible-xs-table-row {
  display: none !important;
}

@media (width <= 767px) {
  .visible-xs-table-row {
    display: table-row !important;
  }
}

.w-4em {
  width: 4em;
}

.popover--yellow {
  min-width: 25vw;
}

.popover--yellow.bs-popover-top .arrow:after {
  border-top-color: #fbf7c5;
}

.popover--yellow.bs-popover-right .arrow:after {
  border-right-color: #fbf7c5;
}

.popover--yellow.bs-popover-bottom .arrow:after {
  border-bottom-color: #fbf7c5;
}

.popover--yellow.bs-popover-left .arrow:after {
  border-left-color: #fbf7c5;
}

.popover--yellow .popover-body {
  background: #fbf7c5;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.modal.modal-popover {
  margin: 0 !important;
  padding: 0 !important;
}

.modal.modal-popover .modal-dialog {
  margin: 0;
  padding: 0;
}

.modal.modal-popover .modal-body {
  text-align: center;
  padding: 1rem 2rem;
}

html.index body {
  background: #fff;
}

html.index .navbar-static-top {
  margin-bottom: 0;
}

.index-inner-1 {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

@media (width >= 768px) {
  .index-inner-1 {
    background-image: none;
    padding-left: 0;
  }
}

@media (width >= 992px) {
  .index-inner-1 {
    background: #0087c9 url("index-lines.1d1f03e9.png") 0 0 / auto 100% no-repeat;
    padding-left: 18%;
  }
}

.index-inner-2 {
  background: #0087c9 url("index-man.ce5452aa.jpg") 100% 100% no-repeat;
  min-height: 470px;
  padding-left: 4em;
  padding-right: 30%;
}

@media (width <= 767px) {
  .index-inner-2 {
    background-image: url("index-lines.1d1f03e9.png");
    background-position: 0 0;
    min-height: 0;
    padding: 1em;
  }
}

.index-inner-bottom {
  background: #0073ab66;
  height: 70px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.index-text {
  padding-bottom: 80px;
  display: table;
}

.index-text h1 {
  color: #fafeff;
  margin-top: 1em;
  margin-bottom: .5em;
  font-size: 60px;
}

.index-text p {
  color: #fafeff;
  margin-bottom: 1em;
  font-size: 24px;
}

@media (width <= 767px) {
  .index-text {
    text-align: center;
  }

  .index-text h1 {
    font-size: 31px;
  }

  .index-text p {
    text-align: justify;
    margin: 2em 1em;
    font-size: 16px;
  }
}

.index-list {
  background: #fff url("index-notes2.347fd956.jpg") top no-repeat;
  min-height: 300px;
}

.index-list .pack-card-small {
  margin-bottom: .9em;
}

.index-list-title {
  color: #8a8a8a;
  margin-top: 220px;
  margin-bottom: 1em;
  font-size: 50px;
}

@media (width <= 767px) {
  .index-list-title {
    margin-top: 120px;
    font-size: 24px;
  }
}

html.nav-append body {
  padding-top: 50px;
}

html.nav-append .navbar-default {
  border-bottom: none;
}

html.nav-append body > .nav-append {
  background-color: #f8f8f8;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 10px;
}

html.nav-append body > .nav-append ol.breadcrumb {
  margin-bottom: 0;
  padding-top: 0;
  padding-left: 0;
}

.index-welcome {
  text-align: center;
  background: #fff;
  border-top: 10px solid #000;
  padding-bottom: 50px;
}

.index-welcome h2 {
  color: #8a8a8a;
  margin: 1.5em 0;
  font-size: 36px;
}

@media (width <= 767px) {
  .index-welcome h2 {
    font-size: 24px;
  }
}

.index-welcome-wid {
  text-align: center;
  color: #4b4b4b;
}

.index-welcome-wid img {
  width: 45%;
  max-width: 128px;
}

.index-welcome-wid h3 {
  color: #3e3e3e;
  margin: 1em 0 .3em;
  font-size: 20px;
}

.index-welcome-wid p {
  padding: 0 5%;
}

@media (width <= 767px) {
  .index-welcome-wid img {
    float: left;
    width: 40px;
  }

  .index-welcome-wid h3 {
    margin-top: 8px;
    margin-bottom: 8px;
    line-height: 24px;
    display: inline-block;
  }

  .index-welcome-wid p {
    text-align: justify;
    margin-top: 1em;
    margin-bottom: 3em;
  }
}

.index-welcome-footer {
  background: #28a05c url("index-footer.07709f17.svg") 100% 100% no-repeat;
  padding: 60px 0;
}

.index-welcome-footer h2 {
  color: #fff;
  font-size: 30px;
  line-height: 1;
}

.index-welcome-footer .btn {
  margin-top: 4px;
}

@media (width <= 767px) {
  .index-welcome-footer h2 {
    text-align: center;
    margin-bottom: 2em;
    font-size: 24px;
  }

  .index-welcome-footer .btn {
    text-align: center;
  }
}

html.sticky-f {
  min-height: 100%;
  position: relative;
}

html.sticky-f body {
  margin-bottom: 140px;
  padding-bottom: 5em;
}

html.sticky-f body .footer {
  width: 100%;
  height: 140px;
  position: absolute;
  bottom: 0;
}

html.fl-fullscreenplay {
  width: 100%;
  height: 100%;
}

body.fl-fullscreenplay {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  min-height: auto !important;
}

body.fl-fullscreenplay > * {
  width: 100%;
}

body.fl-fullscreenplay > .cc-revoke {
  display: none !important;
}

.fl-fullscreenplay--main {
  flex: 1;
  min-height: 0;
  position: relative;
}

.fl-fp-head {
  background: #00a1ff;
  width: 100%;
  margin-bottom: .5rem;
  padding: .5rem;
}

.fl-fp-head:before, .fl-fp-head:after {
  content: " ";
  display: table;
}

.fl-fp-head:after {
  clear: both;
}

.fl-fp-head .btn {
  color: #fff;
  opacity: .85;
  background-color: #00acff;
  border-color: #00acff;
}

.fl-fp-head .btn:hover, .fl-fp-head .btn:focus, .fl-fp-head .btn:active {
  opacity: 1;
}

.fl-fp-head__title {
  text-overflow: ellipsis;
  color: #fff;
  white-space: nowrap;
  margin: 0;
  padding: 0 .5rem;
  font-size: 1rem;
  line-height: 2.4;
  display: block;
  overflow: hidden;
}

.fl-progress-bar {
  width: 100%;
}

.fl-progress-bar__title {
  color: #fff;
  flex-flow: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-size: .9rem;
  line-height: 1.5;
  display: flex;
}

.fl-progress-bar__title-text {
  text-align: left;
}

.fl-progress-bar__title-text-label {
  opacity: .7;
  margin-right: .7rem;
}

.fl-progress-bar__title-text-val {
  opacity: 1;
  white-space: nowrap;
}

.fl-progress-bar__bar {
  background: #35b4ff;
  border-radius: .25rem;
  width: 100%;
  height: 1rem;
  position: relative;
}

.fl-progress-bar__thumb {
  background: #c8e4fc;
  border-radius: .25rem;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.card-inst {
  vertical-align: middle;
  border: 1px solid #4f6577;
  border-radius: .5em;
  width: 500px;
  height: 250px;
  display: inline-block;
  position: relative;
  box-shadow: 1px 1px 5px #bbb;
}

.card-inst.card-green {
  background-color: #fffffc;
  border-color: #b6d588;
}

.card-inst.card-blue {
  background-color: #fafeff;
  border-color: #5bc9dd;
}

.card-inst.card-orange {
  background-color: #fafeff;
  border-color: #ddc059;
}

.card-def {
  width: 100%;
  height: 100%;
  padding-top: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
}

.card-def .card-def-content {
  text-align: center;
  max-height: 100%;
  margin: 0;
  padding: 0;
  font-size: 35px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

table.table-of-def {
  width: 100%;
}

table.table-of-def tr > th, table.table-of-def tr > td {
  border: none;
}

table.table-of-def td {
  padding: .3em 1em;
}

.pack-card {
  background: #fcfcfc;
  border-radius: 1px;
  margin-bottom: .9em;
  padding: 5px 15px;
  font-size: 14px;
  box-shadow: -1px 0 1px #ddd;
}

.pack-card > h2 {
  margin-top: 0;
  font-size: 20px;
}

.pack-card > h2 > a {
  color: #3a3a3a;
  text-decoration: none;
}

.pack-card .pack-card-attrib {
  margin-right: 1em;
  font-size: 90%;
}

.pack-card .pack-card-attrib > .pack-card-attrib-label {
  color: #666;
  font-style: italic;
}

.pack-card-small {
  color: #555656;
  background: #fcfcfc;
  border-radius: 1px;
  padding: 5px 15px;
  font-size: 14px;
  box-shadow: -1px 0 1px #ddd;
}

.pack-card-small > h2 {
  opacity: .9;
  margin: 0;
  font-size: 1.3rem;
  line-height: 1.7;
}

.pack-card-small > h2 > a {
  color: #3a3a3a;
  text-decoration: none;
}

.pack-meta-buttons {
  margin-bottom: 0;
}

.play-progress {
  line-height: 1.5;
}

.play-progress:before, .play-progress:after {
  content: " ";
  display: table;
}

.play-progress:after {
  clear: both;
}

.play-progress-label {
  float: left;
  padding-right: .5em;
  display: block;
}

.play-progress-value {
  display: block;
  position: relative;
  overflow: hidden;
}

.play-header-progress {
  overflow: auto;
}

.pbar-table {
  width: 100%;
}

.pbar-table .w1p {
  text-align: right;
  width: 1%;
  padding: .3em .7em;
}

.pbar-table .w1p:first-child {
  padding-left: 0;
}

.pbar-table .w1p:last-child {
  padding-right: 0;
}

.pbar-table tr:first-child .w1p {
  padding-top: 0;
}

.card-card-qa {
  line-height: 1.2;
}

.card-card-qa .card-face-text {
  color: #111;
  opacity: .8;
  margin-bottom: .5em;
  padding: 0;
  display: block;
}

.card-card-qa .card-back-text {
  color: #9ea3a4;
}

.card-card-qa.limit-text .card-face-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.card-card-qa.limit-text .card-back-text {
  color: #9ea3a4;
  max-height: 3.6em;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.card-card-qa.limit-text .card-back-text:before {
  content: " ";
  width: 100%;
  height: 1.2em;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00ffffff", endColorstr= "#ffffff", GradientType= 0);
  background: linear-gradient(#fff0 0%, #fff 100%);
  display: block;
  position: absolute;
  top: 2.4em;
}

.course-card-small {
  color: #555656;
  background: #fcfcfc;
  border-radius: 1px;
  margin-bottom: .9em;
  padding: 5px 15px;
  font-size: 14px;
}

.course-card-small > h2 {
  margin-top: 0;
  font-size: 20px;
}

.course-card-small > h2 > a {
  color: #3a3a3a;
  text-decoration: none;
}

.move-pack-to-course-header h1 {
  margin-top: 0;
}

.move-pack-to-course-packs > span {
  color: #989898;
  font-size: 14px;
}

.move-pack-to-course-form ul > li.adder {
  padding-left: 26px;
}

.move-pack-to-course-form ul > li.adder span {
  display: block;
}

.move-pack-to-course-form .empty-courses-message {
  display: none;
}

.move-pack-to-course-form .empty-courses-message > p {
  color: #c5c5c5;
  text-align: center;
  padding: 40px 15%;
  font-size: 24px;
}

.move-pack-to-course-form.no-courses .empty-courses-message {
  display: block;
}

.move-pack-to-course-form.no-courses .move-pack-to-course-form-content {
  display: none;
}

.move-pack-to-course-form h3 {
  margin-top: 2em;
}

.memtrick-pack-list {
  padding-bottom: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.memtrick-pack-list__item {
  border: 1px solid #e1e1e1;
  margin-bottom: .5rem;
  display: block;
  text-decoration: none !important;
}

.pack-and-some-cards-list .pack-and-some-cards {
  border-bottom: 1px solid #f1f1f1;
  padding-top: .4em;
  padding-bottom: .4em;
  overflow: hidden;
}

.pack-and-some-cards-list .col-bordered {
  position: relative;
}

.pack-and-some-cards-list .col-bordered:before {
  content: " ";
  border-left: 1px solid #f1f1f1;
  width: 0;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 767px) {
  .pack-and-some-cards-list .pack-and-some-cards .some-cards {
    display: none;
  }
}

.pack-and-some-cards {
  background: #fff;
  margin-left: 0;
  margin-right: 0;
}

.pack-and-some-cards > div:first-child {
  padding-left: 0;
}

.pack-and-some-cards > div:last-child {
  padding-right: 0;
}

.pack-and-some-cards .pack-card-small {
  box-shadow: none;
}

.pack-and-some-cards .card-card-qa {
  padding: 9px 15px;
  font-size: 90%;
  position: relative;
}

.course-header > h1 {
  margin-top: 0;
}

.course-title-panel {
  padding-left: 15px;
  padding-right: 15px;
}

.course-title-panel h1 {
  margin-top: 0;
}

.user-collections-select-list {
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.user-collections-select-list > li {
  margin: .2rem;
  display: block;
}

.user-collections-select-list__item .btn {
  color: #007cff;
  border-color: #0788ff;
  padding: .2rem .5rem;
}

.user-collections-select-list__item .btn.btn-pressed {
  color: #fff;
  background-color: #007cff;
}

.user-collections-select-list__new .btn {
  color: #007cff;
  background: #ffefbf;
  border-color: #0788ff;
  padding: .2rem .5rem;
}

@media (width >= 992px) {
  .pack-header__title > h1 {
    word-break: normal;
  }

  .pack-header__buttons {
    white-space: nowrap;
  }

  .pack-games-buttons .btn {
    min-width: 150px;
    font-size: 150%;
  }
}

.pack-play-controls {
  opacity: .7;
  flex-flow: row;
  margin-top: 1rem;
  padding: .5rem;
  display: flex;
}

.pack-play-controls__left {
  text-align: left;
  white-space: nowrap;
  flex: 1;
}

.pack-play-controls__center {
  text-align: center;
  white-space: nowrap;
  flex: 1;
}

.pack-play-controls__center .btn {
  padding: .3rem;
  font-size: 0;
}

.pack-play-controls__center .btn > span {
  font-size: 1.8rem;
  display: inline-block;
}

.pack-play-controls__right {
  text-align: right;
  white-space: nowrap;
  flex: 1;
}

.pack-play-controls__right .btn {
  font-size: 1.2rem;
  line-height: 1.7rem;
}

.play-header {
  background: #fff;
  margin-bottom: 1rem;
  padding: .5rem;
  display: block;
}

.play-header__left {
  white-space: nowrap;
  text-align: center;
}

.play-header__left .btn {
  margin-bottom: .5rem;
  margin-left: .5rem;
}

.play-header__left .btn:first-child {
  margin-left: 0;
}

.play-card-stack--container {
  position: relative;
}

.play-card-stack {
  position: absolute;
  inset: 0;
  overflow: visible;
}

.play-card-stack .animated {
  z-index: 1000;
}

.play-card-stack__card {
  width: 100%;
  height: 100%;
  animation-duration: .5s !important;
}

.play-card-stack__card-side {
  background: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 1px 1px 5px #3333334d;
}

.play-card-stack__card-side.play-card-stack__card-side--pointer {
  cursor: pointer;
}

.play-card-stack__card-side--one-button > button {
  width: 100%;
  height: 100%;
  font-size: 10em;
  display: block;
}

.play-card-stack__card-text {
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  display: flex;
  position: absolute;
  inset: 0;
}

.play-card-stack__card-text > div {
  word-break: break-word;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  font-size: 2.5rem;
}

.play-card-stack__card-image {
  padding: 1rem;
  position: absolute;
  inset: 0;
}

.play-card-stack__card-side.play-card-stack__card-side--image-and-text .play-card-stack__card-image {
  padding-bottom: 0;
  bottom: 50%;
}

.play-card-stack__card-side.play-card-stack__card-side--image-and-text .play-card-stack__card-text {
  top: 50%;
}

.play-card-stack.play-card-stack--speakable button.play-card-stack__speak {
  color: #6b6b6b;
  background: none;
  border: none;
  padding: .5rem;
  font-size: 1.5rem;
  line-height: 1;
  position: absolute;
  right: 0;
  display: inline-block !important;
}

.play-card-stack.play-card-stack--both-faces .play-card-stack__card {
  position: relative;
}

.play-card-stack.play-card-stack--both-faces .play-card-stack__card-side {
  position: absolute;
  left: 0;
  right: 0;
}

.play-card-stack.play-card-stack--both-faces .play-card-stack__card-face {
  height: 50%;
  top: 0;
}

.play-card-stack.play-card-stack--both-faces .play-card-stack__card-back {
  height: 50%;
  bottom: 0;
}

@media (width <= 991px) and (orientation: landscape) {
  .play-card-stack__card-side.play-card-stack__card-side--image-and-text .play-card-stack__card-image {
    padding: 1rem 0 1rem 1rem;
    bottom: 0;
    right: 60%;
  }

  .play-card-stack__card-side.play-card-stack__card-side--image-and-text .play-card-stack__card-text {
    top: 0;
    left: 40%;
  }

  .play-card-stack.play-card-stack--both-faces .play-card-stack__card-side.play-card-stack__card-side--image-and-text .play-card-stack__card-image {
    padding: 1rem 0 1rem 1rem;
    bottom: 0;
    right: 70%;
  }

  .play-card-stack.play-card-stack--both-faces .play-card-stack__card-side.play-card-stack__card-side--image-and-text .play-card-stack__card-text {
    top: 0;
    left: 30%;
  }
}

@media (width >= 992px) {
  .play-card-stack--container {
    max-width: 42rem !important;
    max-height: 24rem !important;
  }

  .pack-play-controls {
    margin-left: auto;
    margin-right: auto;
    max-width: 42rem !important;
  }

  .play-card-stack__card-text {
    font-size: 3rem;
  }

  .play-card-stack__card-side.play-card-stack__card-side--image-and-text .play-card-stack__card-image {
    padding: 1rem 0 1rem 1rem;
    bottom: 0;
    right: 60%;
  }

  .play-card-stack__card-side.play-card-stack__card-side--image-and-text .play-card-stack__card-text {
    top: 0;
    left: 40%;
  }

  .play-card-stack.play-card-stack--both-faces .play-card-stack__card-side.play-card-stack__card-side--image-and-text .play-card-stack__card-image {
    padding: 1rem 0 1rem 1rem;
    bottom: 0;
    right: 70%;
  }

  .play-card-stack.play-card-stack--both-faces .play-card-stack__card-side.play-card-stack__card-side--image-and-text .play-card-stack__card-text {
    top: 0;
    left: 30%;
  }
}

input.fl-form-control {
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  padding-bottom: .1rem;
  padding-left: 0;
  padding-right: 0;
  line-height: 1.2;
}

input.fl-form-control:focus {
  box-shadow: none;
}

input.fl-form-control::placeholder {
  opacity: .6;
}

.fl-learn-tune-form {
  border-bottom: 1px solid #ddd;
  flex-flow: wrap;
  padding: 1rem 0;
  display: flex;
}

.fl-learn-tune-form:last-child {
  border-bottom: none;
}

.fl-learn-tune-form__label {
  flex: 40%;
  max-width: 40%;
  margin-bottom: 0;
}

.fl-learn-tune-form__content {
  flex: 60%;
}

.fl-learn-tune-form__content .btn-sm {
  margin-top: -.2rem;
}

.fl-learn-tune-form__content .custom-control-label {
  cursor: pointer;
}

.fl-learn-tune-form__error {
  text-align: center;
  color: #f89a90;
  flex: 100%;
}

.fl-learn-tune-form__notice {
  text-align: left;
  opacity: .7;
  flex: 100%;
  font-size: 80%;
}

.fl-learn-progress {
  color: #fff;
  white-space: nowrap;
  flex-flow: row;
  justify-content: space-around;
  display: flex;
}

.fl-learn-progress__item {
  text-align: center;
  font-size: 1rem;
}

.fl-learn-progress__label {
  font-size: .9rem;
}

.fl-learn-progress__label_ring {
  border: 1px solid #fff;
  border-radius: 100%;
  width: .5rem;
  height: .5rem;
  display: inline-block;
}

.fl-learn-progress__label_ring--green {
  background: #b7e5a1;
}

.fl-learn-progress__label {
  opacity: .8;
  line-height: 1.5;
}

.fl-learn-progress__delimiter {
  opacity: .8;
  margin-left: 1rem;
  margin-right: 1rem;
  line-height: 1.3;
  display: block;
}

.fl-learn-progress__value {
  text-align: center;
  flex: 1;
  font-size: 1.7rem;
  line-height: 2rem;
}

.fl-learn-card__answers-answer-text > div, .fl-learn-card-wrong-display__value-answer > div {
  margin: 0;
}

@media (width <= 991px) and (orientation: landscape) {
  .fl-learn-card__question {
    min-height: auto;
  }

  .fl-learn-card__answers {
    flex-direction: row;
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-image {
    width: auto;
    height: 30%;
    padding: .25rem;
    inset: auto 0 0;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-image img {
    vertical-align: top;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-text {
    width: auto;
    height: 70%;
    padding: .25rem .25rem 0;
    position: absolute;
    inset: 0 0 auto;
  }
}

@media (orientation: landscape) {
  .fl-learn-card__write {
    display: flex;
  }

  .fl-learn-card__write-input {
    flex: 100;
    margin-right: 1rem;
  }

  .fl-learn-card__write-buttons {
    white-space: nowrap;
    flex: 1;
  }

  .fl-learn-card__answers > li {
    max-width: 25%;
  }

  .fl-learn-card__answers.fl-learn-card__answers--2lines {
    flex-wrap: wrap;
  }

  .fl-learn-card__answers.fl-learn-card__answers--2lines > li {
    flex: 25%;
    max-height: 50%;
  }
}

@media (width >= 992px) {
  .fl-learn-card__question {
    width: 42rem;
    height: 24rem;
    min-height: auto;
    margin: 0 auto;
  }

  .fl-learn-card__answers {
    flex-direction: row;
    flex: 1;
    max-height: 15rem;
    padding: 1rem 1rem 0;
    position: static;
  }

  .fl-learn-card__answers > li {
    max-width: 25%;
    max-height: 100%;
  }

  .fl-learn-card__answers-answer-shortcut {
    display: block;
  }

  .fl-learn-card__answers-answer.btn {
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: 1rem;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-image {
    width: auto;
    height: 40%;
    padding: 1rem / 2 1rem 1rem;
    inset: auto 0 0;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-image img {
    vertical-align: top;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-text {
    width: auto;
    height: 60%;
    padding: 1.2rem 1rem 0;
    position: absolute;
    inset: 0 0 auto;
  }

  .fl-learn-card__write {
    padding: 1rem 1rem 0;
  }

  .fl-learn-card__write-input, .fl-learn-card__write-buttons {
    margin-bottom: 1rem;
  }

  .fl-learn-card__write {
    width: 42rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.fl-learn-card__result-panel {
  margin-top: .5rem;
  margin-left: .5rem;
  margin-right: .5rem;
}

.fl-learn-card__result-panel p {
  margin-bottom: .5rem;
}

.fl-write-card__answer-notice {
  color: #959090;
  margin-top: .5em;
  margin-bottom: .5em;
  display: block;
}

.fl-write-card__highlight-correct {
  color: #16a765;
}

.fl-write-card__highlight-wrong {
  color: #db6e60;
}

.fl-write-card__buttons {
  flex-flow: row;
  justify-content: flex-end;
  padding-top: 1em;
  display: flex;
}

@media (width >= 992px) {
  .fl-learn-card__result-panel {
    width: 42rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.fl-memo-controls {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.fl-memo-controls .btn {
  background: #fff;
  border: 1px solid #ddd;
}

@media (width <= 767px) {
  .fl-memo-controls {
    flex-direction: row;
    justify-content: space-evenly;
    display: flex;
  }
}

.fl-memo-startup {
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
}

.fl-memo-startup a {
  color: #007cff;
}

.fl-memo-startup p {
  margin: 0;
}

.fl-memo-startup__title {
  color: #007cff;
  margin: 0;
  font-size: 1.9em;
}

.fl-memo-startup__text--smaller {
  font-size: 95%;
}

.fl-memo-startup__text--fade {
  color: #9b9c9e;
}

.fl-memo-help {
  padding-left: 1rem;
  position: absolute;
  top: 5rem;
  width: auto !important;
}

.fl-memo-help .btn {
  opacity: .6;
  padding-left: 0;
  padding-right: 0;
  font-size: 110%;
}

.fl-memo-help .btn:hover, .fl-memo-help .btn:focus, .fl-memo-help .btn:active {
  opacity: 1;
}

.fl-memo-help__line {
  opacity: .6;
  line-height: 1.6;
}

.fl-memo-help__title {
  opacity: .5;
  font-size: 170%;
  line-height: 2;
}

@media (width <= 767px) {
  .fl-memo-help {
    display: none;
  }
}

.fl-pack-games-buttons {
  flex-flow: row;
  margin-bottom: 1em;
  margin-left: -.25rem;
  margin-right: -.25rem;
  display: flex;
}

.fl-pack-games-buttons .btn {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #fff;
  border: 1px solid #cccccc80;
  width: 100%;
  margin-bottom: .25rem;
  position: relative;
  overflow: hidden;
}

.fl-pack-games-buttons__group {
  flex: 0 100%;
  width: 100%;
  padding: 0 .25rem;
}

.fl-pack-games-buttons__filler {
  display: none;
}

.fl-pack-games-buttons__text {
  opacity: .7;
  line-height: 2;
}

@media (width >= 992px) {
  .fl-pack-games-buttons {
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
  }

  .fl-pack-games-buttons .btn:last-child {
    margin-bottom: 0;
  }

  .fl-pack-games-buttons__group {
    flex: 0;
    width: auto;
  }

  .fl-pack-games-buttons__filler {
    display: initial;
    flex: 1;
  }

  .fl-pack-games-buttons__icon {
    margin-top: .4em;
    display: inline-block;
  }

  .fl-pack-games-buttons__text {
    float: right;
  }
}

.fl-pack-header {
  margin: -1rem -1rem auto;
  padding: 1em;
  position: relative;
}

.fl-pack-header__meta {
  flex-flow: wrap;
  justify-content: space-between;
  margin-bottom: .5rem;
  display: flex;
}

.fl-pack-header__meta-author {
  white-space: nowrap;
  display: inline;
}

.fl-pack-header__meta-author .memtrick-person-link {
  color: #007cff;
}

.fl-pack-header__title {
  word-break: break-word;
  margin: 0 3rem 0 0;
  font-size: 1.5rem;
}

.fl-pack-header__description {
  opacity: .7;
  margin: 0;
  font-size: 90%;
}

.fl-pack-header__actions-toggle {
  display: block;
  position: absolute;
  top: 3rem;
  right: 0;
}

.fl-pack-header__actions-toggle .fa-chevron-up {
  display: none;
}

.fl-pack-header__actions {
  flex-wrap: wrap;
  margin-top: .5rem;
  display: none;
}

.fl-pack-header__actions > .btn {
  border-radius: 1em;
  margin: .25em;
  box-shadow: 0 0 .2rem #6e6e6e80;
}

.fl-pack-header__actions > .btn:focus {
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.fl-pack-header__actions > .dropdown {
  display: inline-block;
}

.fl-pack-header__actions > .dropdown > .btn {
  border-radius: 1em;
  margin: .25em;
  box-shadow: 0 0 .2rem #6e6e6e80;
}

.fl-pack-header__actions > .dropdown > .btn:focus {
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.fl-pack-header--actions-open .fl-pack-header__actions-toggle .fa-chevron-up {
  display: inherit !important;
}

.fl-pack-header--actions-open .fl-pack-header__actions-toggle .fa-chevron-down {
  display: none;
}

.fl-pack-header--actions-open .fl-pack-header__actions {
  display: flex;
}

.fl-pack-header__thumb {
  max-width: 120px;
  max-height: 120px;
  margin: 0 auto 1rem;
  display: block;
}

.fl-pack-header__thumb img {
  max-width: 100%;
  max-height: 100%;
}

@media (width >= 768px) {
  .fl-pack-header {
    margin-left: 0;
    margin-right: 0;
  }

  .fl-pack-header__thumb {
    float: left;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 1rem;
  }
}

@media (width >= 992px) {
  .fl-pack-header__actions-toggle {
    display: none;
  }

  .fl-pack-header {
    flex-flow: wrap;
    padding: 1rem 1.5rem;
    display: flex;
  }

  .fl-pack-header__title {
    font-size: 2rem;
  }

  .fl-pack-header__meta {
    width: 100%;
  }

  .fl-pack-header__text, .fl-pack-header__actions {
    display: initial;
    flex: 1;
  }

  .fl-pack-header__actions {
    text-align: end;
    margin-top: 0;
  }

  .fl-pack-main-cols {
    flex-flow: row;
    min-height: auto;
    margin-bottom: 1.5rem;
    margin-left: -.3rem;
    margin-right: -.3rem;
    display: flex;
  }

  .fl-pack-main-cols > div {
    padding-left: .3rem;
    padding-right: .3rem;
  }

  .fl-pack-main-cols__widgets {
    flex: 1;
  }

  .fl-pack-main-cols__embed {
    flex: 1.5;
  }

  .fl-pack-main-cols__modes {
    flex: .5;
  }
}

.fl-pack-embed {
  position: relative;
  box-shadow: 0 0 7px #555;
}

.fl-pack-embed__field {
  cursor: pointer;
  background: #fff;
  padding: 0 3rem;
}

.fl-pack-embed__notice {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background: #2f4247;
  padding: .5rem;
  font-size: 80%;
  font-weight: bold;
}

.fl-pack-embed__next, .fl-pack-embed__prev {
  opacity: .4;
  height: 100%;
  font-size: 200%;
  position: absolute;
  top: 0;
}

.fl-pack-embed__next:focus, .fl-pack-embed__prev:focus {
  box-shadow: none;
}

.fl-pack-embed__prev {
  padding-right: 0;
  left: 0;
}

.fl-pack-embed__next {
  padding-left: 0;
  right: 0;
}

.fl-pack-embed__current-index {
  padding: 0 1rem;
}

.fl-pack-embed__card {
  min-height: 16.7rem;
  position: relative;
}

.fl-pack-embed__card-face, .fl-pack-embed__card-back {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.fl-pack-embed__card-side--image-and-text .fl-pack-embed__card-image, .fl-pack-embed__card-side--image-and-text .fl-pack-embed__card-text {
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
}

.fl-pack-embed__card-side--image-and-text .fl-pack-embed__card-image {
  flex-basis: 40%;
  padding-right: 0;
}

.fl-pack-embed__card-side--image-and-text .fl-pack-embed__card-text {
  text-align: left;
  flex-basis: 60%;
}

.fl-pack-embed__card-image {
  text-align: center;
  height: 100%;
  padding: 1rem;
}

.fl-pack-embed__card-text {
  text-align: center;
  max-width: 100%;
  max-height: 100%;
  padding: 0 1rem;
  font-size: 3rem;
}

.fl-pack-embed__card-text > div {
  -webkit-user-select: none;
  user-select: none;
  max-width: 100%;
  margin: 0;
  display: block;
}

@media (width >= 992px) {
  .fl-pack-embed {
    box-shadow: none;
  }

  .fl-pack-embed__field {
    min-height: 16.7rem;
  }

  .fl-pack-embed__card-image {
    max-width: 50%;
    height: 100%;
  }
}

@media (orientation: portrait) {
  .fl-pack-embed__card-side--image-and-text {
    flex-direction: column;
  }

  .fl-pack-embed__card-side--image-and-text .fl-pack-embed__card-image {
    width: 100%;
    padding-right: 0;
  }

  .fl-pack-embed__card-side--image-and-text .fl-pack-embed__card-text {
    text-align: center;
    width: 100%;
  }
}

.fl-solw {
  padding: 1rem;
  position: relative;
}

.fl-solw__help {
  position: absolute;
  top: 0;
  right: 0;
}

.fl-solw__help .btn {
  color: #ddd;
  font-size: 1.2rem;
}

.fl-solw__title {
  color: #555;
  margin-right: 1rem;
  font-size: 120%;
}

.fl-solw__title-icon {
  vertical-align: middle;
  margin-right: .25em;
  font-size: 150%;
}

.fl-solw__title-small {
  opacity: .6;
  margin-left: 3em;
  font-size: 70%;
  line-height: 1;
  display: block;
}

.fl-solw__play {
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: 200%;
}

.fl-solw__numbers {
  flex-flow: wrap;
  min-width: 1rem;
  margin-top: 1rem;
  display: flex;
}

.fl-solw__numbers-line {
  white-space: nowrap;
  flex: 100%;
}

.fl-solw__numbers-item {
  white-space: nowrap;
  margin-right: 1rem;
}

.fl-solw__numbers-label {
  opacity: .5;
}

.fl-solw__numbers-value--green {
  color: #72cd4e;
}

.fl-solw__numbers-value--blue {
  color: #007bff;
}

@media (width >= 992px) {
  .fl-solw {
    min-height: 8rem;
  }
}

.fl-pack-plist__controls {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: .5rem;
  display: flex;
}

.fl-pack-plist__controls .btn {
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #fff;
  border: 1px solid #cccccc80;
  overflow: hidden;
}

.fl-pack-plist__list .face, .fl-pack-plist__list .back, .fl-pack-plist__list .card-action {
  padding: 1em;
}

.fl-pack-plist__list .back {
  border-left: 1px solid #ccc;
}

.fl-pack-plist__flags {
  text-align: end;
  padding: 0 .2rem;
  font-size: 0;
}

.fl-pack-plist__flags > span, .fl-pack-plist__flags button {
  opacity: .7;
  vertical-align: baseline;
  padding: .7rem .4rem;
  font-size: .9rem;
  line-height: 1;
}

.fl-pack-plist__flags > span {
  padding-left: .3rem;
  padding-right: .3rem;
}

.fl-pack-plist__flags .fa.fa-star {
  color: #ffcd4d;
}

.fl-pack-plist__flags-line {
  white-space: nowrap;
  display: inline-block;
}

.fl-pack-plist__item {
  margin-bottom: .5em;
}

.fl-pack-plist__empty-item {
  text-align: center;
  opacity: .8;
  padding: 2em 1em;
}

.fl-pack-plist__face, .fl-pack-plist__back {
  word-break: break-word;
  flex: .5;
  padding: 0 1em 1em;
}

.fl-pack-plist__image {
  float: left;
  margin-right: 1rem;
}

.fl-pack-plist__image > img {
  max-width: 5rem;
  max-height: 5rem;
}

@media (width >= 992px) {
  .fl-pack-plist__item {
    flex-flow: wrap;
    display: flex;
  }

  .fl-pack-plist__flags {
    flex: .2;
    order: 100;
  }

  .fl-pack-plist__back {
    border-left: 1px solid #d4d4d4;
  }

  .fl-pack-plist__face, .fl-pack-plist__back {
    padding: 1em;
  }

  .fl-pack-plist__flags {
    flex-direction: column;
    align-items: flex-end;
    display: flex;
  }

  .fl-pack-plist__flags > span {
    order: 100;
    padding-top: .5em;
    padding-bottom: .5rem;
    display: block;
  }

  .fl-pack-plist__flags button {
    padding: .8em;
  }

  .fl-pack-plist__flags-line {
    display: block;
  }
}

.person-name-wid {
  background: #a1c3ff;
  border-radius: 1px;
  position: relative;
}

.person-name-wid:before, .person-name-wid:after {
  content: " ";
  display: table;
}

.person-name-wid:after {
  clear: both;
}

.person-name-wid > img {
  border-bottom-left-radius: 1px;
  width: 90px;
  height: auto;
  max-height: 90px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.person-name-wid > div {
  text-align: right;
  height: 90px;
  margin-left: 100px;
  padding: 5px 10px;
  display: block;
  overflow: hidden;
}

.person-name-wid .person-cog {
  text-align: right;
  font-size: 150%;
  display: block;
}

.person-name-wid .person-cog a {
  color: #d4e4ff;
}

.person-name-wid .ba-exp {
  vertical-align: bottom;
  width: 0;
  height: 50px;
  display: inline-block;
}

.person-name-wid .display-name {
  color: #fff;
  text-shadow: 1px 0 #eee;
  font-size: 150%;
  font-weight: bold;
}

@media (width <= 767px) {
  .person-name-wid > div {
    height: 70px;
  }

  .person-name-wid .ba-exp {
    height: 30px;
  }

  .person-name-wid > img {
    width: 70px;
    max-height: 70px;
  }
}

.my-page-list {
  background: #fff;
  padding: 10px 30px;
  overflow: auto;
}

.my-page-list > h1 {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 24px;
}

.my-page-list > div, .my-page-list > ul {
  padding-left: 0;
}

.settings-form-picture {
  width: 150px;
  height: 150px;
  display: inline-block;
  position: relative;
}

.settings-form-picture > img {
  object-fit: contain;
  background: #000;
  width: 100%;
  height: 100%;
}

.settings-form-picture > button {
  opacity: .8;
  border-radius: 0;
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.settings-form-picture > button:hover {
  opacity: 1;
}

.settings-form-picture > button.btn-in-progress {
  background-color: #e6e6e6;
  border-color: #adadad;
}

.memtrick-list-title {
  margin: 0;
  padding: 1rem;
  font-size: 1rem;
}

.search-head {
  padding-top: 1em;
  padding-bottom: 1em;
}

.search-table-packs li {
  background: #fff;
  margin-bottom: 1em;
}

.search-table-packs .card-card-qa {
  padding: .5em;
  font-size: 90%;
  position: relative;
}

.search-table-packs .pack-and-some-cards {
  border-bottom: none;
}

.select-test-variants {
  text-align: center;
  margin-top: 1em;
}

.select-test-variants .btn {
  margin-top: .5em;
  margin-bottom: .5em;
}

.select-test-variants .btn.active {
  background: #0062cc1a;
}

.select-test-submit {
  background: #fff;
  margin-top: 1em;
  padding: 2%;
  font-size: 120%;
}

.select-test-opt {
  text-align: center;
  margin-bottom: 1em;
}

.select-test-opt-label {
  color: #b1b1b1;
}

.test-pack-result {
  background: #fff;
  margin-bottom: 2em;
  padding: 2%;
}

.test-pack-result .result-message, .test-pack-result .result-percent {
  font-size: 2em;
}

.test-pack-result .result-message {
  color: #7e7e7e;
}

.test-pack-result .test-pack-result-face {
  margin-bottom: .8em;
}

@media (width <= 767px) {
  .test-pack-result .test-pack-result-face {
    text-align: center;
  }
}

.test-pack-result .result-face {
  vertical-align: top;
  width: 3em;
  height: 3em;
  margin-right: .5em;
  display: inline-block;
}

.test-pack-match .match-question {
  margin-bottom: 2em;
}

.test-pack-match .match-question select {
  margin-top: -6px;
  margin-right: .5em;
}

.test-pack-match .match-question.wrong, .test-pack-match .match-question.wrong + li {
  border-left: 2px solid #db6e60;
}

.test-pack-match .match-question.correct, .test-pack-match .match-question.correct + li {
  border-left: 2px solid #34ae72;
}

.test-pack-match .match-answer {
  margin-bottom: 2em;
}

.test-pack-match .match-answer.used {
  opacity: .5;
}

.test-pack-match .answer-correct-title {
  color: #989898;
}

.test-pack-match .final-answer {
  position: relative;
  top: -2.5em;
}

.test-pack-match .final-answer .expected {
  color: #08c;
}

.test-pack-match .final-answer .correct {
  color: #34ae72;
}

.test-pack-match .final-answer .wrong {
  color: #db6e60;
}

.test-pack-match .test-pack-match-table {
  background: #fff;
  padding: 2%;
}

.test-pack-check-table {
  background: #fff;
  margin-bottom: 2em;
  padding: 2%;
}

.test-pack-check-table li {
  margin-bottom: 2em;
}

.test-pack-check-table li.wrong, .test-pack-check-table li.correct {
  margin-bottom: 0;
}

.test-pack-check-table li.wrong .variants, .test-pack-check-table li.correct .variants {
  display: none;
}

.test-pack-check-table li.wrong, .test-pack-check-table li.wrong + li {
  border-left: 2px solid #db6e60;
}

.test-pack-check-table li.correct, .test-pack-check-table li.correct + li {
  border-left: 2px solid #34ae72;
}

.test-pack-check-table li.wrong-variant .variant {
  text-decoration: line-through;
}

.test-pack-check-table .variant-prefix {
  color: #989898;
}

.test-pack-check-table .final-answer .expected-title {
  color: #989898;
  margin-top: 1em;
  display: block;
}

.test-pack-check-table .final-answer .expected {
  color: #08c;
}

.test-pack-check-table .final-answer .correct {
  color: #34ae72;
  display: block;
}

.test-pack-check-table .final-answer .wrong {
  color: #db6e60;
  display: block;
}

.test-pack-variants-table {
  background: #fff;
  margin-bottom: 2em;
  padding: 2%;
}

.test-pack-variants-table li {
  margin-bottom: 2em;
}

.test-pack-variants-table li.wrong, .test-pack-variants-table li.correct {
  margin-bottom: 0;
}

.test-pack-variants-table li.wrong .variants, .test-pack-variants-table li.correct .variants {
  display: none;
}

.test-pack-variants-table li.wrong, .test-pack-variants-table li.wrong + li {
  border-left: 2px solid #db6e60;
}

.test-pack-variants-table li.correct, .test-pack-variants-table li.correct + li {
  border-left: 2px solid #34ae72;
}

.test-pack-variants-table .final-answer .expected-title {
  color: #989898;
  margin-top: .5em;
  margin-bottom: .5em;
  display: block;
}

.test-pack-variants-table .final-answer .expected {
  color: #08c;
}

.test-pack-variants-table .final-answer .correct, .test-pack-variants-table .final-answer .expected-correct {
  color: #34ae72;
  display: block;
}

.test-pack-variants-table .final-answer .wrong {
  color: #db6e60;
  display: block;
}

.test-pack-write-table {
  background: #fff;
  margin-bottom: 2em;
  padding: 2%;
}

.test-pack-write-table li {
  margin-bottom: 2em;
}

.test-pack-write-table li.wrong, .test-pack-write-table li.correct {
  margin-bottom: 0;
}

.test-pack-write-table li.wrong .variants, .test-pack-write-table li.correct .variants {
  display: none;
}

.test-pack-write-table li.wrong, .test-pack-write-table li.wrong + li {
  border-left: 2px solid #db6e60;
}

.test-pack-write-table li.correct, .test-pack-write-table li.correct + li {
  border-left: 2px solid #34ae72;
}

.test-pack-write-table .final-answer .expected-title {
  color: #989898;
  margin-top: .5em;
  margin-bottom: .5em;
  display: block;
}

.test-pack-write-table .final-answer .expected {
  color: #08c;
}

.test-pack-write-table .final-answer .correct, .test-pack-write-table .final-answer .expected-correct {
  color: #34ae72;
  display: block;
}

.test-pack-write-table .final-answer .wrong {
  color: #db6e60;
  display: block;
}

.pack-test-prepare {
  background: #fff;
  padding: 1rem 0;
}

.pack-test-prepare__first, .pack-test-prepare__second {
  padding: 0 1rem;
}

.pack-test-prepare__buttons .btn-primary {
  padding-left: 4em;
  padding-right: 4em;
}

.pack-test-prepare__option {
  flex-flow: row;
  margin-bottom: 1.5rem;
  display: flex;
}

.pack-test-prepare__option-title {
  flex: 50%;
  padding-right: 1rem;
}

.pack-test-prepare__option-title-fade {
  opacity: .5;
  font-size: 80%;
  display: block;
}

.pack-test-prepare__option-value {
  flex: 50%;
}

.pack-test-prepare__option-value .custom-control-label {
  cursor: pointer;
}

.pack-test-prepare__option-value .form-control--single-underline {
  height: auto;
  padding-top: 0;
}

.pack-test-prepare__option-value-error {
  color: #f89a90;
}

.form-control.form-control--single-underline {
  border-color: currentColor currentColor #007bff40;
  border-top-style: none;
  border-top-width: medium;
  border-left-style: none;
  border-left-width: medium;
  border-right-style: none;
  border-right-width: medium;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.form-control.form-control--single-underline:focus {
  box-shadow: none;
  outline: none;
}

.pack-test-set {
  background: #fff;
  padding: 1em;
}

.pack-test-set__title {
  margin-bottom: 1em;
  font-weight: bold;
}

.pack-test-set__matching-questions ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pack-test-set__matching-question {
  margin-bottom: 1em;
}

.pack-test-set__matching-question--correct {
  color: #399011;
}

.pack-test-set__matching-question--correct select {
  color: #399011;
  border-color: #399011;
}

.pack-test-set__matching-question--wrong {
  color: red;
}

.pack-test-set__matching-question--wrong select {
  color: red;
  border-color: red;
}

.pack-test-set__matching-question-expected {
  margin-top: .2rem;
  display: block;
}

.pack-test-set__matching-answers ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pack-test-set__matching-question-image > img, .pack-test-set__matching-answer-image > img {
  max-width: 100%;
  max-height: 100%;
}

.pack-test-set__matching-answer {
  margin-bottom: 1em;
}

.pack-test-set__matching-answer--occupied {
  opacity: .3;
}

.pack-test-set__written-questions ul {
  list-style: decimal;
}

.pack-test-set__written-question {
  margin-bottom: 1rem;
}

.pack-test-set__written-question--correct {
  color: #399011;
}

.pack-test-set__written-question--correct > input.form-control {
  padding-left: 1rem;
}

.pack-test-set__written-question--wrong {
  color: red;
}

.pack-test-set__written-question--wrong > input.form-control {
  padding-left: 1rem;
}

.pack-test-set__choice-questions > ul {
  list-style: decimal;
}

.pack-test-set__choice-question {
  margin-bottom: 1rem;
}

.pack-test-set__choice-answer-image > img, .pack-test-set__choice-question-image > img {
  max-width: 150px;
  max-height: 150px;
}

.pack-test-set__choice-question--correct {
  color: #399011;
}

.pack-test-set__choice-question--wrong {
  color: red;
}

.pack-test-set__choice-answers {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pack-test-set__choice-answers .custom-control-label {
  cursor: pointer;
}

.pack-test-set__yn-questions {
  list-style: decimal;
}

.pack-test-set__yn-question {
  margin-bottom: 1rem;
}

.pack-test-set__yn-image > img {
  max-width: 150px;
  max-height: 150px;
}

.pack-test-set__yn-question--correct {
  color: #399011;
}

.pack-test-set__yn-question--wrong {
  color: red;
}

.pack-test-set__yn-selection .custom-control-label, .pack-test-set__yn-selection .custom-radio {
  cursor: pointer;
}

.pack-test-set__checkButton {
  text-align: center;
  margin-top: 2rem;
}

.pack-test-set__checkButton .btn {
  width: 100%;
}

.pack-test-set .custom-radio.custom-radio-correct {
  color: #399011;
}

.pack-test-set .custom-radio.custom-radio-correct .custom-control-label:after {
  display: none;
}

.pack-test-set .custom-radio.custom-radio-correct .custom-control-label:before {
  color: #399011;
  content: "✓";
  background-color: #0000;
  border: none;
  top: .1rem;
}

.pack-test-set .custom-radio.custom-radio-wrong {
  color: red;
}

.pack-test-set .custom-radio.custom-radio-wrong .custom-control-label:after {
  display: none;
}

.pack-test-set .custom-radio.custom-radio-wrong .custom-control-label:before {
  color: red;
  content: "✗";
  background-color: #0000;
  border: none;
  top: .1rem;
}

.pack-test-score {
  text-align: center;
  background: #fff;
  border-bottom: 1px solid #ddd;
  padding: 2rem;
}

.pack-test-score__title {
  opacity: .7;
  font-size: 150%;
  display: block;
}

.pack-test-score__percent {
  color: #4257b1;
  font-size: 200%;
  font-weight: bold;
  display: block;
}

@media (width >= 992px) {
  .pack-test-prepare {
    flex-flow: wrap;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
  }

  .pack-test-prepare__first, .pack-test-prepare__second {
    flex: 50%;
  }

  .pack-test-prepare__second {
    border-left: 1px solid #ddd;
  }

  .pack-test-prepare__buttons {
    flex: 100%;
  }

  .pack-test-set__checkButton .btn {
    width: 60%;
  }
}

.fl-form .btn-link {
  text-decoration: none;
}

.fl-form .bootstrap-select .dropdown-toggle {
  background: #fff;
  border-color: #ced4da;
}

.fl-form .bootstrap-select.is-invalid .dropdown-toggle {
  background: #dc35450d;
  border-color: #dc3545;
}

.fl-form .form-control.is-invalid {
  background: #dc35450d;
}

.fl-form__label {
  font-weight: bold;
}

.fl-form__label-optional {
  opacity: .7;
  font-size: 70%;
  font-weight: normal;
}

@media (width >= 768px) {
  .fl-form__label-optional {
    display: block;
  }
}

.fl-form-main-fixed {
  z-index: 100;
  background: #fff;
  flex-flow: wrap;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: fixed;
  top: 0;
  box-shadow: 0 4px 20px #777;
}

.fl-form-main {
  margin-bottom: 1rem;
}

.fl-form-main > .form-group:last-child {
  margin-bottom: 0;
}

.fl-form-main .custom-radio label {
  cursor: pointer;
}

.fl-form-main__title {
  margin: 0 0 1em;
  font-size: 1.2rem;
  line-height: 1.2;
}

.fl-form-main__thumb {
  cursor: pointer;
  border: 1px solid #8d8d8d66;
  width: 7.5rem;
  height: 7.5rem;
  display: inline-block;
  position: relative;
}

.fl-form-main__thumb:hover, .fl-form-main__thumb:active, .fl-form-main__thumb:focus {
  border-color: #8d8d8d99;
}

.fl-form-main__thumb:hover .fl-form-main__thumb-button, .fl-form-main__thumb:active .fl-form-main__thumb-button, .fl-form-main__thumb:focus .fl-form-main__thumb-button {
  opacity: .9;
}

.fl-form-main__thumb span {
  color: #fff;
}

.fl-form-main__thumb-preview {
  width: 100%;
  height: 100%;
}

.fl-form-main__thumb-preview img {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.fl-form-main__thumb--empty .fl-form-main__thumb-preview {
  display: none;
}

.fl-form-main__thumb-button {
  opacity: .8;
  text-align: center;
  background: #8d8d8d;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (width >= 768px) {
  .fl-form-secondary__languages {
    flex-direction: row;
    display: flex;
  }

  .fl-form-secondary__languages select {
    width: auto;
    display: inline-block;
  }
}

.custom-select--selected-placeholder:invalid {
  color: #aaa;
}

.custom-select--selected-placeholder option {
  color: #212529;
}

.pack-edit__final-options {
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.pack-edit__final-options label {
  margin: 0;
  display: inline;
}

.pack-edit__final-options .btn-link {
  padding-left: 0;
  padding-right: 0;
}

.pack-edit__final-options-input {
  margin-bottom: .7rem;
}

.pack-edit__final-options-body {
  display: block;
}

.fl-form__add-card {
  text-align: center;
  margin-top: 1.5em;
  margin-left: 50%;
  padding-right: 3rem;
}

.fl-form__add-card-button {
  opacity: .5;
  margin-left: -2.3rem;
  padding: .5rem;
  font-size: 250%;
}

.fl-form__add-card-button > span {
  display: block;
}

.fl-form__add-card-button:hover, .fl-form__add-card-button:focus, .fl-form__add-card-button:active {
  opacity: .8;
}

.fl-form__preview {
  background: #f6f6f6;
}

.fl-form__preview strong {
  color: #aaa;
}

.fl-form__preview table {
  border-collapse: collapse;
  margin: 0 auto;
}

.fl-form__preview td {
  background: #fff;
  border: 1px solid #aaa;
  width: 40%;
  margin-bottom: 1rem;
}

.fl-form-cards {
  counter-reset: list;
}

.fl-form-cards--danger .fl-form-cards__li:first-child, .fl-form-cards--danger .fl-form-cards__li:nth-child(2) {
  border: 1px solid #f1a0a8;
}

.fl-form-cards__li {
  margin-bottom: 1em;
  margin-right: 3em;
  position: relative;
}

.fl-form-cards__card {
  background: #fff;
  padding-left: 2em;
  position: relative;
}

.fl-form-cards__face-side, .fl-form-cards__back-side {
  flex-flow: row;
  padding: 1rem .5rem 1rem 2rem;
  display: flex;
}

.fl-form-cards__side-input {
  flex: 1;
}

.fl-form-cards__side-input .ProseMirror {
  max-height: initial;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  padding-bottom: .1rem;
  padding-left: 0;
  padding-right: 0;
  line-height: 1.2;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.fl-form-cards__side-input .ProseMirror:focus {
  box-shadow: none;
  border-color: #80bdff;
  outline: none !important;
}

.fl-form-cards__side-input .ProseMirror::placeholder {
  opacity: .6;
}

.fl-form-cards__side-input .ProseMirror.is-invalid {
  border-color: #dc3545;
}

.fl-form-cards__side-picture {
  margin-left: 1rem;
}

.fl-form-cards__side-picture img {
  max-width: 4rem;
  max-height: 4rem;
}

.fl-form-cards__back-text, .fl-form-cards__face-text {
  color: #495057;
  resize: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  padding-bottom: .1rem;
  padding-left: 0;
  padding-right: 0;
  line-height: 1.2;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.fl-form-cards__back-text:focus, .fl-form-cards__face-text:focus {
  box-shadow: none;
  border-color: #80bdff;
  outline: none !important;
}

.fl-form-cards__back-text::placeholder, .fl-form-cards__face-text::placeholder {
  opacity: .6;
}

.fl-form-cards__back-text.is-invalid, .fl-form-cards__face-text.is-invalid {
  border-color: #dc3545;
}

.fl-form-cards__index {
  text-align: center;
  width: 3em;
  line-height: 2;
  display: block;
  position: absolute;
  top: 1rem;
  left: 0;
}

.fl-form-cards__index:before {
  counter-increment: list;
  content: counter(list);
}

.fl-form-cards__delete {
  text-align: center;
  width: 3em;
  display: block;
  position: absolute;
  top: 2px;
  right: -3em;
}

.fl-form-cards__delete .btn {
  opacity: .8;
}

.fl-form-cards__delete .btn:focus, .fl-form-cards__delete .btn:hover, .fl-form-cards__delete .btn:active {
  opacity: 1;
}

.fl-form-cards__face-buttons, .fl-form-cards__back-buttons {
  flex-direction: column;
  margin-top: -.5rem;
  margin-left: .5rem;
  display: flex;
}

.fl-form-cards__face-buttons .btn, .fl-form-cards__back-buttons .btn {
  opacity: .5;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: 1rem;
}

.fl-form-cards__face-buttons .btn:hover, .fl-form-cards__back-buttons .btn:hover, .fl-form-cards__face-buttons .btn:focus, .fl-form-cards__back-buttons .btn:focus, .fl-form-cards__face-buttons .btn:active, .fl-form-cards__back-buttons .btn:active {
  opacity: .8;
}

.fl-form-cards__face-buttons .btn .fc, .fl-form-cards__back-buttons .btn .fc {
  vertical-align: middle;
  font-size: 1.5em;
}

@media (width >= 768px) {
  .fl-form-cards__back-side--sm-bl {
    border-left: 1px solid #ddd;
  }

  .fl-form-cards__delete {
    display: none;
  }

  .fl-form-cards__li:hover .fl-form-cards__delete {
    display: block;
  }
}

.fl-form-select-image-gallery {
  flex-flow: row;
  display: flex;
  overflow-x: auto;
}

.fl-form-select-image-gallery__image {
  margin: 1rem .5rem;
}

.fl-form-select-image-gallery__image img {
  max-width: 10rem;
  max-height: 10rem;
}

.fl-form-select-image-gallery__image button {
  background: none;
  border: 2px solid #0000;
  min-width: 10rem;
  min-height: 10rem;
  margin: 0;
  padding: 0;
}

.fl-form-select-image-gallery__image button:hover, .fl-form-select-image-gallery__image button:active, .fl-form-select-image-gallery__image button:focus {
  border-color: #ffdc60;
}

.my-sidebar, .my-main {
  background: #fff;
}

.my-sidebar .nav {
  margin-top: 1em;
}

.my-sidebar .nav-link {
  padding: 5px 20px;
}

.my-sidebar .nav-pills-header {
  color: #9b9b9b;
  position: relative;
}

.my-sidebar .nav-pills-header:before {
  content: " ";
  background: #f1f1f1;
  height: 1px;
  position: absolute;
  top: 1.5em;
  left: 0;
  right: 0;
}

.my-sidebar .nav-pills-header > span {
  background: #fff;
  margin-left: 15px;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

.memtrick-person-link > img {
  vertical-align: baseline;
  opacity: .8;
  width: .8em;
  height: .8em;
  display: inline-block;
}

.fl-explorer-menu__label {
  background-color: #f1f1f1;
  border-radius: 4px;
  margin-top: 2rem;
  margin-bottom: .5rem;
  padding: .25rem .5rem;
}

.fl-explorer-menu__lang-select {
  text-transform: capitalize;
}

.fl-explorer-menu__label, .fl-explorer-tree__children {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.fl-explorer-tree__node-expander {
  cursor: pointer;
  background: none;
  border: none;
  width: 1rem;
  margin: .2rem 0 0;
  padding: 0;
  line-height: 1rem;
  position: absolute;
  left: 0;
  outline: none !important;
}

.fl-explorer-tree__node--active > .fl-explorer-tree__node-label {
  color: #007cff !important;
}

.fl-explorer-tree__node-label {
  cursor: pointer;
}

.fl-explorer-tree__children {
  padding-left: 1.4rem;
  position: relative;
}

.fl-explorer-tree__node > .fl-explorer-tree__children {
  display: none;
}

.fl-explorer-tree__node.fl-explorer-tree__node--open > .fl-explorer-tree__node-expander {
  transform: rotate(90deg);
}

.fl-explorer-tree__node.fl-explorer-tree__node--open > .fl-explorer-tree__children {
  display: inherit;
}

.fl-explorer-content {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.fl-explorer-content--loading {
  opacity: .2;
}

.fl-explorer-content__title {
  opacity: .6;
}

.fl-explorer-content__title-gutter {
  padding: 0 .3rem;
}

.fl-explorer-content__section-title {
  margin-top: 2rem;
  margin-bottom: .4rem;
}

.fl-explorer-pack-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: .5rem;
  box-shadow: 2px 2px 2px #ddd;
}

.fl-explorer-pack-item__thumb {
  text-align: center;
  float: right;
  height: 100%;
}

.fl-explorer-pack-item__thumb > img {
  width: 4rem;
  height: 4rem;
}

.fl-explorer-pack-item__card p {
  margin: 0;
}

.fl-explorer-pack-item__count {
  color: #8b8b8b;
}

.fl-explorer-pack-item__author {
  color: #007cff !important;
}

.fl-explorer-pack-item__author > img {
  vertical-align: text-bottom;
  width: 1rem;
  height: 1rem;
  display: inline-block;
}

.fl-explorer-pack-item__title {
  margin-top: .5rem;
}

.fl-explorer-pack-item__card {
  border-left: 1px solid #cacaca;
  position: relative;
}

.fl-explorer-pack-item__card-face {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: 300;
}

.fl-explorer-pack-item__card-back {
  color: #cacaca;
  max-height: 4rem;
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
}

.fl-explorer-pack-item__card-back--fade:after {
  content: " ";
  background: linear-gradient(#fff0 0%, #fff 100%);
  width: 100%;
  height: 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.fl-explorer-pack-item__card-image {
  opacity: .7;
}

.fl-explorer-pack-item__card-image img {
  max-width: 5rem;
  max-height: 5rem;
}

.fl-explorer-collection-list {
  margin: 0;
  padding: 0;
}

.fl-explorer-collection-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: .7rem;
  margin-right: 1rem;
  padding: .5rem 1rem;
  box-shadow: 2px 2px 2px #ddd;
}

.fl-explorer-collection-item__count {
  color: #8b8b8b;
}

.fl-explorer-collection-item__author, .fl-explorer-pack-item__author {
  font-size: 12px;
}

.fl-explorer-collection-item__author {
  color: #007cff !important;
}

.fl-explorer-collection-item__author > img {
  vertical-align: text-bottom;
  width: 1rem;
  height: 1rem;
  display: inline-block;
}

.fl-explorer-collection-item__count {
  text-transform: lowercase;
}

.fl-explorer-collection-item__title {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.fl-explorer-content__empty {
  text-align: center;
  opacity: .5;
  font-size: 130%;
}

.fl-explorer-content__empty p {
  margin: 0 0 .5em;
  line-height: 2.5;
}

.fl-explorer-content__empty .input-group {
  justify-content: center;
}

.fl-explorer-content__empty input[type="text"] {
  flex-grow: 0;
  width: 15em;
}

.fl-spell-game__question {
  background: #fff;
  max-width: 42rem;
  margin: 0 auto;
  position: relative;
  box-shadow: 1px 1px 5px #3333334d;
}

.fl-spell-game__question-content {
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.fl-spell-game__question-audio button {
  font-size: 300%;
}

.fl-spell-game__question-text {
  flex: 1;
  margin-left: 1rem;
}

.fl-spell-game__question-text p {
  margin-bottom: 0;
}

.fl-inpage-modal {
  background: #fff;
  max-width: 42rem;
  margin: 0 auto;
  padding: 1rem 0;
  box-shadow: 0 0 7px #999;
}

.fl-inpage-modal__head {
  text-align: center;
  margin-bottom: 1rem;
  padding: 0 3rem;
}

.fl-inpage-modal__head > span {
  font-size: 140%;
  line-height: 1.7;
}

.fl-inpage-modal__head > small {
  opacity: .7;
}

.fl-inpage-modal__body {
  padding: 0 2rem;
}

.fl-inpage-modal__footer {
  margin-top: 1.5rem;
}

.fl-memo-progress {
  color: #fff;
  margin-left: 1rem;
  margin-right: 1rem;
  display: block;
}

.fl-memo-progress__text-line {
  flex-direction: row;
  display: flex;
}

.fl-memo-progress__text-main {
  flex: 1;
}

.fl-memo-progress__count {
  margin-left: 1rem;
}

.fl-memo-progress__text-fade {
  opacity: .7;
}

.fl-memo-progress__bar {
  background: #ffffff4d;
  border-radius: .2rem;
  width: 100%;
  height: .6rem;
  overflow: hidden;
}

.fl-memo-progress__bar-thumb {
  background: #fff;
  height: 100%;
}

@media (width <= 767px) {
  .fl-memo-progress__hide-xs {
    display: none;
  }
}

@media (width <= 767px) and (orientation: portrait) {
  .fl-memo-progress__hide-xs-portrait {
    display: none;
  }
}

@media (width >= 1200px) {
  .fl-memo-progress {
    width: 42rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.fl-memo-edit-card {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.fl-memo-edit-card__side {
  background: #fff;
  border: 1px solid #eee;
  flex-flow: row;
  flex: 1;
  margin-bottom: 1rem;
  padding: 1.5rem;
  display: flex;
  box-shadow: 1px 1px 1px #3333334d;
}

.fl-memo-edit-card__side-input {
  flex: 1;
}

.fl-memo-edit-card__side-input .ProseMirror {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  padding-bottom: .1rem;
  padding-left: 0;
  padding-right: 0;
  line-height: 1.2;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.fl-memo-edit-card__side-input .ProseMirror:focus {
  box-shadow: none;
  border-color: #80bdff;
  outline: none !important;
}

.fl-memo-edit-card__side-input .ProseMirror::placeholder {
  opacity: .6;
}

.fl-memo-edit-card__side-input .ProseMirror.is-invalid {
  border-color: #dc3545;
}

.fl-memo-edit-card__side-picture {
  margin-left: 1rem;
}

.fl-memo-edit-card__side-picture img {
  max-width: 4rem;
  max-height: 4rem;
}

.fl-memo-edit-card__side-buttons {
  flex-direction: column;
  margin-left: .5rem;
  display: flex;
}

.fl-memo-edit-card__side-buttons .btn {
  opacity: .5;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: 1.5em;
  line-height: 1;
}

.fl-memo-edit-card__side-buttons .btn:focus, .fl-memo-edit-card__side-buttons .btn:active, .fl-memo-edit-card__side-buttons .btn:hover {
  opacity: 1;
}

.fl-memo-edit-card textarea:invalid {
  border-color: #dc3545;
}

.fl-memo-edit-card textarea:invalid ~ .invalid-feedback {
  display: block;
}

.fl-landing {
  color: #3e3e3e;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 300;
}

.fl-landing__title-image img {
  max-width: 100%;
}

@media (width >= 992px) {
  .fl-landing__title-image img {
    max-width: 40vw;
  }
}

.fl-landing__icon {
  font-size: 3.5em;
  display: block;
}

.fl-landing__icon--fade {
  opacity: .5;
}

.fl-landing__icon--blue {
  color: #4aa0fd;
  opacity: .9;
}

.fl-landing--alternate {
  background: #ddd;
}

.fl-landing--h1 {
  color: #000;
  margin: 0;
  font-size: 2.2rem;
  font-weight: 300;
}

.fl-landing--h2 {
  color: #111;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 300;
}

.fl-landing--h3 {
  color: #111;
  margin: 0;
  font-size: 1.4rem;
  font-weight: 300;
}

.fl-landing--last-chance i {
  margin-bottom: 1rem;
}

.fl-landing--last-chance span {
  font-weight: bold;
}

.fl-landing .social-providers {
  margin: 0;
}

.fl-landing .social-providers .btn {
  width: auto;
  margin-top: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  display: inline-block;
}

.fl-landing--reg {
  font-size: 1.5rem;
}

.fl-landing--reg-fade {
  opacity: .6;
}

.fl-match-game__timer {
  color: #fff;
}

.fl-match-game__timer-label {
  opacity: .8;
  font-size: 80%;
  font-weight: bold;
}

.fl-match-game__field {
  background: #f1f1f1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fl-match-game__side {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: 2px solid #eee;
  padding-top: .5rem;
  position: absolute;
  top: 50%;
  left: 50%;
}

.fl-match-game__side > div {
  text-overflow: ellipsis;
  max-height: 50vh;
  margin: 0;
  padding: 0 .5rem .5rem;
  display: block;
  overflow: hidden;
}

.fl-match-game__side-image {
  text-align: center;
  padding: 0 .5rem .5rem;
}

.fl-match-game__side-image > img {
  max-width: 150px;
  max-height: 150px;
}

.fl-match-game__side--moving {
  border-color: #bbb;
}

.fl-match-game__side--over {
  border-color: #198fbb;
}

.fl-memo-heatmap__area {
  margin: 1.3rem auto 0;
  position: relative;
}

.fl-memo-heatmap__area-cell {
  position: absolute;
}

.fl-memo-heatmap__area-cell--today {
  border: 1px solid #7d7d7d;
}

.fl-memo-heatmap__area-month {
  font-size: 80%;
  position: absolute;
  top: -1.3rem;
}

.fl-memo-heatmap__legend {
  text-align: center;
  margin: 1em 0 0;
  padding: 0;
  font-size: 85%;
}

.fl-memo-heatmap__legend > li {
  margin: 0 1rem;
  display: inline-block;
}

.fl-memo-heatmap__legend-value {
  color: #007cff;
}

.fl-dashboard-memo-grid .dropdown-toggle:after {
  display: none;
}

.fl-dashboard-memo-grid--grid .fl-dashboard-memo-grid--table-only {
  display: none !important;
}

.fl-dashboard-memo-grid--grid {
  flex-flow: wrap;
  margin-left: .5rem;
  display: flex;
}

.fl-dashboard-memo-grid--grid > div {
  flex-grow: 0;
  width: 50%;
  padding: 0 .5rem .5rem 0;
}

.fl-dashboard-memo-grid--grid .fl-dashboard-memo-grid__card-placeholder {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 15.5rem;
  display: flex;
}

.fl-dashboard-memo-grid--grid .fl-dashboard-memo-grid__card-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: -.25rem;
  margin-right: -.5rem;
  display: flex;
}

.fl-dashboard-memo-grid--grid .fl-dashboard-memo-grid__card-play {
  margin-top: 1rem;
}

.fl-dashboard-memo-grid--grid .fl-dashboard-memo-grid__card-metrics {
  display: table;
}

.fl-dashboard-memo-grid--grid .fl-dashboard-memo-grid__card-metric {
  display: table-row;
}

.fl-dashboard-memo-grid--grid .fl-dashboard-memo-grid__card-metric span {
  display: table-cell;
}

.fl-dashboard-memo-grid--grid .fl-dashboard-memo-grid__card-metric em {
  text-align: right;
  padding-left: 1rem;
  display: table-cell;
}

.fl-dashboard-memo-grid--grid .fl-dashboard-memo-grid__add-card {
  text-align: center;
  border-top: 1px solid #c4c5c64d;
}

@media (width >= 992px) {
  .fl-dashboard-memo-grid--grid {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .fl-dashboard-memo-grid--grid > div {
    width: 33.3%;
    margin-bottom: 1rem;
    padding: 0 .5rem;
  }
}

.fl-dashboard-memo-grid--table .fl-dashboard-memo-grid--grid-only {
  display: none !important;
}

.fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__row {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  padding: .5rem;
  display: flex;
}

.fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-placeholder {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
  margin-bottom: .5rem;
  display: flex;
}

.fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-header {
  width: 40%;
  padding-left: .5em;
}

.fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__row-foot {
  flex: 100%;
}

.fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-metrics {
  display: block;
}

.fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-metric {
  display: inline-block;
}

.fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-timings {
  justify-content: center;
}

.fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-timing {
  margin-left: .5em;
  margin-right: .5em;
}

@media (width >= 480px) {
  .fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-metric {
    display: inline-block;
  }

  .fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-header {
    width: 50%;
  }
}

@media (width >= 992px) {
  .fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-metrics {
    display: table;
  }

  .fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-metric {
    display: table-row;
  }

  .fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-metric > span {
    padding-right: 1em;
    display: table-cell;
  }

  .fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-metric > em {
    text-align: right;
    display: table-cell;
  }

  .fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-header {
    width: 40%;
  }

  .fl-dashboard-memo-grid--table .fl-dashboard-memo-grid__card-timings {
    width: 15%;
  }
}

.fl-dashboard-memo-grid__card {
  padding: .25rem .5rem;
}

.fl-dashboard-memo-grid__card-placeholder {
  border: 3px solid #dedede;
  font-size: 1.2rem;
  color: #b7b7b7 !important;
}

.fl-dashboard-memo-grid__card-play {
  font-size: 1.8rem;
}

.fl-dashboard-memo-grid__card-totals {
  font-size: 80%;
  font-weight: bold;
}

.fl-dashboard-memo-grid__card-metrics {
  font-size: 80%;
}

.fl-dashboard-memo-grid__card-metric span {
  opacity: .8;
}

.fl-dashboard-memo-grid__card-metric em {
  font-style: normal;
}

.fl-dashboard-memo-grid__card-timing {
  opacity: .4;
  font-size: 70%;
}

.fl-dashboard-memo-grid__card-timings {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.fl-radial-progress {
  display: inline-block;
  position: relative;
}

svg.fl-radial-progress__svg {
  stroke-width: 2px;
  fill: #0000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
}

.fl-radial-progress__svg-track, .fl-radial-progress__svg-thumb {
  transform: translateX(50%)translateY(50%)rotate(-90deg);
}

.fl-radial-progress__svg-thumb {
  stroke: #00bccc;
}

.fl-radial-progress__svg-track, .fl-radial-progress--blue .fl-radial-progress__svg-track {
  stroke: #efefef;
}

.fl-radial-progress__text {
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  display: flex;
}

.fl-keep-nl, .fl-tt-text {
  white-space: pre-line;
}

.fl-tt-html > p {
  margin: 0;
}

.fl-learn-card__container {
  flex-flow: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.fl-learn-card__question {
  flex-basis: 40%;
  min-height: 15rem;
  margin-left: .5rem;
  margin-right: .5rem;
  position: relative;
}

.fl-learn-card__question > .play-card-stack__card {
  position: absolute;
  inset: 0;
}

.fl-learn-card__answers {
  flex-flow: column;
  flex-basis: 60%;
  justify-content: center;
  margin: 0;
  padding: .5rem .5rem 0;
  list-style: none;
  display: flex;
  overflow: hidden;
}

.fl-learn-card__answers > li {
  flex: .25;
  position: relative;
}

.fl-learn-card__answers-item {
  position: absolute;
  inset: .25rem;
}

.fl-learn-card__answers-answer {
  cursor: pointer;
  background: #fff;
  border-width: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 0 2px #3333334d;
}

.fl-learn-card__answers-answer.btn {
  padding-top: .175rem;
  padding-bottom: .175rem;
  font-size: 1.2rem;
}

.fl-learn-card__answers-answer-image {
  padding: .25rem;
  position: absolute;
  inset: 0;
}

.fl-learn-card__answers-answer-image img {
  vertical-align: top;
}

.fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-image {
  width: 30%;
}

.fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-text {
  width: 70%;
  height: auto;
  padding: .25rem .25rem .25rem 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.fl-learn-card__answers-answer-shortcut {
  z-index: 1;
  background: #b1aadd;
  padding: 0 .5em;
  font-size: 80%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.fl-learn-card__answers-answer-text {
  flex-flow: column;
  justify-content: center;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
}

.fl-learn-card__answers-answer-text > p {
  margin: 0;
}

.fl-learn-card__answers-answer--gone {
  opacity: .2 !important;
}

.fl-learn-card__answers-answer--correct {
  opacity: 1 !important;
  background: #e4efdf !important;
}

.fl-learn-card__answers-answer--incorrect {
  opacity: 1 !important;
  background: #f8d0d0 !important;
}

.fl-learn-card-wrong-display {
  text-align: left;
  background: #fff;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: 0 0 2px #3333334d;
}

.fl-learn-card-wrong-display__title {
  color: #f89a90;
  border-bottom: 1px solid #ddd;
  padding: .5rem 1rem;
  font-size: 150%;
}

.fl-learn-card-wrong-display__title > .far {
  vertical-align: bottom;
  margin-right: 1rem;
  font-size: 160%;
}

.fl-learn-card-wrong-display__value {
  flex-direction: column;
  flex: 1;
  padding: .5rem 1rem 0;
  display: flex;
  overflow: hidden;
}

.fl-learn-card-wrong-display__value-label {
  opacity: .6;
  width: 100%;
  margin-bottom: .5rem;
  display: block;
}

.fl-learn-card-wrong-display__value-answer {
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.fl-learn-card-wrong-display__value-answer > p {
  margin: 0;
}

.fl-learn-card-wrong-display__continue {
  opacity: .6;
  text-align: center;
  margin: .5rem 1rem;
}

.fl-learn-card__write {
  background: #fff;
  list-style: none;
  overflow: hidden;
}

.fl-learn-card__write-buttons {
  text-align: end;
}

.fl-learn-card__write {
  padding: .5rem .5rem 0;
}

.fl-learn-card__write-input, .fl-learn-card__write-buttons {
  margin-bottom: .5rem;
}

@media (width <= 991px) and (orientation: landscape) {
  .fl-learn-card__question {
    min-height: auto;
  }

  .fl-learn-card__answers {
    flex-direction: row;
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-image {
    width: auto;
    height: 30%;
    padding: .25rem;
    inset: auto 0 0;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-image img {
    vertical-align: top;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-text {
    width: auto;
    height: 70%;
    padding: .25rem .25rem 0;
    position: absolute;
    inset: 0 0 auto;
  }
}

@media (orientation: landscape) {
  .fl-learn-card__write {
    display: flex;
  }

  .fl-learn-card__write-input {
    flex: 100;
    margin-right: 1rem;
  }

  .fl-learn-card__write-buttons {
    white-space: nowrap;
    flex: 1;
  }

  .fl-learn-card__answers > li {
    max-width: 25%;
  }

  .fl-learn-card__answers.fl-learn-card__answers--2lines {
    flex-wrap: wrap;
  }

  .fl-learn-card__answers.fl-learn-card__answers--2lines > li {
    flex: 25%;
    max-height: 50%;
  }
}

@media (width >= 992px) {
  .fl-learn-card__question {
    width: 42rem;
    height: 24rem;
    min-height: auto;
    margin: 0 auto;
  }

  .fl-learn-card__answers {
    flex-direction: row;
    flex: 1;
    max-height: 15rem;
    padding: 1rem 1rem 0;
    position: static;
  }

  .fl-learn-card__answers > li {
    max-width: 25%;
    max-height: 100%;
  }

  .fl-learn-card__answers-answer-shortcut {
    display: block;
  }

  .fl-learn-card__answers-answer.btn {
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: 1rem;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-image {
    width: auto;
    height: 40%;
    padding: 1rem / 2 1rem 1rem;
    inset: auto 0 0;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-image img {
    vertical-align: top;
  }

  .fl-learn-card__answers-answer.fl-learn-card__answers-answer--image-and-text .fl-learn-card__answers-answer-text {
    width: auto;
    height: 60%;
    padding: 1.2rem 1rem 0;
    position: absolute;
    inset: 0 0 auto;
  }

  .fl-learn-card__write {
    padding: 1rem 1rem 0;
  }

  .fl-learn-card__write-input, .fl-learn-card__write-buttons {
    margin-bottom: 1rem;
  }

  .fl-learn-card__write {
    width: 42rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.fl-memo-choice__question {
  position: relative;
}

.fl-memo-choice__question--1 {
  height: 70%;
}

.fl-memo-choice__question--1 + .fl-memo-choice__buttons {
  height: 30%;
}

.fl-memo-choice__question--2 {
  height: 60%;
}

.fl-memo-choice__question--2 + .fl-memo-choice__buttons {
  height: 40%;
}

.fl-memo-choice__question--3, .fl-memo-choice__question--3 + .fl-memo-choice__buttons, .fl-memo-choice__question--4, .fl-memo-choice__question--5, .fl-memo-choice__question--4 + .fl-memo-choice__buttons, .fl-memo-choice__question--5 + .fl-memo-choice__buttons {
  height: 50%;
}

.fl-memo-choice__question--6, .fl-memo-choice__question--7, .fl-memo-choice__question--8 {
  height: 40%;
}

.fl-memo-choice__question--6 + .fl-memo-choice__buttons, .fl-memo-choice__question--7 + .fl-memo-choice__buttons, .fl-memo-choice__question--8 + .fl-memo-choice__buttons {
  height: 60%;
}

.fl-memo-choice__buttons > .fl-learn-card__answers {
  height: 100%;
}

@media (orientation: landscape) {
  .fl-memo-choice__question--1, .fl-memo-choice__question--2, .fl-memo-choice__question--3, .fl-memo-choice__question--4, .fl-memo-choice__question--5, .fl-memo-choice__question--6, .fl-memo-choice__question--7, .fl-memo-choice__question--8, .fl-memo-choice__question--1 + .fl-memo-choice__buttons, .fl-memo-choice__question--2 + .fl-memo-choice__buttons, .fl-memo-choice__question--3 + .fl-memo-choice__buttons, .fl-memo-choice__question--4 + .fl-memo-choice__buttons, .fl-memo-choice__question--5 + .fl-memo-choice__buttons, .fl-memo-choice__question--6 + .fl-memo-choice__buttons, .fl-memo-choice__question--7 + .fl-memo-choice__buttons, .fl-memo-choice__question--8 + .fl-memo-choice__buttons {
    height: 50%;
  }
}

@media (width >= 992px) {
  .fl-memo-choice__question {
    margin-left: auto;
    margin-right: auto;
    width: 42rem !important;
    height: 24rem !important;
  }

  .fl-memo-choice__buttons {
    margin-left: auto;
    margin-right: auto;
    height: 10rem !important;
  }
}

.fl-memo-btn-grade {
  text-align: center;
  background: #fff;
  border-color: #dadada;
  position: relative;
}

.fl-memo-btn-grade__shortcut {
  z-index: 1;
  background: #b1aadd;
  padding: 0 .5em;
  font-size: 80%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.fl-memo-btn-grade__next {
  opacity: .7;
  font-size: 60%;
  display: block;
}

.fl-memo-btn-grade--H:before {
  content: "🙁";
  color: #ce7c7c;
  font-size: 1.2em;
  font-weight: bold;
}

.fl-memo-btn-grade--M:before {
  content: "🙂";
  color: #5eda5e;
  font-size: 1.2em;
  font-weight: bold;
}

.fl-memo-btn-grade--E:before {
  content: "😊";
  color: #1db51d;
  font-size: 1.2em;
  font-weight: bold;
}

.fl-memo-btn-grade--1:before {
  content: "😖";
  color: #fb0606;
  font-size: 1.2em;
  font-weight: bold;
}

.fl-memo-btn-grade--2:before {
  content: "🙁";
  color: #ce7c7c;
  font-size: 1.2em;
  font-weight: bold;
}

.fl-memo-btn-grade--3:before {
  content: "😕";
  color: #aaa;
  font-size: 1.2em;
  font-weight: bold;
}

.fl-memo-btn-grade--4:before {
  content: "😊";
  color: #5eda5e;
  font-size: 1.2em;
  font-weight: bold;
}

.fl-memo-btn-grade--5:before {
  content: "😃";
  color: #1db51d;
  font-size: 1.2em;
  font-weight: bold;
}

.fl-memo-btn-grade--F:before {
  content: "🙁";
  color: #ce7c7c;
  font-size: 1.2em;
  font-weight: bold;
}

.fl-memo-btn-grade--R:before {
  content: "😊";
  color: #1db51d;
  font-size: 1.2em;
  font-weight: bold;
}

@media (width <= 767px) and (orientation: portrait) {
  .fl-memo-btn-grade {
    min-width: 4rem;
  }

  .fl-memo-btn-grade__text.fl-memo-btn-grade__text--hide-xs-portrait {
    display: none;
  }
}

@media (width >= 992px) {
  .fl-memo-btn-grade {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .fl-memo-btn-grade__shortcut {
    display: block;
  }
}

.fl-memo-card {
  background: #fff;
  margin-top: 2.5rem;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 2px #3333334d;
}

.fl-memo-card__side {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.fl-memo-card--both .fl-memo-card__side--question {
  height: 47%;
  top: 0;
  bottom: auto;
}

.fl-memo-card--both .fl-memo-card__side--answer {
  height: 47%;
  top: auto;
  bottom: 0;
}

.fl-memo-card__side--answer:before {
  content: " ";
  background: #ddd;
  width: 80%;
  height: 1px;
  position: absolute;
  top: -7%;
  left: 10%;
}

.fl-memo-card__tools {
  position: absolute;
  top: -2.5rem;
  right: 0;
}

.fl-memo-card__tools .btn {
  color: #6b6b6b;
  background: none;
  border: none;
  margin-left: .25rem;
  padding: .5rem;
  font-size: 1.5rem;
  line-height: 1;
  display: inline-block;
}

.fl-memo-card__actions {
  flex-direction: row;
  margin-top: -2.5rem;
  display: flex;
}

.fl-memo-card__action-btn {
  opacity: .6;
  background: none;
  border: none;
  padding: .5rem;
  font-size: 1.2rem;
  line-height: 1;
  display: block;
}

.fl-memo-card__action-btn:hover, .fl-memo-card__action-btn:focus, .fl-memo-card__action-btn:active {
  opacity: initial;
}

.fl-memo-card__action-btn .fa.fa-star {
  color: #ffcd4d;
}

.fl-memo-card__side-text {
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: auto;
}

.fl-memo-card__side-text-content {
  border-radius: .5rem;
  max-height: 100%;
  margin-left: .1rem;
  margin-right: .1rem;
  overflow: auto;
}

.fl-memo-card__side-text-content > div {
  word-break: break-word;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  font-size: 2.5rem;
}

.fl-memo-card__side-image {
  padding: 1rem;
  position: absolute;
  inset: 0;
}

.fl-memo-card__side-image img.enlargeable {
  cursor: pointer;
}

.fl-memo-card__side.fl-memo-card__side--display-all .fl-memo-card__side-image {
  padding-bottom: 0;
  bottom: 50%;
}

.fl-memo-card__side.fl-memo-card__side--display-all .fl-memo-card__side-text {
  top: 50%;
}

.fl-memo-card__side-text {
  padding: 0;
}

.fl-memo-card__side-text .fl-memo-card__side-text-content::-webkit-scrollbar {
  width: .5rem;
  height: .5rem;
}

.fl-memo-card__side-text .fl-memo-card__side-text-content::-webkit-scrollbar-track {
  background: none;
  border-radius: .3rem;
  margin: .2rem;
}

.fl-memo-card__side-text .fl-memo-card__side-text-content::-webkit-scrollbar-thumb {
  background: #bfbfbf91;
  border-radius: .3rem;
}

.fl-memo-card__side-text.fl-memo-card__side-text--align-left {
  text-align: left;
}

.fl-memo-card__side-text.fl-memo-card__side-text--text-small .fl-memo-card__side-text-content, .fl-memo-card__side-text.fl-memo-card__side-text--text-xsmall .fl-memo-card__side-text-content, .fl-memo-card__side-text.fl-memo-card__side-text--text-xxsmall .fl-memo-card__side-text-content {
  background: #00000075;
  padding: 1.5rem;
}

.fl-memo-card__side-text.fl-memo-card__side-text--text-small .fl-memo-card__side-text-content > div {
  font-size: 2rem;
}

.fl-memo-card__side-text.fl-memo-card__side-text--text-xsmall .fl-memo-card__side-text-content > div {
  font-size: 1.5rem;
}

.fl-memo-card__side-text.fl-memo-card__side-text--text-xxsmall .fl-memo-card__side-text-content > div {
  font-size: 1rem;
}

@media (width <= 991px) and (orientation: landscape) {
  .fl-memo-card--both .fl-memo-card__side--question {
    width: 50%;
    height: auto;
    inset: 0 auto 0 0;
  }

  .fl-memo-card--both .fl-memo-card__side--answer {
    width: 50%;
    height: auto;
    inset: 0 0 0 auto;
  }

  .fl-memo-card--both .fl-memo-card__side--answer:before {
    content: " ";
    background: #ddd;
    width: 1px;
    height: 80%;
    position: absolute;
    top: 10%;
    left: 0;
  }
}

@media (width >= 992px) {
  .fl-memo-card {
    margin-top: 0;
  }

  .fl-memo-card__actions {
    flex-direction: column;
    margin-top: 0;
    font-size: 0;
    display: flex;
    position: absolute;
    top: 0;
    left: -2.5rem;
  }

  .fl-memo-card__tools {
    flex-direction: column;
    display: flex;
    top: 0;
    right: -3rem;
  }

  .fl-memo-card__tools .btn {
    margin-bottom: .25rem;
  }

  .fl-memo-card__container {
    max-width: 42rem !important;
    max-height: 24rem !important;
  }

  .fl-memo-controls {
    margin-left: auto;
    margin-right: auto;
    max-width: 42rem !important;
  }

  .fl-memo-card__side-text {
    font-size: 3rem;
  }

  .fl-memo-card__side.fl-memo-card__side--display-all .fl-memo-card__side-image {
    padding: 1rem;
    bottom: 0;
    right: 60%;
  }

  .fl-memo-card__side.fl-memo-card__side--display-all .fl-memo-card__side-text {
    top: 0;
    left: 40%;
  }

  .fl-memo-card.fl-memo-card--both .fl-memo-card__side.fl-memo-card__side--display-all .fl-memo-card__side-image {
    padding: 1rem;
    bottom: 0;
    right: 70%;
  }

  .fl-memo-card.fl-memo-card--both .fl-memo-card__side.fl-memo-card__side--display-all .fl-memo-card__side-text {
    top: 0;
    left: 30%;
  }
}

.fl-memo-session-end {
  font-size: 1.5rem;
}

.fl-memo-session-end small {
  opacity: .6;
}

.fl-memo-session-end p {
  margin-bottom: 1rem;
}

.fl-memo-session-end__next {
  margin-bottom: 1em;
  display: block;
}

.fl-memo-session-end__fresh-available span {
  margin-bottom: .5em;
  display: block;
}

@media (width <= 991px) and (orientation: landscape) {
  .fl-memo-session-end {
    font-size: 1.25rem;
  }
}

.fl-memo-cards__container {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.fl-memo-cards__question {
  flex: 1;
  position: relative;
}

.fl-memo-cards__buttons {
  flex-direction: row;
  place-content: center;
  padding: .5rem;
  display: flex;
}

.fl-memo-cards__buttons > .btn {
  margin-bottom: .5rem;
  margin-right: .5rem;
}

.fl-memo-cards__buttons > .btn:last-child {
  margin-right: 0;
}

@media (orientation: landscape) {
  .fl-memo-btn-grade {
    white-space: nowrap;
  }
}

@media (width >= 992px) {
  .fl-memo-cards__container {
    display: block;
  }

  .fl-memo-cards__question-width {
    margin-left: auto;
    margin-right: auto;
    width: 42rem !important;
  }

  .fl-memo-cards__question {
    margin-left: auto;
    margin-right: auto;
    width: 42rem !important;
    height: 24rem !important;
  }

  .fl-memo-controls {
    margin-left: auto;
    margin-right: auto;
    width: 42rem !important;
  }
}

.fl-memo-write__container {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.fl-memo-write__question {
  flex: 1;
  width: 100%;
  margin-bottom: .5rem;
  position: relative;
}

.fl-memo-write__input {
  width: 100%;
}

@media (width >= 992px) {
  .fl-memo-write__container {
    display: block;
  }

  .fl-memo-write__question {
    flex: 0;
    margin-left: auto;
    margin-right: auto;
    width: 42rem !important;
    height: 24rem !important;
  }

  .fl-memo-write__input {
    flex: 0;
    margin-left: auto;
    margin-right: auto;
    width: 42rem !important;
  }

  .fl-learn-card-wrong-display__title {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.fl-expandable-image {
  cursor: zoom-in;
  transition: opacity .25s;
}

.fl-expandable-image .close-button {
  cursor: pointer;
  display: none;
  position: fixed;
  top: 10px;
  right: 10px;
}

.fl-expandable-image .expand-button {
  z-index: 999;
  opacity: 0;
  justify-content: center;
  align-items: center;
  padding: 3px;
  transition: opacity .2s;
  position: absolute;
  top: 10px;
  right: 10px;
}

.fl-expandable-image svg {
  filter: drop-shadow(1px 1px 1px #00000080);
}

.fl-expandable-image svg path {
  fill: #fff;
}

.fl-expandable-image:hover .expand-button {
  opacity: 1;
}

.fl-expandable-image.expanded {
  z-index: 999999;
  opacity: 0;
  cursor: zoom-out;
  background: #000;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  inset: 0 auto auto 0;
  padding: 0 !important;
}

.fl-expandable-image.expanded > img {
  object-fit: contain;
  width: 100%;
  max-width: 1200px;
  max-height: 100%;
  margin: 0 auto;
}

.fl-expandable-image.expanded > .close-button {
  display: block;
}

.fl-expandable-image .expand-button svg {
  width: 20px;
  height: 20px;
}

.fl-expandable-image .expand-button path {
  fill: #fff;
}

.ProseMirror {
  word-wrap: break-word;
  white-space: pre-wrap;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  position: relative;
}

.ProseMirror [contenteditable="false"] {
  white-space: normal;
}

.ProseMirror [contenteditable="false"] [contenteditable="true"], .ProseMirror pre {
  white-space: pre-wrap;
}

.ProseMirror-gapcursor {
  pointer-events: none;
  display: none;
  position: absolute;
}

.ProseMirror-gapcursor:after {
  content: "";
  border-top: 1px solid #000;
  width: 20px;
  animation: 1.1s steps(2, start) infinite ProseMirror-cursor-blink;
  display: block;
  position: absolute;
  top: -2px;
}

@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}

.ProseMirror-hideselection ::selection {
  background: none;
}

.ProseMirror-hideselection * {
  caret-color: #0000;
}

.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

.ProseMirror {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25em;
  width: 100%;
  max-height: 7em;
  padding: .375em .75em;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  overflow: auto;
  outline: none !important;
}

.ProseMirror:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  box-shadow: 0 0 0 .2em #007bff40;
  outline: none !important;
}

.ProseMirror p {
  margin: 0;
}

.ProseMirror .tt-bg-yellow, .fl-tt-html .tt-bg-yellow {
  background: #fff2cb;
}

.ProseMirror .tt-bg-green, .fl-tt-html .tt-bg-green {
  background: #d3ebd3;
}

.ProseMirror .tt-bg-blue, .fl-tt-html .tt-bg-blue {
  background: #c3dbf9;
}

.ProseMirror .tt-bg-red, .fl-tt-html .tt-bg-red {
  background: #ffcdcd;
}

.tippy-box {
  background: #eee;
  border: 1px solid #dcdcdc;
  border-radius: .375em;
  padding: 0;
}

.tippy-content > div {
  flex-flow: row;
  display: flex;
}

.tippy-button {
  background: none;
  border: none;
  border-radius: 0;
  padding: .375em .5em;
  font-family: monospace;
  outline: none !important;
}

.tippy-button:first-child {
  border-top-left-radius: .375em;
  border-bottom-left-radius: .375em;
}

.tippy-button:last-child {
  border-top-right-radius: .375em;
  border-bottom-right-radius: .375em;
}

.tippy-button.is-active, .tippy-button:focus {
  background: #80bdff;
}

.tippy-button--bold {
  text-transform: uppercase;
  font-weight: bold;
}

.tippy-button--italic {
  text-transform: uppercase;
  font-style: italic;
}

.tippy-button--underline {
  text-transform: uppercase;
  text-decoration: underline;
}

.tippy-button--subscript, .tippy-button--superscript, .tippy-button--circle {
  padding-left: .375em;
  padding-right: .375em;
}

.tippy-button--circle > span {
  border: 1px solid #fff;
  border-radius: 1em;
  width: 1em;
  height: 1em;
  display: inline-block;
  position: relative;
  bottom: -.2em;
  box-shadow: 0 0 3px #a0a0a0;
}

.tippy-button--circle-yellow {
  background: #fff2cb;
}

.tippy-button--circle-green {
  background: #d3ebd3;
}

.tippy-button--circle-blue {
  background: #c3dbf9;
}

.tippy-button--circle-red {
  background: #ffcdcd;
}

.fl-memo-card__side-text.fl-memo-card__side-text--text-small .fl-memo-card__side-text-content, .fl-memo-card__side-text.fl-memo-card__side-text--text-xsmall .fl-memo-card__side-text-content, .fl-memo-card__side-text.fl-memo-card__side-text--text-xxsmall .fl-memo-card__side-text-content {
  background: none !important;
}

.fl_add-card-side .ProseMirror {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  padding-bottom: .1rem;
  padding-left: 0;
  padding-right: 0;
  line-height: 1.2;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.fl_add-card-side .ProseMirror:focus {
  box-shadow: none;
  border-color: #80bdff;
  outline: none !important;
}

.fl_add-card-side .ProseMirror::placeholder {
  opacity: .6;
}

.fl_add-card-side .ProseMirror.is-invalid {
  border-color: #dc3545;
}

.fl_add-card-side__buttons {
  margin-top: -.3rem;
}

.fl_add-card-side__buttons .btn {
  opacity: .7;
  padding-left: .5rem;
  padding-right: .5rem;
  line-height: 1;
}

.fl_add-card-side__buttons .btn:hover, .fl_add-card-side__buttons .btn:focus, .fl_add-card-side__buttons .btn:active {
  opacity: 1;
}

.fl_add-card-side__audio, .fl_add-card-side__image {
  text-align: center;
  flex: 1;
  height: 4em;
  position: relative;
}

.fl_add-card-side__image > img {
  max-width: 100%;
  max-height: 100%;
}

.fa-stack.fa-stack--width-1_5em {
  width: 1.5em;
}

.row.row-gap-5px {
  margin-left: -5px;
  margin-right: -5px;
}

.row.row-gap-5px > div[class^="col-"], .row.row-gap-5px > div[class*=" col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.btn-pl {
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: .2em;
  padding: 1em;
  font-size: 90%;
  line-height: 1.2;
  text-decoration: none;
  display: inline-block;
}

.btn-pl > .icon {
  height: 2em;
  padding: 0 1em 1em;
  display: block;
}

.btn-pl > .glyphicon {
  color: #228b22;
  padding: .3em 1em .5em;
  font-size: 2em;
  line-height: 1;
  display: block;
}

.btn-pl > .text {
  font-weight: bold;
}

.btn-pl:hover, .btn-pl:focus {
  color: #555;
  border-bottom: 1px solid #228b22;
  text-decoration: none;
}

.form-options-widget-header {
  color: #31708f;
  margin-bottom: .5em;
}

.child-padding-05em > * {
  padding: .5em;
}

.radio-hidden > input[type="radio"] {
  display: none;
}

.btn-fc {
  color: #7b7b7b;
  text-align: center;
  border: none;
  border-radius: 0;
  padding-top: 1.5em;
  padding-bottom: .5em;
}

.btn-fc .btn-text {
  line-height: 2;
  display: block;
}

.pbar {
  height: 1.7em;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ededed", endColorstr= "#f3f3f3", GradientType= 0);
  background: linear-gradient(#ededed 0%, #f5f5f5 7%, #f3f3f3 100%);
  border-top: 1px solid #e4e4e4;
  border-right: 1px solid #eee;
  border-radius: 2px;
  padding: 0;
  font-size: 90%;
  font-weight: bold;
  position: relative;
}

.pbar .pbar-bar {
  border-radius: 2px;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pbar .pbar-val {
  text-align: center;
  height: 100%;
  display: block;
  position: absolute;
}

.pbar .pbar-bar {
  background: #5bc0de;
  border-bottom: 1px solid #31b0d5;
}

.pbar .pbar-val {
  color: #31b0d5;
}

.pbar .pbar-bar > .pbar-val {
  color: #fff;
}

.pbar.pbar-green .pbar-bar {
  background: #16a765;
  border-bottom: 1px solid #107a4a;
}

.pbar.pbar-green .pbar-val {
  color: #107a4a;
}

.pbar.pbar-green .pbar-bar > .pbar-val {
  color: #fff;
}

.pbar.pbar-red .pbar-bar {
  background: #db6e60;
  border-bottom: 1px solid #d24836;
}

.pbar.pbar-red .pbar-val {
  color: #d24836;
}

.pbar.pbar-red .pbar-bar > .pbar-val {
  color: #fff;
}

.pbar-200, .pbar-200 .pbar-val {
  width: 200px;
}

table.col2 {
  width: 100%;
}

table.col2 td {
  vertical-align: top;
}

table.col2 td:first-child, table.col2 td:last-child {
  width: 50%;
}

.list-custom-icons > li {
  min-height: 2em;
  padding-left: 2.5em;
  position: relative;
}

.list-custom-icons > li > .list-icon {
  text-align: right;
  width: 2.5em;
  display: inline-block;
  position: absolute;
  top: 0;
  left: -.5em;
  overflow: visible;
}

.input-radio-decorator {
  cursor: pointer;
}

.input-radio-decorator > input {
  display: none;
}

.input-radio-decorator > span:before {
  content: "⦾";
  color: #08c;
  vertical-align: sub;
  margin-right: .4em;
  font-family: flashcards;
  font-size: 130%;
  display: inline-block;
}

.input-radio-decorator > input:checked + span:before {
  content: "⦿";
}

.input-radio-decorator:hover > span:before {
  text-shadow: 0 0 1px #08c;
}

.input-check-decorator {
  cursor: pointer;
}

.input-check-decorator > input {
  display: none;
}

.input-check-decorator > span:before {
  content: "☐";
  color: #08c;
  vertical-align: sub;
  margin-right: .4em;
  font-family: flashcards;
  font-size: 130%;
  display: inline-block;
}

.input-check-decorator > input:checked + span:before {
  content: "☑";
}

.input-check-decorator:hover > span:before {
  text-shadow: 0 0 1px #08c;
}

.white-container {
  background: #fff;
  padding: 2%;
}

.white-container-title-lg {
  color: #fff;
  background: #8a9ebf;
  padding: 32px 22px;
}

.white-container-title-lg > h1 {
  margin: 0;
  font-size: 24px;
}

@media (width <= 767px) {
  .white-container-title-lg {
    padding: 1em;
  }

  .white-container-title-lg > h1 {
    font-size: 18px;
  }
}

.info-nav-list {
  background: #fff;
  padding: 2%;
}

.info-nav-list strong {
  color: #979797;
  padding: 0 15px 5px;
  font-size: 18px;
  font-weight: normal;
  display: block;
}

.info-nav-list .nav-pills > li > a {
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}

.info-nav-list ul {
  margin-bottom: 2em;
  margin-left: 0;
}

.info-nav-header {
  text-align: center;
  background: #25b7d3;
  padding: 10px;
}

.info-nav-header img {
  width: 90px;
}

@keyframes scrollBackgroundX {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 20px 0;
  }
}

.btn-in-progress {
  background: url("btn-back-anim.3df7afc5.png") repeat-x;
  animation: 1s linear infinite scrollBackgroundX;
}

.form-footer {
  margin-top: 1em;
}

.form-footer > .btn {
  margin-left: .3em;
  margin-right: .3em;
}

.btn-icon {
  margin-right: .35em;
}

.alert-panel {
  background-image: url("alert-80.bd70d370.svg");
  background-position: 25px;
  background-repeat: no-repeat;
  min-height: 100px;
  padding-left: 130px;
  display: block;
}

.help-content {
  padding-left: 5%;
  padding-right: 5%;
}

.help-content h2 {
  margin: 1em 0;
}

.right-wing:before, .right-wing:after {
  content: " ";
  display: table;
}

.right-wing:after {
  clear: both;
}

.right-wing > .wing {
  float: right;
}

.right-wing > .content {
  overflow: auto;
}

.btn-oauth-facebook, .btn-oauth-google {
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 0;
  width: 250px;
  padding: 1px;
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
}

.btn-oauth-facebook:before, .btn-oauth-google:before, .btn-oauth-facebook:after, .btn-oauth-google:after {
  content: " ";
  display: table;
}

.btn-oauth-facebook:after, .btn-oauth-google:after {
  clear: both;
}

.btn-oauth-facebook .logo, .btn-oauth-google .logo {
  float: left;
  background: #fff 0 0 / 100% 100%;
  width: 3em;
  height: 3em;
}

.btn-oauth-facebook .btn-text, .btn-oauth-google .btn-text {
  line-height: 3;
}

.btn-oauth-facebook:hover, .btn-oauth-google:hover, .btn-oauth-facebook:focus, .btn-oauth-google:focus {
  color: #000;
}

.btn-oauth-google {
  background-color: #4285f4;
}

.btn-oauth-google .logo {
  background-image: url("btn_google_light_normal.51c39b4e.svg");
}

.btn-oauth-facebook {
  background-color: #3b579d;
}

.btn-oauth-facebook .logo {
  background-image: url("FB-f-Logo__blue_144.b7d80713.png");
}

.fl-va-block {
  white-space: nowrap;
  font-size: 0;
}

.fl-va-block > * {
  vertical-align: middle;
}

.fl-va-helper {
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.bg-f1f1f1 {
  background-color: #f1f1f1;
}

.border-1-ddd {
  border: 1px solid #ddd;
}

.fl-btn-green {
  color: #fff;
  background-color: #00b042;
  border-color: #00b042;
}

.fl-btn-green:focus, .fl-btn-green.focus {
  color: #fff;
  background-color: #007d2f;
  border-color: #003112;
}

.fl-btn-green:hover {
  color: #fff;
  background-color: #007d2f;
  border-color: #00732b;
}

.fl-btn-green:active, .fl-btn-green.active, .open > .dropdown-toggle.fl-btn-green {
  color: #fff;
  background-color: #007d2f;
  background-image: none;
  border-color: #00732b;
}

.fl-btn-green:active:hover, .fl-btn-green.active:hover, .open > .dropdown-toggle.fl-btn-green:hover, .fl-btn-green:active:focus, .fl-btn-green.active:focus, .open > .dropdown-toggle.fl-btn-green:focus, .fl-btn-green:active.focus, .fl-btn-green.active.focus, .open > .dropdown-toggle.fl-btn-green.focus {
  color: #fff;
  background-color: #005921;
  border-color: #003112;
}

.fl-btn-green.disabled:hover, .fl-btn-green[disabled]:hover, fieldset[disabled] .fl-btn-green:hover, .fl-btn-green.disabled:focus, .fl-btn-green[disabled]:focus, fieldset[disabled] .fl-btn-green:focus, .fl-btn-green.disabled.focus, .fl-btn-green[disabled].focus, fieldset[disabled] .fl-btn-green.focus {
  background-color: #00b042;
  border-color: #00b042;
}

.fl-btn-green .badge {
  color: #00b042;
  background-color: #fff;
}

.fl-hover-green {
  border-bottom-style: solid !important;
  border-bottom-width: 2px !important;
}

.fl-hover-green:hover {
  border-bottom-color: #00b042 !important;
}

.fl-hover-green:focus, .fl-hover-green:hover:focus {
  border-bottom-color: #007d2f !important;
}
/*# sourceMappingURL=theme.css.map */
