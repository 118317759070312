.fl-inpage-modal {
  box-shadow: 0 0 7px #999;
  max-width: 42rem;
  margin: 0 auto;
  padding: 1rem 0;
  background: #fff;
}

.fl-inpage-modal__head {
  text-align: center;
  padding: 0 3rem;
  margin-bottom: 1rem;

  > span {
    font-size: 140%;
    line-height: 1.7;
  }

  > small {
    opacity: .7;
  }
}

.fl-inpage-modal__body {
  padding: 0 2rem;
}

.fl-inpage-modal__footer {
  margin-top: 1.5rem;
}