@media (orientation: portrait) {
  .fl-pack-embed__card-side--image-and-text {
    flex-direction: column;

    .fl-pack-embed__card-image {
      width: 100%;
      padding-right: 0;
    }

    .fl-pack-embed__card-text {
      width: 100%;
      text-align: center;
    }
  }
}