.fl-dashboard-memo-grid--grid {
  .fl-dashboard-memo-grid--table-only {
    display: none !important;
  }
}

.fl-dashboard-memo-grid--grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-left: .5rem;

  > div {
    width: 50%;
    flex-grow: 0;
    padding: 0 .5rem .5rem 0;
  }

  .fl-dashboard-memo-grid__card-placeholder {
    height: 15.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .fl-dashboard-memo-grid__card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    margin-right: -.5rem;
    margin-top: -.25rem;
  }

  .fl-dashboard-memo-grid__card-play {
    margin-top: 1rem;
  }


  .fl-dashboard-memo-grid__card-metrics {
    display: table;
  }

  .fl-dashboard-memo-grid__card-metric {
    display: table-row;
  }

  .fl-dashboard-memo-grid__card-metric span {
    display: table-cell;
  }

  .fl-dashboard-memo-grid__card-metric em {
    display: table-cell;
    text-align: right;
    padding-left: 1rem;
  }

  .fl-dashboard-memo-grid__add-card {
    text-align: center;
    border-top: solid 1px fade(#c4c5c6, 30%);
  }
}

@media (min-width: @screen-md-min) {
  .fl-dashboard-memo-grid--grid {
    margin-left: -.5rem;
    margin-right: -.5rem;
    //margin-bottom: -1rem;

    > div {
      width: 33.3%;
      padding: 0 .5rem;
      margin-bottom: 1rem;
    }
  }
}
