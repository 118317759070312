.fl-memo-heatmap__area {
  position: relative;
  margin: 1.3rem auto 0;
}

.fl-memo-heatmap__area-cell {
  position: absolute;
}

.fl-memo-heatmap__area-cell--today {
  border: solid 1px #7d7d7d;
}

.fl-memo-heatmap__area-month {
  position: absolute;
  top: -1.3rem;
  font-size: 80%;
}

.fl-memo-heatmap__legend {
  margin: 1em 0 0;
  padding: 0;
  text-align: center;
  font-size: 85%;
}

.fl-memo-heatmap__legend > li {
  display: inline-block;
  margin: 0 1rem;
}

.fl-memo-heatmap__legend-value {
  color: #007cff;
}

