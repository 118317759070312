@media (min-width: @screen-md-min) {
  .pack-header__title {
    > h1 {
      word-break: normal;
    }
  }

  .pack-header__buttons {
    white-space: nowrap;
  }

  .pack-games-buttons {
    .btn {
      font-size: 150%;
      min-width: 150px;
    }
  }
}
