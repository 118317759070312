.ProseMirror {
  position: relative;
}

.ProseMirror {
  word-wrap: break-word;
  white-space: pre-wrap;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
}

.ProseMirror [contenteditable="false"] {
  white-space: normal;
}

.ProseMirror [contenteditable="false"] [contenteditable="true"] {
  white-space: pre-wrap;
}

.ProseMirror pre {
  white-space: pre-wrap;
}

.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}

.ProseMirror-gapcursor:after {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  border-top: 1px solid black;
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}

@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}

.ProseMirror-hideselection *::selection {
  background: transparent;
}

.ProseMirror-hideselection *::-moz-selection {
  background: transparent;
}

.ProseMirror-hideselection * {
  caret-color: transparent;
}

.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}

.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0
}

.ProseMirror {
  max-height: 7em;
  overflow: auto;

  display: block;
  width: 100%;

  outline: none !important;
  padding: 0.375em 0.75em;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: none !important;
    box-shadow: 0 0 0 0.2em rgb(0 123 255 / 25%);
  }

  p {
    margin: 0;
  }
}

.ProseMirror,
.fl-tt-html {
  .tt-bg-yellow {
    background: #fff2cb;
  }

  .tt-bg-green {
    background: #d3ebd3;
  }

  .tt-bg-blue {
    background: #c3dbf9;
  }

  .tt-bg-red {
    background: #ffcdcd;
  }
}


.tippy-box {
  background: #eeeeee;
  padding: 0;

  border: solid 1px #dcdcdc;
  border-radius: .375em;
}

.tippy-content > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.tippy-button {
  font-family: monospace;

  border: none;
  background: none;
  border-radius: 0;
  padding: .375em .5em;
  outline: none !important;

  &:first-child {
    border-top-left-radius: .375em;
    border-bottom-left-radius: .375em;
  }

  &:last-child {
    border-top-right-radius: .375em;
    border-bottom-right-radius: .375em;
  }

  &.is-active,
  &:focus {
    background: #80bdff;
  }
}

.tippy-button--bold {
  font-weight: bold;
  text-transform: uppercase;
}

.tippy-button--italic {
  font-style: italic;
  text-transform: uppercase;
}

.tippy-button--underline {
  text-decoration: underline;
  text-transform: uppercase;
}

.tippy-button--subscript,
.tippy-button--superscript {
  padding-left: .375em;
  padding-right: .375em;
}

.tippy-button--circle {
  padding-left: .375em;
  padding-right: .375em;

  > span {
    display: inline-block;
    height: 1em;
    width: 1em;
    border-radius: 1em;
    border: solid 1px white;
    box-shadow: 0 0 3px #a0a0a0;

    position: relative;
    bottom: -0.2em;
  }
}

.tippy-button--circle-yellow {
  background: #fff2cb;
}

.tippy-button--circle-green {
  background: #d3ebd3;
}

.tippy-button--circle-blue {
  background: #c3dbf9;
}

.tippy-button--circle-red {
  background: #ffcdcd;
}
