@media (min-width: @screen-md-min) {
  .fl-pack-main-cols {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: auto;

    margin-left: -.3rem;
    margin-right: -.3rem;
    margin-bottom: 1.5rem;

    > div {
      padding-left: .3rem;
      padding-right: .3rem;
    }
  }

  .fl-pack-main-cols__widgets {
    flex: 1;

  }

  .fl-pack-main-cols__embed {
    flex: 1.5;
  }

  .fl-pack-main-cols__modes {
    flex: .5;
  }
}