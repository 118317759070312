@media (min-width: @screen-sm-min) {
  .fl-form-cards__back-side--sm-bl {
    border-left: solid 1px #ddd;
  }

  .fl-form-cards__delete {
    display: none;
  }

  .fl-form-cards__li:hover {
    .fl-form-cards__delete {
      display: block;
    }
  }
}
