.fl-memo-session-end {
  font-size: 1.5rem;

  small {
    opacity: .6;
  }

  p {
    margin-bottom: 1rem;
  }
}

.fl-memo-session-end__next {
  display: block;
  margin-bottom: 1em;
}

.fl-memo-session-end__fresh-available {
  span {
    display: block;
    margin-bottom: .5em;
  }
}

@import "fl-memo-session-end--sm-landscape";