.fl-form-select-image-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.fl-form-select-image-gallery__image {
  margin: 1rem .5rem;

  img {
    max-height: 10rem;
    max-width: 10rem;
  }

  button {
    min-height: 10rem;
    min-width: 10rem;
    padding: 0;
    margin: 0;
    border: solid 2px transparent;
    background: none;

    &:hover,
    &:active,
    &:focus {
      border-color: #ffdc60;
    }
  }
}