@fl-explorer-content-border: solid 1px #dddddd;
@fl-explorer-content-border-radius: 4px;
@fl-explorer-card-shadow: 2px 2px 2px #ddd;

.fl-explorer-menu__label {
  background-color: #f1f1f1;
  padding: 0.25rem 0.5rem;
  margin-bottom: .5rem;
  border-radius: 4px;
  margin-top: 2rem;
}

.fl-explorer-menu__lang-select {
  text-transform: capitalize;
}

.fl-explorer-menu__label,
.fl-explorer-tree__children {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.fl-explorer-tree {
}

.fl-explorer-tree__node-expander {
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  outline: none !important;

  position: absolute;
  left: 0;
  width: 1rem;
  line-height: 1rem;
  margin-top: .2rem;
}

.fl-explorer-tree__node {
}

.fl-explorer-tree__node--active > .fl-explorer-tree__node-label {
  color: #007cff !important;
}

.fl-explorer-tree__node-label {
  cursor: pointer;
}

.fl-explorer-tree__children {
  position: relative;
  padding-left: 1.4rem;
}

.fl-explorer-tree__node {
  > .fl-explorer-tree__children {
    display: none;
  }

  &.fl-explorer-tree__node--open {
    > .fl-explorer-tree__node-expander {
      transform: rotate(90deg);
    }

    > .fl-explorer-tree__children {
      display: inherit;
    }
  }
}

.fl-explorer-content {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.fl-explorer-content--loading {
  opacity: .2;
}

.fl-explorer-content__title {
  opacity: .6;
}

.fl-explorer-content__title-gutter {
  padding: 0 .3rem;
}

.fl-explorer-content__section-title {
  margin-top: 2rem;
  margin-bottom: .4rem;
}

.fl-explorer-pack-item {
  border: @fl-explorer-content-border;
  border-radius: @fl-explorer-content-border-radius;
  margin-bottom: .5rem;
  box-shadow: @fl-explorer-card-shadow;
}

.fl-explorer-pack-item__thumb {
  text-align: center;
  height: 100%;
  float: right;

  > img {
    height: 4rem;
    width: 4rem;
  }
}

.fl-explorer-pack-item__card {
  p {
    margin: 0;
  }
}

.fl-explorer-pack-item__count {
  color: #8b8b8b;
}

.fl-explorer-pack-item__author {
  color: #007cff !important;

  > img {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    vertical-align: text-bottom;
  }
}

.fl-explorer-pack-item__title {
  margin-top: .5rem;
  //margin-bottom: .5rem;
}

.fl-explorer-pack-item__card {
  position: relative;
  border-left: solid 1px #cacaca;
}

.fl-explorer-pack-item__card-face {
  font-size: 12px;
  font-weight: 300;
  color: #8b8b8b;
}

.fl-explorer-pack-item__card-back {
  font-size: 12px;
  font-weight: 300;
  color: #cacaca;
  max-height: 4rem;
  overflow: hidden;
}

.fl-explorer-pack-item__card-back--fade {
  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 1.5rem;

    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
}

.fl-explorer-pack-item__card-image {
  img {
    max-height: 5rem;
    max-width: 5rem;
  }

  opacity: .7;
}

.fl-explorer-collection-list {
  padding: 0;
  margin: 0;
}

.fl-explorer-collection-item {
  border: @fl-explorer-content-border;
  margin-right: 1rem;
  margin-bottom: .7rem;
  padding: .5rem 1rem;
  border-radius: @fl-explorer-content-border-radius;
  box-shadow: @fl-explorer-card-shadow;
}

.fl-explorer-collection-item__count {
  color: #8b8b8b;
}

.fl-explorer-collection-item__author,
.fl-explorer-pack-item__author {
  font-size: 12px;
}

.fl-explorer-collection-item__author {
  color: #007cff !important;

  > img {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    vertical-align: text-bottom;
  }
}

.fl-explorer-collection-item__count {
  text-transform: lowercase;
}

.fl-explorer-collection-item__title {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.fl-explorer-content__empty {
  text-align: center;
  opacity: .5;
  font-size: 130%;

  p {
    line-height: 2.5;
    margin: 0 0 .5em;
  }

  .input-group {
    justify-content: center;
  }

  input[type=text] {
    width: 15em;
    flex-grow: 0;
  }
}