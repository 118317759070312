.fl-spell-game__field {
}

.fl-spell-game__question {
  max-width: 42rem;
  margin: 0 auto;

  position: relative;
  background: #fff;
  box-shadow: 1px 1px 5px rgba(51, 51, 51, 0.3);
}

.fl-spell-game__question-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 1rem;
}

.fl-spell-game__question-audio {
  button {
    font-size: 300%;
  }
}

.fl-spell-game__question-text {
  flex: 1;
  margin-left: 1rem;

  p {
    margin-bottom: 0;
  }
}