.card-inst {
  display: inline-block;
  vertical-align: middle;
  width: 500px;
  height: 250px;
  border-radius: .5em;
  border: solid 1px #4f6577;
  box-shadow: 1px 1px 5px #bbb;
  position: relative;

  &.card-green {
    border-color: #b6d588;
    background-color: #fffffc;
  }

  &.card-blue {
    border-color: #5bc9dd;
    background-color: #fafeff;
  }

  &.card-orange {
    border-color: #ddc059;
    background-color: #fafeff;
  }
}

.card-def {
  height: 100%;
  width: 100%;
  padding-top: 1.5em;
  padding-left: 1em;
  padding-right: 1em;

  .card-def-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 35px;
    max-height: 100%;
  }
}

table.table-of-def {
  width: 100%;

  tr > th {
    border: none;
  }

  tr > td {
    border: none;
  }

  td {
    padding: .3em 1em;

    > input {

    }
  }
}

.pack-card {
  background: #fcfcfc;
  border-radius: 1px;
  box-shadow: -1px 0 1px #ddd;
  padding: 5px 15px;
  font-size: 14px;
  margin-bottom: .9em;

  > h2 {
    margin-top: 0;
    font-size: 20px;

    > a {
      text-decoration: none;
      color: #3a3a3a;
    }
  }

  .pack-card-attrib {
    font-size: 90%;
    margin-right: 1em;

    > .pack-card-attrib-label {
      font-style: italic;
      color: #666;
    }
  }
}

.pack-card-small {
  background: #fcfcfc;
  border-radius: 1px;
  box-shadow: -1px 0 1px #ddd;
  padding: 5px 15px;
  font-size: 14px;
  //	margin-bottom: .9em;
  color: #555656;

  > h2 {
    margin: 0;
    font-size: 1.3rem;
    line-height: 1.7;
    opacity: .9;

    > a {
      text-decoration: none;
      color: #3a3a3a;
    }
  }
}

.pack-meta-buttons {
  margin-bottom: 0;
}

.play-progress {
  .clearfix();
  line-height: 1.5;
}

.play-progress-label {
  display: block;
  float: left;
  padding-right: .5em;
}

.play-progress-value {
  display: block;
  position: relative;
  overflow: hidden;
}

.play-header-progress {
  overflow: auto;
}

.pbar-table {
  width: 100%;

  .w1p {
    width: 1%;
    padding: .3em .7em;
    text-align: right;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  tr:first-child {
    .w1p {
      padding-top: 0;
    }
  }
}

.card-card-qa {
  line-height: 1.2;

  .card-face-text {
    display: block;
    padding: 0;
    margin-bottom: .5em;
    color: #111;
    opacity: .8;
  }

  .card-back-text {
    color: #9ea3a4;
  }

  &.limit-text {
    @limit-height: 3.6em;
    @limiter-height: 1.2em;

    .card-face-text {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .card-back-text {
      color: #9ea3a4;
      max-height: @limit-height;
      display: inline-block;
      overflow: hidden;
      position: relative;

      &:before {
        display: block;
        content: " ";
        height: @limiter-height;
        top: @limit-height - @limiter-height;
        position: absolute;
        width: 100%;

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
      }
    }
  }
}

.course-card-small {
  background: #fcfcfc;
  border-radius: 1px;
  color: #555656;
  padding: 5px 15px;
  font-size: 14px;
  margin-bottom: .9em;

  > h2 {
    margin-top: 0;
    font-size: 20px;

    > a {
      text-decoration: none;
      color: #3a3a3a;
    }
  }
}

.move-pack-to-course-header {
  h1 {
    margin-top: 0;
  }
}

.move-pack-to-course-packs {
  > span {
    color: #989898;
    font-size: 14px;
  }

  > ul {
  }
}

.move-pack-to-course-form {
  ul {
    > li.adder {
      span {
        display: block;
      }

      padding-left: 26px;
    }
  }

  .empty-courses-message {
    display: none;

    > p {
      padding: 40px 15%;
      color: #c5c5c5;
      font-size: 24px;
      text-align: center;
    }
  }

  &.no-courses {
    .empty-courses-message {
      display: block;
    }

    .move-pack-to-course-form-content {
      display: none;
    }
  }
}

.move-pack-to-course-form {
  h3 {
    margin-top: 2em;
  }
}

.memtrick-pack-list {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: .5rem;
}

.memtrick-pack-list__item {
  display: block;
  text-decoration: none !important;
  border: solid 1px #e1e1e1;
  margin-bottom: .5rem;
}


.pack-and-some-cards-list {
  @border-color: #f1f1f1;

  .pack-and-some-cards {
    padding-bottom: .4em;
    border-bottom: solid 1px @border-color;
    padding-top: .4em;
    overflow: hidden;
  }

  .col-bordered {
    position: relative;

    &:before {
      position: absolute;
      content: " ";
      left: 0;
      top: 0;
      width: 0;
      height: 500px;
      border-left: solid 1px @border-color;
    }
  }
}

@media (max-width: @screen-xs-max) {
  .pack-and-some-cards-list {
    .pack-and-some-cards .some-cards {
      display: none;
    }
  }
}

.pack-and-some-cards {
  background: #fff;
  margin-left: 0;
  margin-right: 0;

  > div:first-child {
    padding-left: 0;
  }

  > div:last-child {
    padding-right: 0;
  }

  .pack-card-small {
    box-shadow: none;
  }

  .card-card-qa {
    position: relative;
    font-size: 90%;
    padding: 9px 15px;
  }
}