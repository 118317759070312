.select-test-variants {
  text-align: center;
  margin-top: 1em;

  .btn {
    margin-top: .5em;
    margin-bottom: .5em;
  }

  .btn.active {
    background: fade(#0062cc, 10%);
  }
}

.select-test-submit {
  margin-top: 1em;
  padding: 2%;
  background: #fff;
  font-size: 120%;

  .input-short-num {
  }
}

.select-test-opt {
  margin-bottom: 1em;
  text-align: center;
}

.select-test-opt-label {
  color: #b1b1b1;
}

.test-pack-result {
  margin-bottom: 2em;
  background: #fff;
  padding: 2%;

  .result-message,
  .result-percent {
    font-size: 2em;
  }

  .result-message {
    color: #7e7e7e;
  }

  .test-pack-result-face {
    margin-bottom: .8em;

    @media (max-width: @screen-xs-max) {
      text-align: center;
    }
  }

  .result-face {
    display: inline-block;
    width: 3em;
    height: 3em;
    margin-right: .5em;
    vertical-align: top;
  }
}

.test-pack-match {
  .match-question {
    margin-bottom: 2em;

    select {
      margin-top: -6px;
      margin-right: .5em;
    }

    &.wrong,
    &.wrong + li {
      border-left: solid 2px #db6e60;
    }

    &.correct,
    &.correct + li {
      border-left: solid 2px #34ae72;
    }
  }

  .match-answer {
    margin-bottom: 2em;

    &.used {
      opacity: .5;
    }
  }

  .match-check {
  }

  .answer-correct-title {
    color: #989898;
  }

  .final-answer {
    position: relative;
    top: -2.5em;

    .expected {
      color: #0088cc;
    }

    .correct {
      color: #34ae72;
    }

    .wrong {
      color: #db6e60;
    }
  }

  .test-pack-match-table {
    padding: 2%;
    background: #fff;
  }
}

.test-pack-table-base() {
  margin-bottom: 2em;
  background: #fff;
  padding: 2%;

  li {
    margin-bottom: 2em;

    &.wrong,
    &.correct {
      margin-bottom: 0;

      .variants {
        display: none;
      }
    }

    &.wrong,
    &.wrong + li {
      border-left: solid 2px #db6e60;
    }

    &.correct,
    &.correct + li {
      border-left: solid 2px #34ae72;
    }
  }
}

.test-pack-check-table {
  .test-pack-table-base();

  li {
    &.wrong-variant {
      .variant {
        text-decoration: line-through;
      }
    }
  }

  .variant-prefix {
    color: #989898;
  }

  .final-answer {
    .expected-title {
      color: #989898;
      margin-top: 1em;
      display: block;
    }

    .expected {
      color: #0088cc;
    }

    .correct {
      color: #34ae72;
      display: block;
    }

    .wrong {
      color: #db6e60;
      display: block;
    }
  }
}

.test-pack-variants-table {
  .test-pack-table-base();

  .final-answer {
    .expected-title {
      color: #989898;
      margin-top: .5em;
      display: block;
      margin-bottom: .5em;
    }

    .expected {
      color: #0088cc;
    }

    .correct,
    .expected-correct {
      color: #34ae72;
      display: block;
    }

    .wrong {
      color: #db6e60;
      display: block;
    }
  }
}

.test-pack-write-table {
  .test-pack-table-base();

  .final-answer {
    .expected-title {
      color: #989898;
      margin-top: .5em;
      display: block;
      margin-bottom: .5em;
    }

    .expected {
      color: #0088cc;
    }

    .correct,
    .expected-correct {
      color: #34ae72;
      display: block;
    }

    .wrong {
      color: #db6e60;
      display: block;
    }
  }
}
