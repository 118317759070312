@fl-memo-startup-title-color: #007cff;

.fl-memo-startup {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  padding-left: 10%;
  padding-right: 10%;

  a {
    color: @fl-memo-startup-title-color;
  }

  p {
    margin: 0;
  }
}

.fl-memo-startup__title {
  color: @fl-memo-startup-title-color;
  margin: 0;
  font-size: 1.9em;
}

.fl-memo-startup__text {
}

.fl-memo-startup__text--smaller {
  font-size: 95%;
}

.fl-memo-startup__text--fade {
  color: #9b9c9e;
}

.fl-memo-startup__button {
}