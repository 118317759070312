.fl-landing {
  color: #3e3e3e;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1rem;
}

.fl-landing__title-image img {
  max-width: 100%;
}

@media (min-width: @screen-md-min) {
  .fl-landing__title-image img {
    max-width: 40vw;
  }
}

.fl-landing__icon {
  font-size: 3.5em;
  display: block;
}

.fl-landing__icon--fade {
  opacity: .5;
}

.fl-landing__icon--blue {
  color: #4aa0fd;
  opacity: .9;
}

.fl-landing--alternate {
  background: #ddd;
}

.fl-landing--h1 {
  font-size: 2.2rem;
  color: #000;
  font-weight: 300;
  margin: 0;
}

.fl-landing--h2 {
  font-size: 1.5rem;
  color: #111;
  font-weight: 300;
  margin: 0;
}

.fl-landing--h3 {
  font-size: 1.4rem;
  color: #111;
  font-weight: 300;
  margin: 0;
}

.fl-landing--last-chance i {
  margin-bottom: 1rem;
}

.fl-landing--last-chance span {
  font-weight: bold;
}

.fl-landing .social-providers {
  margin: 0;
}

.fl-landing .social-providers .btn {
  display: inline-block;
  width: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 1rem;
}

.fl-landing--reg {
  font-size: 1.5rem;
}

.fl-landing--reg-fade {
  opacity: .6;
}