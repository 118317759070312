.fl-pack-header {
  margin: -1rem -1rem auto;
  position: relative;
  padding: 1em;
}

.fl-pack-header__meta {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: .5rem;
}

.fl-pack-header__meta-author {
  display: inline;
  white-space: nowrap;

  .memtrick-person-link {
    color: #007cff;
  }
}

.fl-pack-header__title {
  word-break: break-word;
  margin: 0 3rem 0 0;
  font-size: 1.5rem;
}

.fl-pack-header__description {
  margin: 0;
  font-size: 90%;
  opacity: .7;
}

.fl-pack-header__actions-toggle {
  position: absolute;
  right: 0;
  top: 3rem;
  display: block;

  .fa-chevron-up {
    display: none;
  }
}

.fl-pack-header__actions {
  display: none;

  flex-wrap: wrap;
  margin-top: .5rem;

  .btn-style() {
    border-radius: 1em;
    box-shadow: 0 0 0.2rem rgba(110, 110, 110, .5);
    margin: .25em;

    &:focus {
      box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5);
    }
  }

  > .btn {
    .btn-style();
  }

  > .dropdown {
    display: inline-block;

    > .btn {
      .btn-style();
    }
  }
}

.fl-pack-header--actions-open {
  .fl-pack-header__actions-toggle {
    .fa-chevron-up {
      display: inherit !important;
    }

    .fa-chevron-down {
      display: none;
    }
  }

  .fl-pack-header__actions {
    display: flex;
  }
}

.fl-pack-header__thumb {
  display: block;
  max-width: 120px;
  max-height: 120px;
  margin: 0 auto 1rem;
}

.fl-pack-header__thumb img {
  max-width: 100%;
  max-height: 100%;
}

@import "fl-pack-header--sm";
@import "fl-pack-header--md";