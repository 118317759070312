/* Sticky footer styles
-------------------------------------------------- */
html.sticky-f {
	position: relative;
	min-height: 100%;

	.body-size(@footerW) {
		/* Margin bottom by footer height */
		margin-bottom: @footerW;
		padding-bottom: 5em;

		.footer {
			position: absolute;
			bottom: 0;
			width: 100%;
			/* Set the fixed height of the footer here */
			height: @footerW;
		}
	}

	body {
		.body-size(140px);
	}
}

