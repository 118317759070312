.fl-memo-btn-grade {
  text-align: center;
  background: #fff;
  border-color: #dadada;
  position: relative;
}

.fl-memo-btn-grade__shortcut {
  position: absolute;
  padding: 0 .5em;
  font-size: 80%;
  left: 0;
  top: 0;
  background: #b1aadd;
  display: none;
  z-index: 1;
}

.fl-memo-btn-grade__next {
  display: block;
  font-size: 60%;
  opacity: .7;
}

.mixin-memo-btn-grade(@content, @color) {
  &:before {
    content: @content;
    color: @color;
    font-weight: bold;
    font-size: 1.2em;
  }
}

.fl-memo-btn-grade--H {
  .mixin-memo-btn-grade("🙁", #ce7c7c);
}

.fl-memo-btn-grade--M {
  .mixin-memo-btn-grade("🙂", #5eda5e);
}

.fl-memo-btn-grade--E {
  .mixin-memo-btn-grade("😊", #1db51d);
}

.fl-memo-btn-grade--1 {
  .mixin-memo-btn-grade("😖", #fb0606);
}

.fl-memo-btn-grade--2 {
  .mixin-memo-btn-grade("🙁", #ce7c7c);
}

.fl-memo-btn-grade--3 {
  .mixin-memo-btn-grade("😕", #aaa);
}

.fl-memo-btn-grade--4 {
  .mixin-memo-btn-grade("😊", #5eda5e);
}

.fl-memo-btn-grade--5 {
  .mixin-memo-btn-grade("😃", #1db51d);
}

.fl-memo-btn-grade--F {
  .mixin-memo-btn-grade("🙁", #ce7c7c);
}

.fl-memo-btn-grade--R {
  .mixin-memo-btn-grade("😊", #1db51d);
}

@import "fl-memo-btn-grade--xs-portrait";
@import "fl-memo-btn-grade--md";