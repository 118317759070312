@gap: .25rem;

.fl-pack-games-buttons-btn() {
  background: #fff;
  border: solid 1px rgba(204, 204, 204, .5);

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.fl-pack-games-buttons {
  margin-bottom: 1em;
  margin-left: -@gap;
  margin-right: -@gap;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .btn {
    position: relative;
    text-align: left;
    width: 100%;
    margin-bottom: @gap;

    .fl-pack-games-buttons-btn();
  }
}

.fl-pack-games-buttons__group {
  //width: 50%;
  //flex: 50%;
  width: 100%;
  flex: 100%;
  flex-grow: 0;
  padding: 0 @gap;
}

.fl-pack-games-buttons__filler {
  display: none;
}

.fl-pack-games-buttons__icon {
}

.fl-pack-games-buttons__text {
  line-height: 2;
  opacity: .7;
}

@import "fl-pack-games-buttons--md";