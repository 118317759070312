@import "_edit-card-form.less";

.fl-form-cards {
  counter-reset: list;
}

.fl-form-cards--danger {
  .fl-form-cards__li {

    &:nth-child(1),
    &:nth-child(2) {
      border: solid 1px #f1a0a8;
    }
  }
}

.fl-form-cards__li {
  position: relative;
  margin-bottom: 1em;
  margin-right: 3em;
}

.fl-form-cards__card {
  position: relative;
  padding-left: 2em;
  background: #fff;
}

.fl-form-cards__face-side {
  padding: 1rem .5rem 1rem 2rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.fl-form-cards__back-side {
  padding: 1rem .5rem 1rem 2rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}


.fl-form-cards__side-input {
  flex: 1;

  .ProseMirror {
    max-height: initial;

    .edit-card-textarea-theme();
  }
}

.fl-form-cards__side-picture {
  margin-left: 1rem;

  img {
    max-height: 4rem;
    max-width: 4rem;
  }
}

.fl-form-cards__back-text,
.fl-form-cards__face-text {
  .edit-card-textarea-theme();

  resize: none;
}

.fl-form-cards__index {
  line-height: 2;
  position: absolute;
  display: block;
  top: 1rem;
  left: 0;
  width: 3em;
  text-align: center;

  &:before {
    counter-increment: list;
    content: counter(list);
  }
}

.fl-form-cards__delete {
  position: absolute;
  display: block;
  top: 2px;
  right: -3em;
  width: 3em;
  text-align: center;

  .btn {
    opacity: .8;

    &:focus,
    &:hover,
    &:active {
      opacity: 1;
    }
  }
}

.fl-form-cards__face-buttons,
.fl-form-cards__back-buttons {
  margin-top: -.5rem;
  margin-left: .5rem;

  display: flex;
  flex-direction: column;

  .btn {
    opacity: .5;
    font-size: 1rem;
    padding-right: .25rem;
    padding-left: .25rem;

    &:hover,
    &:focus,
    &:active {
      opacity: .8;
    }

    .fc {
      vertical-align: middle;
      font-size: 1.5em;
    }
  }
}

@import "fl-form-cards--sm.less";