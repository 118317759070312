input.fl-form-control {
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  border: none;
  border-bottom: solid 1px #ddd;
  padding-bottom: .1rem;
  line-height: 1.2;

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    opacity: .6;
  }
}