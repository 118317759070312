.fl-dashboard-memo-grid {
  .dropdown-toggle::after {
    display: none;
  }
}

@import "fl-dashboard-memo-grid--grid";
@import "fl-dashboard-memo-grid--table";

.fl-dashboard-memo-grid__card {
  padding: .25rem .5rem;
}

.fl-dashboard-memo-grid__card-placeholder {
  border: 3px solid #dedede;
  color: #b7b7b7 !important;
  font-size: 1.2rem;
}

.fl-dashboard-memo-grid__card-play {
  font-size: 1.8rem;
}

.fl-dashboard-memo-grid__card-totals {
  font-size: 80%;
  font-weight: bold;
}

// -------------------------------------------------------------------
// fl-dashboard-memo-grid__card-metrics styles

.fl-dashboard-memo-grid__card-metrics {
  font-size: 80%;
}

.fl-dashboard-memo-grid__card-metric span {
  opacity: .8;
}

.fl-dashboard-memo-grid__card-metric em {
  font-style: normal;
}

// fl-dashboard-memo-grid__card-metrics styles
// -------------------------------------------------------------------

// -------------------------------------------------------------------
// fl-dashboard-memo-grid__card-metrics layout

// fl-dashboard-memo-grid__card-metrics layout
// -------------------------------------------------------------------

// -------------------------------------------------------------------
// fl-dashboard-memo-grid__card-timings styles

.fl-dashboard-memo-grid__card-timing {
  opacity: .4;
  font-size: 70%;
}

// fl-dashboard-memo-grid__card-timings styles
// -------------------------------------------------------------------

// -------------------------------------------------------------------
// fl-dashboard-memo-grid__card-timings layout

.fl-dashboard-memo-grid__card-timings {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
}

// fl-dashboard-memo-grid__card-timings layout
// -------------------------------------------------------------------
