@import "../../less/_edit-card-form";

.fl_add-card-side {
  .ProseMirror {
    .edit-card-textarea-theme();
  }
}

.fl_add-card-side__buttons {
  margin-top: -0.3rem;
}

.fl_add-card-side__buttons .btn {
  line-height: 1;
  padding-left: .5rem;
  padding-right: .5rem;
  opacity: .7;
}

.fl_add-card-side__buttons .btn:hover,
.fl_add-card-side__buttons .btn:focus,
.fl_add-card-side__buttons .btn:active {
  opacity: 1;
}

.fl_add-card-side__audio,
.fl_add-card-side__image {
  height: 4em;
  flex: 1;
  text-align: center;
  position: relative;
}

.fl_add-card-side__image > img {
  max-width: 100%;
  max-height: 100%;
}

.fa-stack.fa-stack--width-1_5em {
  width: 1.5em;
}