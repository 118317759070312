.fl-learn-card__result-panel {
  margin-left: .5rem;
  margin-right: .5rem;
  margin-top: .5rem;

  p {
    margin-bottom: .5rem;
  }
}

.fl-write-card__answer-notice {
  display: block;
  color: #959090;
  margin-top: .5em;
  margin-bottom: .5em;
}

.fl-write-card__highlight-correct {
  color: #16a765;
}

.fl-write-card__highlight-wrong {
  color: #db6e60;
}

.fl-write-card__buttons {
  padding-top: 1em;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

@import "fl-write-card--md";